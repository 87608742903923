import { createSelector} from 'reselect';


const selectCritMessages = state => state.critMessages;

export const getCritMessages = createSelector(
    [selectCritMessages],
    critMessages => critMessages.messages
)

export const selectCurrentCritMessages = currentTopic => 
createSelector(
  [getCritMessages],
  critMessages => critMessages.filter(item => item.topic === currentTopic)
);

export const selectUserCritMessages = currentUserId => createSelector(
  [getCritMessages],
  critMessages => critMessages.filter(item => item.user.id === currentUserId)
);