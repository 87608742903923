import PROJ2_MARKDOWN from './proj2.markdown.md';

const PROJ2 = {
    id: 2,
    title: 'project 2: goldberg machine',
    due: 'October 20',
    route: '/unit2/project/project2',
    teaser: '',
    // description: "For your first project, I'd like you to make a collection.",
    markdown: PROJ2_MARKDOWN,
    inspirationLinks: [
        {
            linkText:"Pitagoru Suitchi",
            linkSrc:"https://www.facebook.com/34842394274/videos/1054182998170/"
        },
        {
            linkText: 'miranda july somebody',
            linkSrc: 'https://www.youtube.com/watch?v=iz13HMsvb6o',
        },
        {
            linkText: 'celeste boursier-mougenot',
            linkSrc: 'http://www.artnews.com/2015/04/30/french-pavilion-artist-celeste-boursier-mougenot-teaches-the-world-to-sing/',
        },
        {
            linkText:"Kristin Lucas, refresh",
            linkSrc:"http://www.kristinlucas.com/refresh.html"
        },
        {
            linkText:"giphy arcade",
            linkSrc:"https://arcade.giphy.com/"
        },
        {
            linkText: 'giphy arcade nose',
            linkSrc: 'https://arcade.giphy.com/playlist/-LntTOeuqUo2uIjJwWjG',
        },


        {
            linkText:"artist jer thorp",
            linkSrc:"https://www.jerthorp.com/"
        },
        {
            linkText:"molle industria",
            linkSrc:"https://molleindustria.org/"
        },
        {
            linkText:"likelike",
            linkSrc:"http://likelike.org/"
        },
        {
            linkText:"ucla game lab",
            linkSrc:"http://games.ucla.edu/"
        },
        {
            linkText:"mathias dorfelt",
            linkSrc:"https://www.mokafolio.de/works"
        },
        {
            linkText:"Angela Washko - the game",
            linkSrc:"https://angelawashko.com/home.html"
        },		
        {
            linkText:"hey baby video game by Suyn Looui",
            linkSrc:"https://www.youtube.com/watch?v=krvA3VHq5as"
        },				
        {
            linkText:"Adrian Piper - the rules of the game",
            linkSrc:"https://hyperallergic.com/127622/adrian-piper-binds-us-with-impossible-trust/"
        },

        {
            linkText:"francis alys, seven walks",
            linkSrc:"https://www.artangel.org.uk/project/seven-walks/"
        },
        {
            linkText:"Simone Forti - Dance Constructions",
            linkSrc:"https://www.youtube.com/watch?v=i5zlyDoEkqs"
        },	

        {
            linkText:"anthropophagic slobber",
            linkSrc:"https://www.dailymotion.com/video/x2n9bqv"
        },
        {
            linkText:"view of lygia clark and the abandonment of art show",
            linkSrc:"https://www.youtube.com/watch?v=HZ4TXDlUwQc"
        },
        
        {
            linkText:"kaffe matthews sensory bike",
            linkSrc:"https://sonicbikes.net/sonic-bike/#sensory-bike"
        },
        {
            linkText:"Kaffe Matthews, The swamp that was, a bicycle opera",
            linkSrc:"https://www.youtube.com/watch?v=ZuPHlxpK9i4"
        },
        

        {
            linkText:"Vagamundo - a migrant's tale",
            linkSrc:"http://www.ambriente.com/cart/"
        },
        
        {
            linkText:"Thomas Hirschhorn - Gramsci Monument",
            linkSrc:"https://www.youtube.com/watch?v=O5yyegM2u88"
        },
        {
            linkText:"pacmanhattan",
            linkSrc:"http://www.pacmanhattan.com/about.php"
        },	
        {
            linkText:"Designing For Play | Meet Hellicar & Lewis",
            linkSrc:"https://www.youtube.com/watch?v=Xxdg2H9DvXg"
        },
        {
            linkText:"Ludic Interfaces, Matthias Fuchs et al",
            linkSrc:"http://creativegames.org.uk/publications/pdf_files/Ludic_Interfaces-2013.pdf"
        },
        {
            linkText:"Indie Game Development with Jane Friedhoff",
            linkSrc:"https://www.youtube.com/watch?v=VIEwBm7PWQM"
        },
        {
            linkText:"Jane Friedhoff",
            linkSrc:"http://janefriedhoff.com/"
        },
        {
            linkText:"jane friedhoff- scream em up",
            linkSrc:"https://vimeo.com/41629022"
        },	
        {
            linkText:"handvaska by Ramsey nasser and Jane Friedhof",
            linkSrc:"http://nas.sr/handv%C3%A4ska/"
        },												
        {
            linkText:"matt parker - duello",
            linkSrc:"http://madparker.com/duello/"
        },
        {
            linkText:"matt parker - recurse",
            linkSrc:"https://www.youtube.com/watch?v=UwpX2veYCSY&feature=youtu.be"
        },
        {
            linkText:"kaho abe projects",
            linkSrc:"http://kahoabe.net/"
        },
        {
            linkText:"kaho abe - hit me",
            linkSrc:"https://vimeo.com/29638917"
        },					
        {
            linkText:"wafaa bilal - domestic tension",
            linkSrc:"http://wafaabilal.com/domestic-tension/"
        },
        {
            linkText:"johann sebastian joust",
            linkSrc:"https://www.youtube.com/watch?v=c2Ei9fgFAOs"
        },
        {
            linkText:"free fall high score",
            linkSrc:"https://gizmodo.com/5828833/free-fall-high-score-drop-your-iphone-off-the-tallest-building-to-win-the-game/"
        },
        {
            linkText:"100%: a click game on scratch",
            linkSrc:"https://scratch.mit.edu/projects/132319133/"
        },
        {
            linkText:"Gabriel Orozco",
            linkSrc:"http://www.art21.org/artists/gabriel-orozco"
        },
        {
            linkText:"angelo washko the game",
            linkSrc:"http://angelawashko.com/home.html"
        },
        {
            linkText:"Sorry moma, Video Games Are Not Art",
            linkSrc:"https://www.theguardian.com/artanddesign/jonathanjonesblog/2012/nov/30/moma-video-games-art"
        },
        {
            linkText:"Are Video Games Art?",
            linkSrc:"https://www.artsy.net/article/artsy-editorial-are-video-games-art"
        },
        {
            linkText:"Video Games Do Belong In MOMA",
            linkSrc:"https://www.wired.com/2012/12/why-videogames-do-belong-in-the-museum-of-modern-art/"
        },
        {
            linkText:"save historic feminist games",
            linkSrc:"http://readwrite.com/2014/12/04/killscreen-save-historic-feminist-game/"
        },	


        {
            linkText:"Jeremy Deller - the battle of orgreave",
            linkSrc:"http://www.jeremydeller.org/TheBattleOfOrgreave/TheBattleOfOrgreave.php"
        },
        {
            linkText:"Center For Tactical Magic - Bank Heist",
            linkSrc:"http://www.tacticalmagic.org/bankshot.htm"
        },
        {
            linkText:"notations 21 book",
            linkSrc:"https://www.brainpickings.org/2011/05/06/notations-21/"
        },
        {
            linkText:"graphic scores in pictures",
            linkSrc:"https://www.theguardian.com/music/gallery/2013/oct/04/graphic-music-scores-in-pictures#/?picture=418979755&index=0"
        },
        

        {
            linkText:"christian marclay, shuffle",
            linkSrc:"https://www.youtube.com/watch?v=rmNJQaaBLcQ"
        },						
        {
            linkText:"sound shapes game",
            linkSrc:"https://www.youtube.com/watch?v=FIxKLZHFThE"
        },
        {
            linkText:"john zorn cobra card game",
            linkSrc:"https://www.youtube.com/watch?v=yp-oZbmsQVw"
        },
        {
            linkText:"yoko ono text scores",
            linkSrc:"https://socalledsound.github.io/gap2019/images/scores/yokoBicycle.jpg"
        },
        {
            linkText:"george brecht - card piece for voice page 1",
            linkSrc:"https://socalledsound.github.io/gap2019/images/non-sense/cardpieceforvoice-bracht-pg1.jpg"
        },
        
        {
            linkText:"george brecht - card piece for voice page 2",
            linkSrc:"https://socalledsound.github.io/gap2019/images/non-sense/cardpieceforvoice-brecht-pg2.jpg"
        },
        {						
            linkText:"oblique strategies alternate deck",
            linkSrc:"http://4.bp.blogspot.com/_jPPunq0tCuc/TAhX0gtfXVI/AAAAAAAAEN0/yP-f2bM1qwU/s1600/brian+eno-oblique-strategies-deck2.jpg"
        },						{
            linkText:"oblique strategies cards",
            linkSrc:"http://stoney.sb.org/eno/oblique.html"
        },

        {
            linkText:"Nomic - A game of Self ammendment",
            linkSrc:"https://legacy.earlham.edu/~peters/nomic.htm"
        },
        {
            linkText:"baby castles",
            linkSrc:"https://www.babycastles.com/exhibitions"
        },	
        {
            linkText: 'darkgame 4.0 by Eddo Stern, notice the awesomely looping gameplay and sounds',
            linkSrc: "http://eddostern.com/works/darkgame-4-0/",
        },
        {
            linkText: '15 awesome html+js gasmes',
            linkSrc: 'https://www.digitaltrends.com/gaming/best-html-5-javascript-games/',
        },

        {
            linkText: 'js13k: competition for really small javascript games',
            linkSrc: 'https://js13kgames.com',
        },
        {
            linkText: 'offline paradise, a game I like from the js13k competition',
            linkSrc: 'https://js13kgames.com/games/offline-paradise/index.html',
        },
    ]
}

export default PROJ2