import { CritMessagesActionTypes } from './critMessages.types';

// const INITIAL_STATE = ARCHIVED_WORKS;
const INITIAL_STATE = {
    messages: null,
    itemCritMessages: null,
    userCritMessages: null,
};

const critMessagesReducer = (state = INITIAL_STATE, action) => {
   
//    console.log('updating crit messages');
    console.log(action);
    switch(action.type){
       
       case CritMessagesActionTypes.UPDATE_CRIT_MESSAGES:
            
            let messages;
            if(state.messages){
                messages = state.messages.slice();
                action.payload.map(item => {
                    const itemExists = state.messages.some(message => message.id === item.id);
                    if(!itemExists){
                        messages.push(item)
                    }
                    return null
                })


            } else {
                messages = action.payload;
            }


            return {
               ...state,
               messages 
           }

        case CritMessagesActionTypes.UPDATE_ITEM_CRIT_MESSAGES:
        return {
            ...state,
            itemCritMessages : action.payload
        }
        
        case CritMessagesActionTypes.UPDATE_USER_CRIT_MESSAGES:
            return {
                ...state,
                userCritMessages : action.payload
            }

        // case CritMessagesActionTypes.UPDATE_USER_CRIT_MESSAGES:
        //         return {
        //             ...state,
        //             messages : state.messages.concat(action.payload)
        //         }    
       
        default:
            return state
    }
}
export default critMessagesReducer
