import { createSelector} from 'reselect';


const selectDiscussion = state => state.discussion;

export const getMessages = createSelector(
    [selectDiscussion],
    discussion => discussion.messages
)

export const selectCurrentTopicMessages = currentTopicId => 
createSelector(
  [getMessages],
  messages => messages != null ? messages.filter(item => item.topic === currentTopicId) : messages
);

// export const selectUserMessages = currentUserId => {
  
//   console.log(currentUserId);
//   return createSelector(
//   [getMessages],
//   messages => messages.filter(message => message.createdBy.id === currentUserId)
// )};

export const selectSelectedMessage = createSelector(
  [selectDiscussion],
  discussion => discussion.selectedMessage
)