import { ASSIGNMENT5 } from "../../assignments/assignments.data"

const UNIT1_DAY5 = {
    id: 5,
    unit: 'unit1',
    route: '/units/unit1/day5',
    assignmentRoute: '/units/unit1/day5/assignment', 
    month: 'September',
    day: 10,  
    name: 'jeremy bearimy',
    illustrationURL: 'https://res.cloudinary.com/chris-kubick/image/upload/c_scale,h_526/v1596997450/side-effects/billy-trips_hr3eoy.jpg',
    description: "This might seem like a leap, but sometimes it seems like the atom bomb ( or, maybe the invention of computers) was made inevitable by the invention of photography.  It's that moment of splitting, of cutting time to create a new context that really changed the way we think, and led to amazing new ways of restructuring the realities we live.  A hundred years later, the click of a camera became the click of a mouse.  And here we are.  Today we'll talk about hypertexts.  And timelines that look like the name Jeremy Bearimy.  And, we're going to make an interactive website using photographs and web code, and give your audience some control over the narrative, by letting them click.  We're also going to level up our coding game by started to use a dedicated code editor called vscode, learn about git and github and begin hosting our web sites via github.  ",
    assignment: ASSIGNMENT5,
    detailed: true,
 
    inspirationLinks: [
        {
            linkText: 'Jeremy Bearimy',
            linkSrc: 'https://www.youtube.com/watch?v=RFm9ClqlGuo',
        },
        {
            linkText:'William Burroughs, The Invisible Generation',
            linkSrc: 'https://drive.google.com/file/d/1Wg_K1qyp1rbWQUG2ztbH62jbl7RdGEfm/view?usp=sharing',
        },
        {
            linkText:'#14: “The Invisible Generation” Poster',
            linkSrc:'https://realitystudio.org/bibliographic-bunker/the-top-23-most-interesting-burroughs-collectibles/14-the-invisible-generation-poster/',
        },
        {
            linkText: 'Jorge Luis Borges, The Garden of Forking Paths',
            linkSrc:'http://mycours.es/gamedesign2012/files/2012/08/The-Garden-of-Forking-Paths-Jorge-Luis-Borges-1941.pdf',
        },
        {
            linkText: 'Nick Felton, Photoviz',
            linkSrc: 'http://videos.theconference.se/nicholas-felton-creating-meaningful-stories-out',
        },
        {
            linkText: 'yung jake embedded',
            linkSrc: 'http://e.m-bed.de/d/',
        },
        {
            linkText:"The 7 Most F***ed Up Real 'Choose Your Own Adventure' Books",
            linkSrc:"https://www.cracked.com/article_22188_7-choose-your-own-adventure-books-clearly-conceived-lsd.html"
        },
        {
            linkText: 'What happens when you raise an AI on a diet of choose your own advenure books?',
            linkSrc: 'https://www.theverge.com/tldr/2019/12/6/20998993/ai-dungeon-2-choose-your-own-adventure-game-text-nick-walton-gpt-machine-learning',
        },
        {
            linkText: 'Gif book by Dennis Cooper',
            linkSrc: 'http://www.kiddiepunk.com/zacs_haunted_house.htm',
        },
        {
            linkText:"reactive books by John Maeda",
            linkSrc:"https://www.youtube.com/watch?v=nA_UTUvC4h8"
        },

        {
            linkText:"twinery.org",
            linkSrc:"http://twinery.org/"
        },
        {
            linkText:"twinery tutorial",
            linkSrc:"https://www.youtube.com/watch?v=fauDZQyJtnM"
        },
        {
            linkText: 'stop motion animation by some kids with water!',
            linkSrc: 'https://thumbs.gfycat.com/TenderFrayedHagfish-size_restricted.gif',
        },
        {
            linkText: 'psychic tv remixed by yoshihide sodeoka',
            linkSrc: 'https://vimeo.com/115066659',
        },

        ],
        techLinks: [
            {
                linkText: 'learn git and github in five minutes',
                linkSrc: 'https://www.educative.io/edpresso/learn-git-and-github-in-5-minutes',
            },

            {
                linkText: 'download visual studio code',
                linkSrc : 'https://code.visualstudio.com/download',
            },
            {
                title:  'visual studio code tips and tricks',
                linkSrc : 'https://code.visualstudio.com/docs/getstarted/tips-and-tricks',
            },
            {
                linkText: 'emmet cheatsheet',
                linkSrc: 'https://docs.emmet.io/cheat-sheet/',
            },
            {
                linkText: 'the anchor element',
                linkSrc: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a',
            },
            {
                linkText:'arrays in javascript',
                linkSrc: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array',
            },
            {
                linkText:'template literals in javascript',
                linkSrc: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals',
            },
            {
                linkText: 'on-click',
                linkSrc: 'https://www.w3schools.com/jsref/event_onclick.asp',
            },
            {
                linkText: "text generation API based on openAI's GPT-2",
                linkSrc: 'https://deepai.org/machine-learning-model/text-generator',
            },
            {
                linkText: 'unicode characters - category other ',
                linkSrc: 'https://www.compart.com/en/unicode/category/So',
            },
            {
                linkText: 'jeremy-bearimy github repo (with multiple branches!',
                linkSrc: 'https://github.com/socalledsound/jeremy-bearimy-starter/tree/master',
            },
            {
                linkText: 'how to download starter code from github',
                linkSrc: 'https://youtu.be/9h9YpOBsJ1U',
            },
            {
                linkText: 'how to get started with vs code',
                linkSrc: 'https://youtu.be/qVYxGNBVMl0',
            },
            {
                linkText: 'how to host your website at github pages',
                linkSrc: 'https://youtu.be/n1Ir4mkBtcc',
            },
        ],
        videos: [
            {
                id : 'UB1O30fR-EE',
                title:'html basics from traversy media',
                description: 'an hour long dive into the basics of html'
            },
            {
                id : 'yfoY53QXEnI',
                title: '',
                description: ''
            },
            {
    
            },
            {
    
            },
            {
    
            },
            {
    
            },
        ], 
}

export default UNIT1_DAY5