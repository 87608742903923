import React from 'react';
import { Grid, Header } from 'semantic-ui-react';


const DiscussionUserPanel = () => {
        
        return (
            <Grid style={{ background: '#4c3c4c'}}>
                <Grid.Column>
                    <Grid.Row style={{ padding: '1.2em', marginTop: '-20px', marginLeft: '-20px' }}>
                        {/* main app header */}
                        <Header inverted floated="left" as="h2">
                            {/* <Icon name="code" /> */}
                            <Header.Content>discussion</Header.Content>
                        </Header>
                    </Grid.Row>

                </Grid.Column>
            </Grid>

        )
    

}

export default DiscussionUserPanel