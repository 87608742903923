import { ASSIGNMENT12 } from '../../assignments/assignments.data';

const UNIT2_DAY3 = {
    id: 12,
    unit: 'unit2',
    name: 'player player',
    route: '/units/unit2/day12',
    month: 'October',
    day: 6,
    description: "For project 2, the first part of the assignment asks you to invent a character.  Today we're going to look at some artists who have grappled with this question of what a character is and what it means to play one.  ",
    illustrationURL:'https://res.cloudinary.com/chris-kubick/image/upload/v1597703164/side-effects/trent5_o835fq.jpg',
    assignmentRoute: '/units/unit2/day12/assignment', 
    assignment: ASSIGNMENT12,
    
    inspirationLinks: [
        {
            linkText:"Introduction to Observing Kobito Hiding Peach Bottom Kobitos",
            linkSrc:"https://www.youtube.com/watch?v=6c0hBQQRRJY"
        },	
        {
            linkText:"william pope l as superman",
            linkSrc:"https://www.google.com/search?q=william+pope+l+superman&sxsrf=ACYBGNRMAFa9qh7KPoltkCUrUYZoFI9-Aw:1572931878311&source=lnms&tbm=isch&sa=X&ved=0ahUKEwjF9vLuq9LlAhVxFjQIHazWC7wQ_AUIEigB&biw=3164&bih=714"
        },
        {
            linkText:"James Luna - take  Picture With a Real Indian",
            linkSrc:"https://www.youtube.com/watch?v=dAa69BVwPYg"
        },
        {
            linkText:"Rammellzee",
            linkSrc:"https://www.youtube.com/watch?v=T52mHTpvSeA"
        },	
                               
        {
            linkText:"pierre Huyghe",
            linkSrc:"https://www.youtube.com/watch?v=J3E8ioPg8xQ"
        },
        {
            linkText:"Angela Washko - the game",
            linkSrc:"https://angelawashko.com/home.html"
        }
        ,
        {
            linkText:"Adrian Piper, Mythic Being",
            linkSrc:"http://www.adrianpiper.com/vs/video_tmb.shtml"
        },
        {
            linkText: 'adrian piper - the probable trust registry',
            linkSrc: 'https://hyperallergic.com/127622/adrian-piper-binds-us-with-impossible-trust/',
        },
        {
            linkText:"Star Choir by Malik Gaines and Alexandro Segade",
            linkSrc:"https://vimeo.com/193188303"
        },		

         {
            linkText:"Nao Bustamente, 'Silver & Gold'",
            linkSrc:"https://vimeo.com/86799096"
        },
        {
            linkText:"Senga Nengudi",
            linkSrc:"http://sengasenga.com/gallery.html"
        },
        {
            linkText:"Mikka Rottenberg",
            linkSrc:"https://www.youtube.com/watch?v=jQjZ-nZeWcE"
        },
        {
            linkText:"Nick Cave, Until",
            linkSrc:"http://massmoca.org/nick-cave-until/"
        },

        {
            linkText:"Nikki S. Lee",
            linkSrc:"https://www.youtube.com/watch?v=oI8xpJItPVI"
        },
        {
            linkText:"scathing critique of Nikki S Lee",
            linkSrc:"http://contemptorary.org/nikki-s-lees-projects-and-the-ongoing-circulation-of-blackface-brownface-in-art/"
        },

        {
            linkText:"William Pope L - the will to exhaust",
            linkSrc:"https://walkerart.org/magazine/william-popel-will-exhaust"
        },
        {
            linkText: 'trenton doyle hancock on art 21',
            linkSrc: 'https://art21.org/watch/art-in-the-twenty-first-century/s2/trenton-doyle-hancock-in-stories-segment/',
        },

        {
            linkText: 'She created a fake Twitter persona — then she killed it with COVID-19',
            linkSrc: 'https://www.theverge.com/21419820/fake-twitter-persona-covid-death-munchausen-metoostem-co-founder',
        },


        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        ],
        techLinks: [

            {
                linkText: 'using piskel',
                linkSrc: 'https://youtu.be/HS2OaAu07MU',
            },
            {
                linkText: 'javascript classes',
                linkSrc: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Classes',
            },
            {
                linkText: 'classes in javascript with daniel shiffman',
                linkSrc: 'https://www.youtube.com/watch?v=T-HGdc8L-7w',
            },
            {
                linkText: '',
                linkSrc: '',
            },
        ], 
        videos: [
            {
                id : 'UB1O30fR-EE',
                title:'html basics from traversy media',
                description: 'an hour long dive into the basics of html'
            },
            {
                id : 'yfoY53QXEnI',
                title: 'css basics from traversy media',
                description: 'a video about the basics of css from one of my favorite teachers of web coding'
            },
            {
    
            },
            {
    
            },
            {
    
            },
            {
    
            },
        ],    
}

export default UNIT2_DAY3