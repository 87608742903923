import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';

export const DisappearingMessagesPanel = styled(Grid.Column)`
    
   background-color: white;
    @media screen and (max-width: 600px) {
        display: none;
        visibility: collapse;
}
`;

export const ShiftingColumn = styled(Grid.Column)`

    margin-left: -30px;
    margin-top: 12px;
    @media screen and (max-width: 600px) {
        margin-left: -140px;
        margin-top: -12px;
}

`

export const GalleryBackground = styled.div`

margin-left: -30px;
margin-top: -12px;
background-color: white;
width: 100vw;

display: inline-block;

`