import React from 'react';
import './user-assignments-list.styles.scss';

const UserAssignmentsList = ({completedAssignments}) => {
    const nonRandom = completedAssignments.filter(ass => ass.indexOf('random') === -1 );
    return (
        <div className="assignment-list-container">
            you've completed the following assignments:
            <div>

            
                {nonRandom.map((item, index) => (
                    <div className="assignment-list-item" key={index}>
                        <span >{item}</span>
                    </div> 
                ))}
            
            </div>
            
        </div>
    )

}
export default UserAssignmentsList