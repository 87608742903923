import React from 'react';
import './login-message.styles.scss';

const LoginMessage = () => {
    return(
            <div className='login-container'>
                <p>this is the web site for a class that teaches creative coding.  if you want to join the fun you have to <a href="/signin">log in</a></p>
                <p>if this is your first time here, please visit the <a href="/gallery">gallery</a></p>
                <p>or learn more about the class <a href='/about'>here</a></p>
            </div>
        )
}

export default LoginMessage