import { ASSIGNMENT27 } from '../../assignments/assignments.data';

const UNIT4_DAY4 = {
    id: 27,
    unit: 'unit4', 
    route: '/units/unit4/day27',
    month: 'December',
    day: 1, 
    name: 'firebase + p5',
    description: "Last time, we learned how to integrate a database-as-a-service, firebase, into a web page.  Today I'll show you how to integrate that database into a p5 sketch.  It's mostly pretty straightforward, but there are a few little wrinkles, including dealing with CORS (cross origin resource scripting) and making sure that we don't try to use data that we haven't received yet, which is often a problem when working with remote data.", 
    // description:"Emergent systems are systems that exhibit some semblance of life.  In other words, they develop on their own, surprising us with the results.  Like, for instance, a college course on digital media.  Today we'll look at one of the more iconic articulations of this idea, cellular automata and the game of life.",
    illustrationURL: 'https://res.cloudinary.com/chris-kubick/image/upload/v1606769777/side-effects/webgl-image-gallery_xf6vg1.jpg',
    // illustrationURL: 'https://res.cloudinary.com/chris-kubick/image/upload/v1597869195/side-effects/cellular-automata_m8q8lt.jpg',
    assignmentRoute: '/units/unit4/day27/assignment', 
    assignment: ASSIGNMENT27,
     
    inspirationLinks: [

        {
            linkText: '',
            linkSrc: '',
        },
        // {
        //     linkText: 'cellular automata at tumblr.com',
        //     linkSrc: 'https://www.tumblr.com/tagged/cellular-automata',
        // },
        // {
        //     linkText:"'cellular automata' from the Nature of Code",
        //     linkSrc:"http://natureofcode.com/book/chapter-7-cellular-automata/"
        // },
        // {
        //     linkText:"the game of life in 3D",
        //     linkSrc:"http://gameoflife.samuellevy.com/"
        // },
        // {
        //     linkText:"cubes.io -- cellular automata in three dimensions v2",
        //     linkSrc:"http://cubes.io/"
        // },
        {
            linkText: "what happens when a virtual streamer doesn't own her own body?",
            linkSrc: 'https://www.theverge.com/2020/11/24/21591488/projekt-melody-twitch-ban-copyright-strike-digitrevx-vtuber',
        },
        {
            linkText: 'my image gallery app, working',
            linkSrc: 'https://new-image-gallery.web.app/',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        ],
        techLinks: [

            // {
            //     linkText:"shiffman videos on cellular automata",
            //     linkSrc:"https://www.youtube.com/watch?v=DKGodqDs9sA&list=PLRqwX-V7Uu6YrWXvEQFOGbCt6cX83Xunm"
            // },
            {
                linkText: 'javascript promises',
                linkSrc: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise',
            },
            {
                linkText: 'using the javascript debugger',
                linkSrc: 'https://developers.google.com/web/tools/chrome-devtools/javascript',
            },
            {
                linkText: 'my videos on making a p5 sketch with firebase integration',
                linkSrc: 'https://www.youtube.com/playlist?list=PLHJTpoJkSrePa9gCcRqmRwbYnFNxmPTtZ',
            },
            {
                linkText: "your standard non-firebase webgl image gallery @ github - we'll integrate this into our firebase project",
                linkSrc: 'https://github.com/socalledsound/webgl-image-gallery',
            },
            {
                linkText: 'the deployed firebase site from the previous videos -- be sure to enter in your own config info from firebase!',
                linkSrc: 'https://github.com/socalledsound/new-image-gallery/tree/04-deployed',
            },
            
        ], 
        videos: [

            {
    
            },
            {
    
            },
            {
    
            },
            {
    
            },
        ],  
}
export default UNIT4_DAY4