import { UserActionTypes } from './user.types';

export const setCurrentUser = user => ({
    type: UserActionTypes.SET_CURRENT_USER,
    payload: user
}) 

export const getCurrentUser = user => ({
    type: UserActionTypes.GET_CURRENT_USER,
    payload: user
}) 

export const updateUserNotifications = notifications => ({
    type: UserActionTypes.UPDATE_USER_NOTIFICATIONS,
    payload: notifications
})

export const updateUsers = users => ({
    type: UserActionTypes.UPDATE_USERS,
    payload: users
})