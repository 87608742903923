import PROJ3 from '../projects/proj3.data';
import UNIT3_DAY1 from '../days/unit3.days/unit3.day1.data';
import UNIT3_DAY2 from '../days/unit3.days/unit3.day2.data';
import UNIT3_DAY3 from '../days/unit3.days/unit3.day3.data';
import UNIT3_DAY4 from '../days/unit3.days/unit3.day4.data';
import UNIT3_DAY5 from '../days/unit3.days/unit3.day5.data';
import UNIT3_DAY6 from '../days/unit3.days/unit3.day6.data';
// import UNIT3_DAY7 from '../days/unit3.days/unit3.day7.data';
// import UNIT3_DAY8 from '../days/unit3.days/unit3.day8.data';
import LOGO_UNIT_3 from '../../assets/hvbrd2.jpg';


const UNIT3 = {
    id: 3,
    title: 'unit 3 : virtually yours',
    routeName: '/units/unit3',
    unit: 'unit3',
    imageUrl: LOGO_UNIT_3,
    overview: "In this unit we will start to move beyond the browser and into an alternate universe. You can call it Virtual Reality, Alternate Reality, or Xpanded Reality, but whatever you call it, it's been around a lot longer than computers have, and it doesn't need neccessarily need a headset to access it.  In fact, it often requires multiple media, and almost always requires reference to various definitions of external reality, whether they be perspective geometry, light, or social constructs like history and human emotion.  We'll explore all of these things in this unit, but our focus will be on code, and using code as a means to connect these various realities together.  ",
    mainAssignment: '',
    project: PROJ3,
    items: [UNIT3_DAY1, UNIT3_DAY2, UNIT3_DAY3, UNIT3_DAY4, UNIT3_DAY5, UNIT3_DAY6],
    objectives: "We'll begin this unit by looking at some ways to use two sensors that are built into most people's computers: microphone and camera.  We'll use these to trigger changes in our code and think about the ways that these sensors extend the experience of the web outside the browser and into the realities of the user.  Then, we'll explore webGL, and drawing on the canvas in three dimensions.  We'll touch on the power of light, material and shader language and we'll learn how to create immersive, 360 degree scenes; and then we'll learn how to combine these fantasy worlds with the user's own camera, to create interesting meshes between several worlds.  We'll finish up by exploring how to connect one browser to another, to create interactive real-time experiences, using web sockets.",
}
export default UNIT3