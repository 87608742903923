import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { selectCurrentProject } from '../../redux/projects/projects.selectors'; 
import MarkdownView from 'react-showdown';
import { Button } from 'semantic-ui-react';
import Directory from '../../components/directory/directory.component';
import SubmitProjectModal from '../../components/submit-project-modal/submit-project-modal.component';
import Pumpkin from '../../components/pumpkin/pumpkin.component';
import './projectpage.styles.scss';

class ProjectPage extends React.Component {

    state = {
        projectMarkdown : null,
        modal: false,
        isLate: true,
    }


    componentDidMount(){
        // const { project } = this.props;
        
        // this.setState({ isLate, project});
    }


    componentWillMount(){ 
        fetch(this.props.project.markdown)
        .then(resp => resp.text())
        .then( text => this.setState({ projectMarkdown: text}))
}


    checkIfLate = (project) => {
        return Date.now() > Date.parse(`${project.due}, 2020`);

        // Date.parse(`${day.month} ${day.day} 2020`);
    }


    openModal = () => this.setState({ modal: true });
    closeModal = () => this.setState({ modal: false });

    showButton(){
        // const { project } = this.props.day;
        const {currentUser, history, project } = this.props;
        const assignment = project;
       
        const completed = currentUser.completedAssignments || [];
        console.log(completed);
        const submitted = completed.indexOf(assignment.title) > -1;
            
     

        if(!submitted){
            if(assignment.project){
                console.log(assignment.project);
                return (
                <Button
                onClick={() => history.push(`/unit${assignment.project}/project/project${assignment.project}`)}
                color="green"
                content="submit project"
                labelPosition="right"
                icon="star"
                style={{ marginBottom: '1em'}}
                />
                )
            } else {
            return (
                <Button
                onClick={() => this.openModal()}
                color="green"
                content="submit assignment"
                labelPosition="right"
                icon="star"
                style={{ marginBottom: '1em'}}
                />
            )}
        } else {
            return (
                <div className='edit-error'>you've already submitted this assignment.  you can edit it in the <Link to="/gallery">  gallery</Link></div>
            )
        }

    }


    
    render(){
        const { modal } = this.state;
        const { currentUser, project } = this.props;
        const isLate = this.checkIfLate(project);
        return (
            <React.Fragment>
            <div className="directory-horizontal">
            <Directory />    
            </div> 

            <div className={`project-page-container unit${project.id}`}>
                <div className={`content unit${project.id}`}>
                    <h4 style={{color: 'blue', width: '100%', marginBottom: '1.2em'}}>project {project.id} due: {project.due} at midnight!</h4>
                    <MarkdownView 
                      markdown={this.state.projectMarkdown}
                      options={{ tables: true, emoji: true }}
                
                    />
              
              {this.showButton()}


                </div>


                <div className="links">
                     <ul className="links-list">
                        inspiration & ideas :
                    {project.inspirationLinks.map((link, index) => (
                                <li key={index} className="day-overview-link"><a href={link.linkSrc}>{link.linkText}</a></li>            
                        ))}
                    </ul>
                </div>



                <SubmitProjectModal modal={modal} closeModal={this.closeModal} user={currentUser} project={project}/>
            
            </div>
            </React.Fragment>
    )
    }




}


const mapStateToProps = (state, ownProps) => {
    console.log(ownProps.match.params.projectId);
    const {users:{currentUser}} = state;
    return ({
        currentUser,
         project: selectCurrentProject(ownProps.match.params.projectId)(state),
    }) 
} 


export default withRouter(connect(mapStateToProps)(ProjectPage))






//this page is going to show an assignment, in detail
//it needs a description of the assignment
//preferably some examples of the assignment
//some links for inspiration
//a video describing the assignment?
