import { ASSIGNMENT3 } from "../../assignments/assignments.data"

const UNIT1_DAY3 = {
    id: 3,
    unit: 'unit1',
    route: '/units/unit1/day3',
    assignmentRoute: '/units/unit1/day3/assignment', 
    name: 'breaking it down',
    illustrationURL: 'https://res.cloudinary.com/chris-kubick/image/upload/v1596752658/side-effects/DcKNQ4kVMAArzMi_cr4aiq.jpg',
    month: 'September',
    day: 3,
    description:"Today, we'll learn a little more about getting colored shapes on the page.  We'll use circles, trinagles and polygons, and think a little more seriously about composing with code.  We'll look at some fairly baroque adventures in css drawing and realize that the learning curve in css is basically eternal.  If there's a core lesson that I'd like you to take away from today it's the idea of re-building something by first breaking it down into it's discrete components.",
    // assignment: "submit your css art to the course gallery from your home page, in the same way that you submitted your last assignment.  be sure to check out what other people have done and show them some love in the comments!",
    assignment: ASSIGNMENT3,
    detailed: true,
     
    inspirationLinks: [
        {
            linkText: 'species in pieces',
            linkSrc: 'http://www.species-in-pieces.com/',
        },
        {
            linkText: 'This Stunning Image Made With Pure Code Is Like a Mood Ring For Your Browser',
            linkSrc: 'https://www.vice.com/en_us/article/9kgx7p/painting-made-with-code-html-pure-css-browser-art-diana-smith',
        },
        {
            linkText: ' painting the f train using css',
            linkSrc: 'https://blog.logrocket.com/painting-with-css-644cd4e67fad/',
        },
        {
            linkText: 'generative backgrounds with the css paint api',
            linkSrc : 'https://medium.com/better-programming/how-to-create-dynamic-backgrounds-with-the-css-paint-api-ebd733254014',
        },
        {
            linkText:'generative art with css',
            linkSrc : 'https://generative-art-with-css.commons.host/',
        },
        {
            linkText:'mona lisa with pure css',
            linkSrc: 'https://codepen.io/jaysalvat/pen/HaqBf',
        },
        {
            linkText:'magritte son of man with css',
            linkSrc: 'https://codepen.io/cgorton/pen/EWgoYq',
        },
        {
            linkText: 'a 3d engine made with just css!',
            linkSrc: 'https://keithclark.co.uk/labs/css-fps/',
        },
        {
            linkText: 'totoro made with just css',
            linkSrc: 'https://codepen.io/jacksaidwhat/pen/faJpv',
        },
        {
            linkText: 'Finn the human boy, in css',
            linkSrc: 'https://codepen.io/rothecoder/pen/BaKLYGd',
        },
        {
            linkText: 'css zen garden: classic web site showing how css transforms html',
            linkSrc: 'http://www.csszengarden.com/',
        },
            {
                linkText: 'Visually Satisfying Project Shares the Color Palettes of Iconic Film Scenes',
                linkSrc: 'https://mymodernmet.com/cinema-palettes/',
            },
    ],

        techLinks: [
            {
                linkText: 'how pure css images can help you understand coding',
                linkSrc: 'https://medium.com/coding-artist/how-pure-css-images-helped-me-understand-react-components-3ad7b05051b0'
            },
            // {
            //     linkText: 'gradients in css',
            //     linkSrc: 'https://css-tricks.com/css3-gradients/',
            // },
            // {
            //     linkText: 'ui gradients, a great resource for working with gradients',
            //     linkSrc: 'https://uigradients.com/#CalmDarya',
            // },
            {
                linkText: 'css variables',
                linkSrc: 'https://www.w3schools.com/css/css3_variables.asp',
            },
            {
                linkText: 'everything you need to know about svg',
                linkSrc: 'https://css-tricks.com/lodge/svg/',
            },
            {
                linkText: 'clippy',
                linkSrc: 'https://bennettfeely.com/clippy/',
            },
            {
                linkText: 'css: linear-gradient from mdn',
                linkSrc: 'https://developer.mozilla.org/en-US/docs/Web/CSS/linear-gradient',
            },

            {
                linkText: 'free svg',
                linkSrc: 'https://freesvg.org/',
            },
            {
                linkText: 'boxy-svg',
                linkSrc: 'https://boxy-svg.com/',
            },
            {
                linkText: 'how to create triangle shapes',
                linkSrc: 'https://catalin.red/how-to-create-triangle-shapes/',
            },
            {
                linkText: 'trianglify',
                linkSrc: 'https://trianglify.io/',
            },
            {
                linkText: 'border radius at mdn',
                linkSrc: 'https://developer.mozilla.org/en-US/docs/Web/CSS/border-radius',
            },
            {
                linkText: 'a big list of most of the css properties',
                linkSrc: 'https://www.w3schools.com/cssref/default.asp',
            },
            {
                linkText: 'ice cream project at github NOTE: IN SCSS',
                linkSrc: 'https://github.com/lauragrassig/ice-cream-project',
            },
            {
                linkText:'completed assignment at codepen',
                linkSrc: 'https://codepen.io/Fleischut/pen/GRZgYzV',
            }
        ],
        videos: [
            {
                id : '33333323',
                title:'css: border-radius',
                description: ''
            },
            {
                id : 'yfoY53QXEnI',
                title: 'css basics from traversy media',
                description: 'a video about the basics of css from one of my favorite teachers of web coding'
            },
            {
    
            },
            {
    
            },
            {
    
            },
        ],  

        
}

export default UNIT1_DAY3