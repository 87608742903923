import React from 'react';
import { Button } from 'semantic-ui-react';
import GalleryItem from '../gallery-item/gallery-item.component';
import './gallery-row.styles.scss';

const appWindow = document.querySelector('#root')
class GalleryRow extends React.Component{

    state = {
        scrolled: false,
        item: this.props.item.split(' ').join(''),
        itemRef: this.props.item,
        container: null,
        clicked: false,
        startX: 0,
        posx1: 0,
        posx2: 0,
        posInitial: 0,
        currentPos: 0,
        currentX: 0,
        scrollValue: 0,
        threshold: 100,
        containerRef: null,
        windowWidth: 0,
        windowHeight: 0,
        heightScaler: 3,
        hide: true,
    }


    componentDidMount(){
        const {item } = this.state;
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        let strippedItem;
        if(item === 'loading....'){
            strippedItem = item.replace("....", "");
        } else if(item === 'project2:goldbergmachine'){
            strippedItem = item.replace(":goldbergmachine", "");
        } else {
            strippedItem = item;
        }
       //    let strippedItem = item.replace(/^[^a-z]+|[^\w:.-]+/gi, "");
    //    console.log(strippedItem);
       const container = document.querySelector(`#${strippedItem}`);
       console.log('CONTAINER:  ', container);
       this.setState({ container: container});
        // window.addEventListener("scroll", this.scrollEventHandler, false);
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.updateWindowDimensions);
        // window.removeEventListener("scroll", this.scrollEventHandler, false);
    }


    dragAction(e){
        // const { container, currentPos, posx1, posx2, clicked } = this.state
        // e = e || window.event;
        // if(clicked){
        //     console.log(e);
        //     console.log(posx1, posx2);
        //     if(e.type == 'touchmove'){
    
        //     } else {
        //         // posx2  = posx1 - e.clientX;
        //         // posx1 = e.clientX;
        //         // console.log(posx1, posx2);
        //         const value = posx1 - e.clientX;
        //         console.log('VALUE: ', value);
        //         container.style.left = `${currentPos - value}px`;
        //         console.log(container.style.left);
        //         this.setState({posx1: e.clientX, posx2: value, currentPos: currentPos - value });
        //     }
        // }

        
    }

    dragEnd(e){
        // const { container, threshold, posInitial, currentPos } = this.state;
        // const posFinal = currentPos;
        // // console.log(e);
        // // console.log(container.offsetLeft);
        // if(posFinal - posInitial < -threshold){
        //     // shiftSlide(-1, 'drag');
        //     this.scrollForward()
        // } else if (posFinal - posInitial > threshold){
        //     // shiftSlide(-1, 'drag');
        //     this.scrollBackward()
        // } else {
        //     // container.style.left = `${posInitial}px`;
        // }
        // this.setState({clicked: false})
        // // container.removeEventListener('mousemove', (e) => this.dragAction());
        // // container.removeEventListener('mouseup', (e) => this.dragEnd());
       
        

    }

    dragStart(e){
    //     const { currentX } = this.state;
    //     e = e || window.event;
    //     e.preventDefault();
    //     //change to currentX
    //    const posInitial = currentX;

    //     if(e.type == 'touchstart'){

    //     //    let pos1 = e.thouches[0].clientX;
        
    //     } else {
    //         //set the position based on the amount moved
    //         const dragStartX = e.clientX;
    //         this.setState({ posx1: dragStartX, posx2: 0, posInitial: posInitial, clicked: true });
    //         // container.addEventListener('mouseup', (e) => this.dragEnd());
    //         // container.addEventListener('mousemove', (e) => this.dragAction());
    //         // document.onmousemove = (e) => this.dragAction();
    //     }


    }


    updateWindowDimensions = () => {
        this.setState({ windowWidth: appWindow.clientWidth, windowHeight: appWindow.clientHeight});
    }

    // scrollEventHandler = () => { window.scroll(0, window.pageYOffset)}

    scrollBackward = () => {
        const { container, windowWidth, windowHeight, heightScaler, currentX, item, scrollValue, posInitial} = this.state;

       // const value = currentX + ((windowWidth - windowWidth/(heightScaler * 1.01 * (windowWidth/windowHeight))));
       const value = currentX + scrollValue; 
       container.style.transform = `translateX(${value}px)`;
        const currentlyScrolled = value > - 10 ? false : true;
        this.setState({ scrolled: currentlyScrolled, currentX: value});
    }

    scrollForward = (featured, numWorks) => {
        const { container, windowWidth, windowHeight, heightScaler, currentX, item, itemRef } = this.state;

        let imgSize;
        
        if(windowWidth < 751){
            imgSize = 80;
        } else if( windowWidth > 750 && windowWidth < 1000){
            imgSize = 140;
        } else {
            imgSize = 200;
        }
        const scrollValue = imgSize * 4;
        console.error('scrollVALUE:', scrollValue);
        const rowEnd = currentX - scrollValue + (imgSize * numWorks);
        console.log('rowEnd: ', rowEnd);
       // const rowEnd = currentX - scrollValue + (340 * numWorks);
        let value;
        if(rowEnd < 0){
            value = currentX; 
        } else {
            value = currentX - scrollValue;
        }
        container.style.transform = `translateX(${value}px)`;
        console.log('should load more works');
        this.props.loadMore(itemRef);
        this.setState({ scrolled: true, currentX: value, scrollValue: scrollValue });
    }


    toggleHideButton = () => {
        const { hide } = this.state;
        const toggleHide = !hide;
        console.log(toggleHide);
        this.setState({ hide: toggleHide })
    }


    render(){
        const { works} = this.props;
        const { scrolled, item, windowWidth, windowHeight, hide, heightScaler } = this.state;
        const hidden = hide ? 'hide' : 'show';
        const leftButtonState =  scrolled ?  'inline-block' : 'none';
        const featured = item === 'featured';
        const numWorks = works.length;
        let strippedItem;
        if(item === 'loading....'){
            strippedItem = item.replace("....", "");
        } else if(item === 'project2:goldbergmachine'){
            strippedItem = item.replace(":goldbergmachine", "");
        } else {
            strippedItem = item;
        }
       //    let strippedItem = item.replace(/^[^a-z]+|[^\w:.-]+/gi, "");
    //    console.log(strippedItem);

    //     // strippedItem = item.replace("....", "");
    //     // strippedItem = item.replace(":", " ");
    //    console.log(strippedItem);
        return (
           
                 <div className="container" style={{width: `${windowWidth + 10}px`, height: `${(windowHeight/heightScaler)+30}px`}} onMouseDown={() => this.dragStart()} onMouseUp={()=> this.dragEnd()} onMouseMove={() => this.dragAction()} onMouseEnter={() => this.toggleHideButton()} onMouseLeave={() => this.toggleHideButton()}>
                <h1 id="title" >{item}</h1>  
                
                <div className="gallery-row-container" id={strippedItem} >
                    
                    {
                    works.map(item => (<GalleryItem key={item.id} item={item} windowWidth={windowWidth} windowHeight={windowHeight} scaler={heightScaler}/>))
                    }
                    
                </div>
             
             <React.Fragment>
             <Button icon="left arrow" style={{ display: `${leftButtonState}`, height: `${windowHeight/heightScaler+30}px`}} className={`button-backward ${hidden}`} onClick={() => this.scrollBackward(strippedItem)}>
                
             </Button>
             <Button icon="right arrow" className={`button-forward ${hidden}`} onClick={() => this.scrollForward(strippedItem, numWorks)} style={{ height: `${windowHeight/heightScaler+30}px`}}>
                 
             </Button>
             </React.Fragment>
              

            </div>
        )
    }



}
export default GalleryRow