import React from 'react';
import './user-missing-assignments-list.styles.scss';

const UserMissingAssignmentsList = ({missingAssignments, missingAssignmentRoutes, history}) => {
    const nonRandom = missingAssignments.filter(ass => ass.indexOf('random') === -1 );
    console.log(missingAssignmentRoutes);
    return (
        <div>
            you're missing the following assignments:
            
                {nonRandom.map((item, index) => (
                    <div className="missing-assignment-list-item-container" key={index} onClick={()=> history.push(missingAssignmentRoutes[index])}>
                    <span >{item}</span>
                    </div> 
                ))}
            

            
        </div>
    )

}
export default UserMissingAssignmentsList