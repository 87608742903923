import { ASSIGNMENT28 } from '../../assignments/assignments.data';

const UNIT4_DAY5 = {
    id: 28,
    unit: 'unit4',
    route: '/units/unit4/day28',
    month: 'December',
    day: 3,  
    name: 'finally',
    crit: true,
    description: 'Final Projects!!!!',
    assignmentRoute: '/units/unit4/day28/assignment', 
    assignment: ASSIGNMENT28,
 
    inspirationLinks: [

        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        ],
        techLinks: [

            {
                linkText: "a firebase 'gallery' example with sounds instead of images...",
                linkSrc: 'https://github.com/socalledsound/silence-farm',
            },
            {
                linkText: '',
                linkSrc: '',
            },
            {
                linkText: '',
                linkSrc: '',
            },
            {
                linkText: '',
                linkSrc: '',
            },
        ], 
        videos: [
            {
                id : 'UB1O30fR-EE',
                title:'html basics from traversy media',
                description: 'an hour long dive into the basics of html'
            },
            {
                id : 'yfoY53QXEnI',
                title: 'css basics from traversy media',
                description: 'a video about the basics of css from one of my favorite teachers of web coding'
            },
            {
    
            },
            {
    
            },
            {
    
            },
            {
    
            },
        ],    
}

export default UNIT4_DAY5