import React from 'react';
import { Button } from 'semantic-ui-react';

import './assignment-block.styles.scss';


const showAssignmentButton = (assignment, assignmentRoute, assignmentRouteHandler, nextDay, month, day) => {

  //const isNextDay = Date.now() >= Date.parse(`${month} ${day}, 2020`) ? true : false;
  const isNextDay = true;
  if(isNextDay ) {
    return(

      assignment.markdown != null &&
      <Button
      color="green"
      onClick={()=> assignmentRouteHandler(assignmentRoute)}
      >
          
          open assignment
      </Button>
    )
  }

  else {
    return (
      <div className="assignment-button-alt-text">a link to a workshop assignment page will be available on the day of class</div>
    )
  }


    


}

const AssignmentBlock = ({ assignment, assignmentRoute, assignmentRouteHandler, nextDay, month, day }) => {
  // console.log(month, day);
  return (



    <div className="assignment-day">
                    
    {/* <p className="assignment-heading">today's assignment:</p> */}
    
    <div className="boundary-div"></div>
    <div className="assignment-body">
         <h2 className="assignment-title">{assignment.title}</h2>
         <p>{assignment.description}</p>
    </div>
   
   {showAssignmentButton(assignment, assignmentRoute, assignmentRouteHandler, nextDay, month, day )}

</div>


)
  }
export default AssignmentBlock