import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import { createStructuredSelector } from 'reselect';
// import { selectCurrentUser } from '../../redux/user/user.selectors';
import { selectCurrentCritMessages } from '../../redux/critMessages/critMessages.selectors';
// import { addItemToFirestore } from '../../firebase/firebase.utils';
import { Comment } from 'semantic-ui-react';
import Message from '../message/message.component';
// import MessagesHeader from '../messages-header/messages-header.component';
import MessageForm from '../message-form/message-form.component';
import MessageFormTextArea from '../message-form-text-area/message-form-text-area.component';
import './messages-panel.styles.scss';
import { MessagePanelContainer } from './messages-panel.styles.jsx';

class MessagesPanel extends React.Component {

    state = {
        // messagesRef: firebase.database().ref('messages'),
        critMessagesRef: 'critMessages',
        messages: this.props.messages,
        messagesLoading : true,
        item: null,
        user: null,
        topic: null,
    }

    componentDidMount(){
        this.setState({ 
            // messages: this.props.critMessages, 
            item: this.props.item, 
            user: this.props.currentUser, 
            topic: this.props.item.id
         }, //console.log(messages)
         )
    }


    updateParent = ()=>{
        
        this.setState({messagesLoading: false, messages: this.props.messages})
    }
    

    renderMessages = (messages) => {
        if(messages) {

            messages.sort((a,b) => b.timestamp - a.timestamp)
             console.log(messages, 'in messages panel');
            return (
                messages.length > 0 && messages.map(message => (
                    <div className="message-container" key={message.timestamp}>
      
                        {/* <div>{message.content}</div> */}
      
                        <Message className="message"
                          
                            message={message}
                          
                            // currentUser={this.props.currentUser}
                        />
                    </div>
        
                ))
            )

        } else {
            return (
                <div>please refresh the browser</div>
            )
        }
  

    }

    render(){
       
        const { critMessagesRef, channel, item } = this.state;
        const { messages } = this.props;
        console.log(messages);
        // console.log(item, 'in message panel');
        // console.log(user, 'USER  in message panel');
        const { currentUser } = this.props;
        // console.log(currentUser);
        return (
            <React.Fragment>
                
                <MessagePanelContainer>
                {/* <MessagesHeader /> */}
                {currentUser && (
                        <MessageFormTextArea 
                            critMessagesRef={critMessagesRef} 
                            channel={channel} 
                            user={currentUser} 
                            item={item} 
                            updateParent={this.updateParent}
                            />
                    )}
                    {
                        !currentUser && (
                            <div>
                                only registered students can post here but you can <Link to='/book' className="show-link">sign the book</Link>
                            </div>
                        )
                    }
                    <Comment.Group className="messages">
                        {this.renderMessages(messages)}
                        
                    </Comment.Group>

                
                </MessagePanelContainer>
                
            </React.Fragment>
        )
    }

}

// const mapStateToProps = createStructuredSelector({
//     currentUser: selectCurrentUser,
//     critMessages : selectCurrentCritMessages('IUvcAsRUAWqz9n0fcpVF'),
//     // currentTopic: selectCurrentTopic
//     // works: getWorks
//   })

  const mapStateToProps = (state, ownProps) => {
    console.log(ownProps.item.id);
    return ({
        
        // currentUser: selectCurrentUser(state),
        critMessages: selectCurrentCritMessages(ownProps.item.id)(state)
        // itemCritMessages: state.critMessages.itemCritMessages,
    }) 
} 




export default connect(mapStateToProps)(MessagesPanel)