import PROJ4 from '../projects/proj4.data';
import UNIT4_DAY1 from '../days/unit4.days/unit4.day1.data';
import UNIT4_DAY2 from '../days/unit4.days/unit4.day2.data';
import UNIT4_DAY3 from '../days/unit4.days/unit4.day3.data';
import UNIT4_DAY4 from '../days/unit4.days/unit4.day4.data';
import UNIT4_DAY5 from '../days/unit4.days/unit4.day5.data';
// import UNIT4_DAY6 from '../days/unit4.days/unit4.day6.data';
// import UNIT4_DAY7 from '../days/unit4.days/unit4.day7.data';
// import UNIT4_DAY8 from '../days/unit4.days/unit4.day8.data';
import LOGO_UNIT_4 from '../../assets/unit1-temp.png';

const UNIT4 = {
    id: 4,
    title: 'unit 4 : zomes',
    routeName: '/units/unit4',
    unit: 'unit4',
    imageUrl: LOGO_UNIT_4,
    overview: "In this last unit, we will learn about some of the many ways to attach external sensors of various kinds to your code projects, using the raspberry pi platform.  We'll also learn how to set up a backend database, for persistent storage, using Google's firestore platform.  This will let users leave comments, or upload images or other data to your site, or let you store data about what they've done on your site before, like what score they got the last time they visited your game.  But this unit is mostly about you working on your final project, and us helping you get that work done.  So, get working!",
    mainAssignment: '',
    project: PROJ4,
    items: [UNIT4_DAY1, UNIT4_DAY2, UNIT4_DAY3, UNIT4_DAY4, UNIT4_DAY5],
    objectives: "We'll start with the raspberry pi, work our way through firestores and along the way discover nirvana.",
}
export default UNIT4