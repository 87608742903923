import { ASSIGNMENT2 } from "../../assignments/assignments.data"

const UNIT1_DAY2 = {
    id: 2,
    unit: 'unit1',
    route: '/units/unit1/day2',
    assignmentRoute: '/units/unit1/day2/assignment', 
    month: 'September',
    day: 1,
    name: 'colors in boxes',
    illustrationURL: 'https://res.cloudinary.com/chris-kubick/image/upload/v1596660749/side-effects/albers-many-boxes_nztt6v.jpg',
    description: "Today we'll talk about color -- what it is, what it means, how we can use it and how it's represented in web code.  We'll talk about a few artists that have focused on the relationships between colors:  Piet Mondrian, Josef Albers, Bridget Reilly, Agnes Martin, Mark Rothko.",
   // assignment: "make a copy of Bridget Reilly's 'Achaean' using color, html and css.  add a link to your code pen and a screen captured image to the course gallery from your home page!  be sure to check out what other people have done and show them some love in the comments.",
   assignment: ASSIGNMENT2,
   detailed: true,
   
    inspirationLinks: [

        // {
        //     linkText: 'De Stijl',
        //     linkSrc: 'https://en.wikipedia.org/wiki/De_Stijl',
        // },
        {
            linkText: 'Josef Albers and the science of seeing',
            linkSrc: 'https://acpress.amherst.edu/books/intersectingcolors/chapter/josef-albers-and-the-science-of-seeing/',
        },
        {
            linkText: 'Why Josef Albers painted his squares',
            linkSrc: 'https://www.phaidon.com/agenda/art/articles/2019/march/18/why-josef-albers-painted-his-squares/',
        },
        // {
        //     linkText: 'ad reinhardt, untitled (blue-purple painting), 1952',
        //     linkSrc: 'https://www.liveauctioneers.com/news/auctions/upcoming-auctions/ad-reinhardt-painting-may-top-1m-at-heritage-auction-may-31/',           
        // },
        {
            linkText: "Bridget Reilly's Aechaean",
            linkSrc: 'https://www.tate.org.uk/art/artworks/riley-achan-t03816',
        },
        
        {
            linkText: 'a nice and short review of a recent bridget reilly show',
            linkSrc: 'https://www.theguardian.com/artanddesign/2019/oct/22/bridget-riley-review-hayward-gallery',
        },
        // {
        //     linkText: 'the use of color thru art history',
        //     linkSrc: 'https://dockyard.com/blog/2015/06/25/color-1-art-history',
        // },
        {
            linkText: 'our Josef Albers box painting at codepen',
            linkSrc: 'https://codepen.io/Fleischut/pen/ExKxexj',
        },
        {
            linkText: 'mondrian in html and css at codepen',
            linkSrc: 'https://codepen.io/nancypwong/pen/vZaLXj?editors=1100',
        },
        {
            linkText: 'steps by step mondrian from google code project',
            linkSrc: 'https://googlecreativelab.github.io/coder-projects/projects/mondrian/',
        },
        {
            linkText: 'make your own Mondrian project from google coder',
            linkSrc: 'https://googlecreativelab.github.io/coder-projects/projects/mondrian/',
        },
        {
            linkText: "'sensational color' website",
            linkSrc: 'https://www.sensationalcolor.com/',
        }
 
    ],

    techLinks: [
        {
            linkText: 'html color codes, a super great web color resource',
            linkSrc: 'https://htmlcolorcodes.com/'
        },
     
        {
            linkText: 'color hexa, another great color reference',
            linkSrc: 'https://www.colorhexa.com/ff0000',
        },
        {
            linkText: 'a simple guide to html',
            linkSrc: 'http://www.simplehtmlguide.com/',
        },
        
        {
            linkText: 'you only need 10 html tags',
            linkSrc: 'http://www.99lime.com/_bak/topics/you-only-need-10-tags/',
        },
        {
            linkText: 'geeks for geeks: the div tag, explained',
            linkSrc: 'https://www.geeksforgeeks.org/div-tag-html/',

        },
        {
            linkText: 'color: hsl calculator',
            linkSrc: 'https://www.w3schools.com/colors/colors_hsl.asp',
        },
        {
            linkText: 'the basics of css',
            linkSrc: 'https://drstearns.github.io/tutorials/css/',
        },

    ],

    videos: [ 
        {
            id : '222222211222',
            title:'html basics: the mighty div, and a few other useful tags',
            description: 'an hour long dive into the basics of html'
        },
        {
            id : '22212212212',
            title: 'css: add some style to your html',
            description: 'a video about the basics of css from one of my favorite teachers of web coding'
        },
        {
            id : '20',
            title:'css: color basics ',
            description: 'a'
        },
        {
            id : '22',
            title:'gradients',
            description: ''
        },
        {
            id : 'xxc',
            title:'css: positioning basics',
            description: ''
        },
        {
            id : 'ddf',
            title:'copy josef albers with html and css',
            description: '',
        },
    ],  
}

export default UNIT1_DAY2