import React from 'react';
import { connect } from 'react-redux';
// import { createStructuredSelector } from 'reselect';
// import { selectCurrentUser } from '../../redux/user/user.selectors';
import Directory from '../../components/directory/directory.component';
import WelcomeMessage from '../../components/welcome-message/welcome-message.component';
import UserPage from '../userpage/userpage.component';
// import Syllabus from '../../components/syllabus/syllabus.component';
import './home-page.styles.scss';

const HomePage = ({currentUser}) => {
    // console.log(currentUser, 'current user on home page');
    if(currentUser && currentUser.id != null) {
        return (
            <div className="">
                <UserPage currentUser={currentUser}/>
            </div>

        )
    }
    
    
    return (
        <div className='about-body'>
                <WelcomeMessage />

                <Directory />
                {/* <Syllabus></Syllabus> */}
        </div>

    )
}
// const mapStateToProps = createStructuredSelector({
//     currentUser: selectCurrentUser
// })


const mapStateToProps = ({users:{currentUser}}) => ({
    currentUser
})


export default connect(mapStateToProps)(HomePage)

// export default HomePage
