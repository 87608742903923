import { ASSIGNMENT9 } from "../../assignments/assignments.data"

const UNIT1_DAY9 = {
    id: 9,
    unit: 'unit1',
    route: '/units/unit1/day9',
    month: 'September',
    day: 24, 
    name: "there's a red dot today so, what the heck, lets have a crit",
    description: "I'd like to start by saying things about why we do crits and what makes a useful crit.  We do crits because we want to know what impact our work is having on some people.  As an artist, this is your best opportunity to learn.  So, don't explain your work in advance.  If it's in the work, it's in the work and if it isn't, then that's something useful to think about.  Try not to be defensive -- not everyone shares your perspective, they might in fact take issue with it.  That doesn't mean you're a bad person or a bad artist, it just means they see things differently.  But, that's super useful info for you if you don't take it personally, and it can be very illuminating if you let it be.  Often times, really good work takes harsh criticism -- BECAUSE it's really good, and it touches people.  Similarly, try not to be offensive as you offer your opinions.  Please, try to be honest but remember that this work was made by a person, for a reason.  And, above all, speak up!  Offer what you can to your peers, they need your help, your compassion, your guidance and your perspective.",
    crit: true,
    assignmentRoute: '/units/unit1/day9/assignment', 
    assignment: ASSIGNMENT9,
    videos: [
        {
            id : 'UB1O30fR-EE',
            title:'html basics from traversy media',
            description: 'an hour long dive into the basics of html'
        },
        {
            id : 'yfoY53QXEnI',
            title: 'css basics from traversy media',
            description: 'a video about the basics of css from one of my favorite teachers of web coding'
        },
        {

        },
        {

        },
        {

        },
        {

        },
    ],    
    inspirationLinks: [

        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        ],
        techLinks: [

            {
                linkText: '',
                linkSrc: '',
            },
            {
                linkText: '',
                linkSrc: '',
            },
            {
                linkText: '',
                linkSrc: '',
            },
            {
                linkText: '',
                linkSrc: '',
            },
        ], 
}

export default UNIT1_DAY9