import * as actionTypes from './topics.types';

export const setCurrentTopic = topic => {
    return {
        type: actionTypes.SET_CURRENT_TOPIC,
        payload: {
            currentTopic : topic
        }
    }
}

export const updateTopics = topics => ({
    type: actionTypes.UPDATE_TOPICS,
    payload: topics
})