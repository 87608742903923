import PROJ4_MARKDOWN from './proj4.markdown.md';

const PROJ4 = {
    id: 4,
    title: 'project 4: final project',
    due: 'December 1',
    route: '/unit4/project/project4',
    teaser: '',
    // description: "For your first project, I'd like you to make a collection.",
    markdown: PROJ4_MARKDOWN,
    inspirationLinks: [
        // {
        //     linkText: 'Lorna Simpson, Easy To Remember',
        //     linkSrc: 'https://vimeo.com/91549843',
        // },
        // {
        //     linkText: 'Christian Marclay, the clock',
        //     linkSrc: 'https://www.youtube.com/watch?v=BXbQw0rE5UE&list=PLWZ5fzURD_c2XUmveT2oF0SNMPB_f291a',
        // },
        // {
        //     linkText: 'Lauren Woods, American Monument',
        //     linkSrc: 'https://hyperallergic.com/461150/american-monument-lauren-woods-kimberli-meyer-california-state-university-long-beach/',
        // },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        // {
        //     linkText: 'zimoun',
        //     linkSrc: 'https://www.zimoun.net/',
        // },
        {
            linkText: '',
            linkSrc: '',
        },
    ]
}

export default PROJ4