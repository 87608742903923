import { ASSIGNMENT17 } from '../../assignments/assignments.data';

const UNIT2_DAY8 = {
    id: 17,
    unit: 'unit2',
    name: '...loading',
    route: '/units/unit2/day17',
    month: 'October',
    day: 22, 
    description: "Today we are going to demo each other's games, your partner should present your game to the class and people will test it out!  Today's assignment page will guide you through the process of creating a seperate landing or splash page for your game, so people can get instructions and context before they play, and also your assets can load!",
    illustrationURL:'',
    assignmentRoute: '/units/unit2/day17/assignment', 
    assignment: ASSIGNMENT17,
    videos: [

        {

        },
        {

        },
        {

        },
        {

        },
    ],    
    inspirationLinks: [

        {
            linkText:"give it juice!",
            linkSrc:"https://www.youtube.com/watch?v=Fy0aCDmgnxg"
        },
        // {
        //     linkText:"Sol Lewitt, Sentences on Conceptual Art",
        //     linkSrc:"http://www.altx.com/vizarts/conceptual.html#:~:text=Conceptual%20artists%20are%20mystics%20rather,conclusions%20that%20logic%20cannot%20reach.&text=If%20the%20artist%20changes%20his,result%20and%20repeats%20past%20results.",
        // },

        {
            linkText:"some good suggestions coding a game engine in JS",
            linkSrc:"https://www.briankoponen.com/html5-javascript-game-tutorial/"
        },
        {
            linkText: "Apple threatens to crush unreal engine ",
            linkSrc: 'https://www.theverge.com/2020/8/17/21372480/apple-epic-threat-developer-tools-agreement-unreal-engine-fortnite',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },

        ],
        techLinks: [
            {
                linkText:"bezier curves and easing (juice) simply explained",
                linkSrc:"http://greweb.me/2012/02/bezier-curve-based-easing-functions-from-concept-to-implementation/"
            },
            {
                linkText:"simplest bezier example ever (juice!), in p5 with comments",
                linkSrc:"https://editor.p5js.org/socalledsound/sketches/vWHUbHZ3Z"
            },
            {
                linkText:"a little more juice for you, with comments",
                linkSrc:"https://editor.p5js.org/socalledsound/sketches/ecrE28PMw"
            },
            {
                linkText: 'How to create an HTML button that acts like a link?',
                linkSrc: 'https://www.geeksforgeeks.org/how-to-create-an-html-button-that-acts-like-a-link/',
            },
            {
                linkText: 'a nice link on promises',
                linkSrc: 'https://web.dev/promises/#whats-all-the-fuss-about',
            },
            {
                linkText: 'promises at mdn',
                linkSrc: 'https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Asynchronous/Promises',
            },
            {
                linkText: 'an example with one promise, loading audio',
                linkSrc: 'https://editor.p5js.org/socalledsound/sketches/u3BeiLJAy',
            },
            {
                linkText: "today's code repo",
                linkSrc: 'https://github.com/socalledsound/SE-unit2-day-17-loading',
            },

        ], 
}

export default UNIT2_DAY8