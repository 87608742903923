import React from 'react';
import { withRouter } from 'react-router-dom';
import './directory-item.styles.scss';

const DirectoryItem = ({unit, history }) => {
    

    // const initRoute = match.path === '/' ? 'units/' : ''; 
    const initRoute = '/units/';
   const  { id, title, subtitle, imageUrl, linkUrl } = unit;
     return (
        <div
        onClick={()=>history.push(`${initRoute}${linkUrl}`)}
        className='directory-item'>

            <div 
            style={{
                backgroundImage: `url(${imageUrl})`
            }}
            className="background-image"
            />
            <div className={`content unit${id}`}>
                <h1 className="title">{title.toUpperCase()}</h1>
                <span className="subtitle">{subtitle}</span>    
            </div>
        </div>
    )
}
export default withRouter(DirectoryItem);