import React from 'react';
import { Button } from 'semantic-ui-react';
import  moment  from 'moment';


class MeetingsStudentRow extends React.Component {
    state = {
        clockRunning: false,
        expired: false,
        time: '5:00',
    }

    startCountdown = () => {
        const countDownDate = moment().add(5, 'minutes');
        const x = setInterval(() => {
        let diff = countDownDate.diff(moment());

        this.setState({clockRunning: true})
        
            if (diff <= 0) {
              clearInterval(x);
               // If the count down is finished, write some text 
              this.setState({ time: '0:00', expired: true})
            } else {
                this.setState({ time: moment.utc(diff).format("HH:mm:ss"), expired: false})
                // $('.countdown').text(moment.utc(diff).format("HH:mm:ss"));
            }

              
    
          }, 1000);
    }

    reset(){
        const {student, markMeetingComplete } = this.props;
        this.setState({
            clockRunning: false,
            expired: false,
            time: '5:00',
        });
        markMeetingComplete(student);
        
    }
    
    render(){
        const {currentUser, student, markMeetingComplete } = this.props;
        const { time, expired, clockRunning } = this.state;


        return (
            <React.Fragment>
                <p className="name" >{student.displayName}</p>
                <p className={`timer ${clockRunning ? 'running' : ''} ${expired ? 'expired' : ''}`}>{time}</p>
                {currentUser.admin &&
                <React.Fragment>
                    {clockRunning &&
                    <Button onClick={()=> this.stopCountdown()} style={{marginLeft: '50px'}}>stop timer</Button>
                    }
                    <Button onClick={()=> this.startCountdown()} style={{marginLeft: '50px'}}>start timer</Button>
                    
                    <Button onClick={()=> this.reset()} style={{marginLeft: '50px'}}>mark complete</Button>
                </React.Fragment>
                }
            </React.Fragment>
        )

    }

}

export default MeetingsStudentRow
