import PROJ1 from '../projects/proj1.data';
import UNIT1_DAY1 from '../days/unit1.days/unit1.day1.data';
import UNIT1_DAY2 from '../days/unit1.days/unit1.day2.data';
import UNIT1_DAY3 from '../days/unit1.days/unit1.day3.data';
import UNIT1_DAY4 from '../days/unit1.days/unit1.day4.data';
import UNIT1_DAY5 from '../days/unit1.days/unit1.day5.data';
import UNIT1_DAY6 from '../days/unit1.days/unit1.day6.data';
import UNIT1_DAY7 from '../days/unit1.days/unit1.day7.data';
import UNIT1_DAY8 from '../days/unit1.days/unit1.day8.data';
import UNIT1_DAY9 from '../days/unit1.days/unit1.day9.data';
import LOGO_UNIT_1 from '../../assets/purecss-francine.png';

const UNIT1 = {
    id: 1,
    title: 'unit1 : collection',
    routeName: '/units/unit1',
    unit: 'unit1',
    imageUrl: LOGO_UNIT_1,
    overview: "This first unit is about collections, otherwise known as data.  What makes a meaningful collection?  What are some of the strategies we can use to tame data, or set it free?  Data is to coding as paint is to painting.  In this unit we will learn some things about storing and manipulating data but also spend a good ammount of time thinking about why we should do this, and explore various approaches to organizing and presenting collections.  Most importantly, we'll discuss how to introduce just enough fuckery to keep things spicy and fun.  We'll look at the work of a wide range of artists who have tackled these problems in interesting ways: Eva Hesse, Agnes Martin, Francis Alys, Joseph Kosuth and, of course, Iggy Azalea.   And many others  .  Wait, what?  Data, Eva Hesse, you ask?  All will be made clear, and I hope it will be a fun process.",
    project: PROJ1,
    items: [UNIT1_DAY1, UNIT1_DAY2, UNIT1_DAY3, UNIT1_DAY4, UNIT1_DAY5, UNIT1_DAY6, UNIT1_DAY7, UNIT1_DAY8, UNIT1_DAY9],
    objectives: "On the technical side, this first unit is primarily concerned with getting a foundational understanding of how to present content on the web.  It will serve as an introduction to HTML, CSS and javascript, the main languages used to build web sites.  We'll paint using CSS, learn about images and text as we make our own meme page, learn how to build web page navigation and handle clicks and other user events, and finally build a sampler.  By the end of this unit you should be well-prepared not only to make and present an interesting collection for project 1, but also to build your own portfolio site, or even build small static sites for friends or, potentially, clients.",
}
export default UNIT1