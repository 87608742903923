import { ASSIGNMENT23 } from '../../assignments/assignments.data';

const UNIT3_DAY6 = {
    id: 23,
    unit: 'unit3',
    route: '/units/unit3/day23', 
    month: 'November',
    day: 12,
    name: 'server/sockets',
    description: "Hold onto your hats.  Today we are going to learn how to connect one browser to another browser, using a technology called web sockets.  These let us do things like have real time chats, or play mutliplayer games.  I'll also introduce you to a key pillar of the javascript world, node, and the node package manager, and we'll use it to quickly and easily build our own web server.",
    illustrationURL:'https://res.cloudinary.com/chris-kubick/image/upload/v1605154288/side-effects/kissclipart-diagram-clipart-diagram-client-amazon-elasticache-2e20865f5cfcf417_fjydbg.png',
    assignmentRoute: '/units/unit3/day23/assignment', 
    assignment: ASSIGNMENT23,
    
    inspirationLinks: [

        {
            linkText: 'hvbrd game',
            linkSrc: 'https://github.com/charliegerard/hvbrd-sockets',
        },
        {
            linkText:"a multiplayer simon game I made with sockets",
            linkSrc:"https://github.com/socalledsound/mmm-simon-heroku"
        },
        {
            linkText: 'my version of the multiplayer tetris game (tutorial below)',
            linkSrc: 'https://tetris-clone-6000.herokuapp.com/#n4agum',
        },
        {
            linkText: 'a little multiplayer sound instrument',
            linkSrc: 'https://sound-circles.herokuapp.com/',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },


        ],
        techLinks: [
            {
                linkText: 'download node',
                linkSrc: 'https://nodejs.org/en/download/',
            },

            {
                linkText: 'Getting started with Node and VS code',
                linkSrc: 'https://code.visualstudio.com/docs/nodejs/nodejs-tutorial',
            },
            {
                linkText: 'the node package manager',
                linkSrc: 'https://www.npmjs.com/',
            },
            {
                linkText: 'a nice intro to node and p5.js',
                linkSrc: 'https://creative-coding.decontextualize.com/node/',
            },    
            {
                linkText: 'what are web sockets?',
                linkSrc: 'https://pusher.com/websockets',
            },

            {
                linkText:"make agar.io with DS",
                linkSrc:"https://www.youtube.com/watch?v=JXuxYMGe4KI&vl=en"
            },
            {
                linkText: 'making an interactive skateboarding game using web sockets',
                linkSrc: 'https://medium.com/@devdevcharlie/hvbrd-c6266ee31461',
            },
            {
                linkText: 'great multiplayer tetris game tutorial (in 3 parts)(uses websockets but not socket.io)',
                linkSrc: 'https://www.youtube.com/watch?list=PLS8HfBXv9ZWW49tOAbvxmKy17gpsqWXaX&v=H2aW5V46khA',
            },
            {
                linkText: 'nice tutorial on making a smartphone controlled game',
                linkSrc: 'https://css-tricks.com/how-to-make-a-smartphone-controlled-3d-web-game/',
            },
            {
                linkText: 'https://github.com/socalledsound/simple-sound-circle-game',
                linkSrc: 'simple sound circle game repo',
            },
            {
                linkText: 'multiplayer sound circle game repo',
                linkSrc: 'https://github.com/socalledsound/sound-circle-server',
            },
            {
                linkText: '',
                linkSrc: '',
            },
            {
                linkText: '',
                linkSrc: '',
            },
        ], 
        videos: [

            {
    
            },
            {
    
            },
            {
    
            },
            {
    
            },
        ],    
}  

export default UNIT3_DAY6