import React from 'react';
// import {}
import './avatar.styles.scss';
// import logo from '../../assets/avatar-temp.jpg';
const Avatar = ({photoURL})=>(
    <div className='avatar'>
        <img src={photoURL} alt="user"/>
    </div>
)

export default Avatar