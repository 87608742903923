import React from 'react';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import DayDirectoryItem from '../day-directory-item/day-directory-item.component.jsx';
import { selectDays } from '../../redux/days/days.selectors';
import './day-directory.styles.scss';

const DayDirectory = ({days, id, dayRouteHandler})=> {
    // console.clear();
    // console.log(days);
    return (

        <div className="day-directory-container">

            {
                days.map( day => {
                    const selected = id === day.id ? 'selected' : '';
                    const { unit } = day;
                    // console.log(unit);
                    // console.log(day.id);
                    const color = ((unit) => {
                        switch(unit) {
                            case 'unit1' : 
                                return 'green'
                            case 'unit2' : 
                                return 'yellow'
                           case 'unit3' : 
                                return 'purple' 
                            case 'unit4' : 
                                return 'pink'                           
                            default:
                              return ''    
                    }
                   })(unit); 

                   return (
                        <DayDirectoryItem 
                            key={day.id} 
                            day={day} 
                            selected={selected} 
                            color={color} 
                            dayRouteHandler={dayRouteHandler}
                            />
                   )
                   


                })
            }


        </div>


    )
}

const mapStateToProps = (state)=>{
    return {
        days: selectDays(state)
    }
    
}

export default connect(mapStateToProps)(DayDirectory)