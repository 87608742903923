import { createSelector } from 'reselect';
// import { selectCurrentWork } from '../works/works.selectors';


const selectUsers = state => state.users;

export const selectCurrentUser = createSelector(
    [selectUsers],
    users => users.currentUser
)

export const selectAllUsers = createSelector(
  [selectUsers],
  users => users.users
)


export const selectItemUser = id => 
createSelector(
  [selectUsers],
  users => {
   console.log(users);
    return users.find(user => user.id === id)
  }
);

export const selectCurrentStudent = id => 
createSelector(
  [selectAllUsers],
  users => {
    console.log(id);
   return users.find(user => user.id === id)
  }
)
