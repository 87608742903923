import React from 'react';
import RESOURCES from '../../redux/resources/resources.data';
import ResourceSection from '../../components/resource-section/resource-section.component';
import './resources-page.styles.scss';

const ResourcesPage = () => {
    
const { resourceTopics } =  RESOURCES;

    const renderSections = () => (
        resourceTopics.map(section => (
        <ResourceSection section={section}/>
        ))
    )

       
        


    return (
        <div className="resource-container">
            <div className="sections">
                {renderSections()}
            </div>
            
        </div>
           
     
    )
}

export default ResourcesPage