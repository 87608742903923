import React from 'react';
import GalleryItem from '../gallery-item/gallery-item.component';



const UserGallery = ({userWorks, windowWidth, windowHeight, scaler, history}) => {

    return (
        userWorks.map(item => <GalleryItem key={item.id} item={item} windowWidth={windowWidth} windowHeight={windowHeight} history={history} scaler={scaler} />)
    )


}

export default UserGallery