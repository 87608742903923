import { createSelector} from 'reselect';

const selectSyllabus = state => state.syllabus;

export const selectUnits = createSelector(
    [selectSyllabus],
    syllabus => syllabus.units
)

export const selectProjects = createSelector(
    [selectSyllabus],
    syllabus => syllabus.projects
)