import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Comment, Button } from 'semantic-ui-react';
// import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { getCompletedDays, getNextDay, getRemainingDays } from '../../redux/days/days.selectors';
// import { selectUserCritMessages } from '../../redux/critMessages/critMessages.selectors';
import { selectUserMessages } from '../../redux/messages/messages.selectors';
import { selectUserWorks } from '../../redux/works/works.selectors';
import { auth, firestore, convertWorksSnapshotToMap, convertMessagesSnapshotToMap  } from '../../firebase/firebase.utils';
import LoginMessage from '../../components/login-message/login-message.component';
// import Status from '../../components/status/status.component';
import Status2 from '../../components/status2/status2.component';
import ProfileModal from '../../components/profile-modal/profile-modal.component';
import SubmitRandomModal from '../../components/submit-random-modal/submit-random-modal.component';
import UserCritMessages from '../../components/user-crit-messages/user-crit-messages.component';
import UserMessages from '../../components/user-messages/user-messages.component';
import UserPageCritMessage from '../../components/user-page-crit-message/user-page-crit-message.component';
import UserGallery from '../../components/user-gallery/user-gallery.component.jsx';
import UserAssignmentsList from '../../components/user-assignments-list/user-assignments-list.component';
import DiscussionMessage from '../../components/discussion-message/discussion-message.component';
import UserMissingAssignmentsList from '../../components/user-missing-assignments-list/user-missing-assignments-list.component';
// import UserMessages from '../../components/user-messages/user-messages.component';
import NextAssignment from '../../components/next-assignment/next-assignment.component';
// import UserAssignments from '../../components/user-assignments/user-assignments.component';
import CustomButton from '../../components/custom-button/custom-button.component';
import { ButtonsBarContainer }  from './user-page.styles.jsx';
import './userpage.styles.scss';
import { updateWorks } from '../../redux/works/works.actions';
import MessageWithResponses from '../../components/message-with-responses/message-with-responses.component';
class UserPage extends React.Component {


        state = {
            usersRef: firestore.collection('users'),
            critMessagesRef: firestore.collection('critMessages'),
            worksRef: firestore.collection('works'),
            messagesRef: firestore.collection('messages'),
            currentUser : null,
            userWorks : null, 
            nextDay: null,
            numCritMessages: null,
            numDiscussionMessages: null,
            profileModal: false,
            randomModal: false,
            completedAssignments: null,
            messagesAboutUserWork: null,
            lastItem: '',
            scaler: 3,
            
        }
    

        componentDidMount(){
            const { critMessagesRef, worksRef, messagesRef } = this.state;
            const { currentUser, nextDay, remainingDays, userNotifications, userWorks,  updateWorks, completedDays } = this.props;
            const { numCritMessages, numDiscussionMessages, completedAssignments } = currentUser;
             console.log(completedAssignments);
            const daysCompleted = 30 - (remainingDays.length-2);
            const assignmentsTilNow = completedDays.map(day=>day.assignment.title).slice(1);
            const assignmentRoutesTilNow = completedDays.map(day => day.assignmentRoute).slice(1);
            console.log(assignmentRoutesTilNow);
            const randomContributions = completedAssignments.filter(item => item === 'random').length;
            // const nonRandomCompletedAssignments = completedAssignments.filter(item => item != 'random');
            const missingAssignments = assignmentsTilNow.filter(item => completedAssignments.indexOf(item) === -1);
            const missingAssignmentRoutes = completedDays.filter(item => completedAssignments.indexOf(item.assignment.title) === -1).map(item => item.assignmentRoute).slice(1);
            console.log(missingAssignmentRoutes);
            let messagesAboutUserWork = [];
            

            if(currentUser.id){
                worksRef.where(`user.id`, '==',  currentUser.id).get().then(snapshot => {
                    const works = convertWorksSnapshotToMap (snapshot);
                     updateWorks(works);
                     this.setState({ userWorks: works})
                }) 
            }

    

            if(userWorks){
                userWorks.map(item => {
                    // console.log(item.id);
                    critMessagesRef.where('topic', '==', item.id).get().then(snapshot => {
                        snapshot.docs.forEach(doc => {
                            // console.log(doc.data());
                            const message = {
                                comment: doc.data().content,
                                about: item.title,
                                timestamp: doc.data().timestamp,
                                by: {
                                    name: doc.data().user.name,
                                    avatar: doc.data().user.avatar,
                                }
                            }
                            // console.log(message);
                            messagesAboutUserWork.push(message);
                            this.setState({ messagesAboutUserWork: messagesAboutUserWork})
                        })
                    }, this.setState({ messagesAboutUserWork: messagesAboutUserWork}))
                    return null
                });
            }

            if(currentUser){
                messagesRef.where('createdBy.id', '==', currentUser.id).get().then(snap => {
                    const userMessages = convertMessagesSnapshotToMap(snap);
                    this.setState({ userMessages })
                })
            }



             this.setState({
                currentUser, 
                userNotifications,
                nextDay,
                numCritMessages,
                numDiscussionMessages,
                completedAssignments,
                remainingDays,
                daysCompleted,
                missingAssignments,
                randomContributions,
                missingAssignmentRoutes,
               
                });

                this.updateWindowDimensions();
                window.addEventListener('resize', this.updateWindowDimensions);       
        }

    

        componentWillUnmount(){
            window.removeEventListener('resize', this.updateWindowDimensions);
            // this.unsubscribeFromUser();
        }


        updateWindowDimensions = () => {
            this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight});
        }

        signOut = () => {
            auth.signOut();
            this.props.history.push(`/`)
        }


        openProfileModal = () => {
            console.log('clicking');
            this.setState({profileModal: true})
        };
        closeProfileModal = () => this.setState({ profileModal: false});

        //openSubmitModal = () => this.setState({submitModal: true})

        openRandomModal = () => this.setState({ randomModal: true });
        closeRandomModal = () => this.setState({ randomModal: false });

        assignmentRouteHandler = (route) => this.props.history.push(route);

        renderMessages = ( messagesAboutUserWork ) => {
           

            return messagesAboutUserWork.sort((a, b) => b.timestamp - a.timestamp).map((item) => {

                // console.log(item);

              return  (
                    <div key={item.timestamp} className="message-container" >
                    {/* <h1>{item.about}</h1> */}
                    <Comment.Group >
                      <UserPageCritMessage message={item} />
                    </Comment.Group>
                  </div>
                )
            })
                

        }

        renderDiscussionMessages = messages => {
            console.log(messages);
            if(messages){
                return messages.sort((a, b) => b.timestamp - a.timestamp).map(message => {
                    
                    if(message.responses){
                        // <div></div>
                        return (
                            <MessageWithResponses message={message} />
                        )
                        
                    }
    
                })
            }



        }


        reLoadData(){
            const {currentUser, userWorks} = this.props;
            const { worksRef, critMessagesRef } = this.state;
            let messagesAboutUserWork = [];

            if(currentUser.id){
                worksRef.where(`user.id`, '==',  currentUser.id).get().then(snapshot => {
                    const works = convertWorksSnapshotToMap (snapshot);
                     updateWorks(works);
                     this.setState({ userWorks: works})
                }) 
            }

    

            if(userWorks){
                userWorks.map(item => {
                    // console.log(item.id);
                    critMessagesRef.where('topic', '==', item.id).get().then(snapshot => {
                        snapshot.docs.forEach(doc => {
                            // console.log(doc.data());
                            const message = {
                                comment: doc.data().content,
                                about: item.title,
                                timestamp: doc.data().timestamp,
                                by: {
                                    name: doc.data().user.name,
                                    avatar: doc.data().user.avatar,
                                }
                            }
                            // console.log(message);
                            messagesAboutUserWork.push(message);
                            this.setState({ messagesAboutUserWork: messagesAboutUserWork})
                        })
                    }, this.setState({ messagesAboutUserWork: messagesAboutUserWork}))
                    return null
                });
            }
        }


    render(){
       const { currentUser, profileModal, randomModal, nextDay, daysCompleted, numCritMessages, 
        numDiscussionMessages, completedAssignments, messagesAboutUserWork, userWorks, userMessages,
        windowWidth, windowHeight, scaler, missingAssignments, randomContributions, missingAssignmentRoutes } = this.state;
        const { userNotifications, history } = this.props;
        console.log(missingAssignmentRoutes);


        if(currentUser != null ) {
            // console.log(messagesAboutUserWork);
            if(userNotifications){
                    // const newNotifications = userNotifications.filter((item) => item.count > 0);
            }
           
            // console.log("NEW NOTIFICATIONS", newNotifications);
        //    console.log(userWorks);
        //    console.log(currentUser);
        //    console.log(userMessages);

        return (
            <div className='user-page'>
                <ButtonsBarContainer>
                    <CustomButton onClick={() => this.openProfileModal()} isProfileButton>
                        edit profile
                    </CustomButton>



                    <CustomButton onClick={()=> this.signOut()} isLogout>
                        logout
                    </CustomButton>
                </ButtonsBarContainer>
                



                {/* <div className='log-out-button' onClick={()=> auth.signOut()}>log out</div> */}


                <div className="user-content">

                    <div className="greeting-block">
                        
                        
                        <div className="greeting-text">
                            <span className="user-image"><Comment.Avatar src={currentUser.photoURL}/></span>
                            <span className='name'>{currentUser.displayName ? currentUser.displayName : currentUser.email }</span>
                    
                            {
                            !currentUser.displayName && (
                                <p style={{marginLeft: '0.5em'}}>please edit your user profile with a name, and a picture of yourself. </p>
                            )
                            
                            } 
                        </div>
                    </div>
                  





                    {/* <div className="new-messages-block">
                        
                        you have 
 
                            {    newNotifications && ( 
                                    newNotifications.map((item, id) => {
                                        
                                        return (
                                            <div key={id}>
                                            <span>{item.count} unread public messages in {item.title} </span>
                                            </div>
                                        )
                                    })
                               
                            )}
                            
                            {newNotifications.length === 0 && (
                                    <div> no unread messages </div>
                            )
                            
                            }
                           
                       

                        in the discussions forum
                    </div>         */}




                    {/* <div className="to-do-block"> */}
                        {/* <Status due={{hours: 20, minutes: 54}}/> */}
                        {/* <Status2 due={{month: nextDay.month, day: nextDay.day}} />

                        <NextAssignment nextDay={nextDay} assignmentRouteHandler={this.assignmentRouteHandler} />   
                   </div> */}
                
                
                   <div className="progress-block">

                        <div className="user-critMessages">

                                {numCritMessages ? <UserCritMessages numCritMessages={numCritMessages} daysCompleted={daysCompleted}/> : <div>you haven't posted any comments on people's work yet</div>}

                        </div>

                        <div className="user-messages">
                            {numDiscussionMessages ? <UserMessages numDiscussionMessages={numDiscussionMessages} daysCompleted={daysCompleted}/> : <div> you haven't posted anything to the discussion forums yet</div>}
                            {/* <UserMessages userMessages={this.state.userMessages}/> */}
                        </div>


                        <div className="user-assignments-list">
                            
                            {completedAssignments ? 
                            <UserAssignmentsList completedAssignments={completedAssignments} />
                            :
                            <div>you haven't submitted any assignments yet</div>
                            }  
                        </div>   

                        <div className="user-missing-assignments-list">
                        
                            {missingAssignmentRoutes !== undefined ? 
                            <UserMissingAssignmentsList missingAssignments={missingAssignments} history={history} missingAssignmentRoutes={missingAssignmentRoutes}/>
                            :
                            <div>you don't have any missing assignments</div>
                            }  
                        </div> 

                        <div className="user-random-submissions">
                            {randomContributions ?
                            <div> you've contributed {randomContributions} submissions to the random gallery.</div>
                                :
                            <div> you haven't made any random contributions</div>

                        }
                            
                        </div>  
                            <div className="random">
                                
                                feel free to submit any old thing to the gallery, at any time, it's your showcase:


                                <div className="random-button">
                                <Button
                                    onClick={() => this.openRandomModal()}
                                    color="green"
                                    content="submit random"
                                    labelPosition="right"
                                    icon="star"
                                    style={{ marginBottom: '1em'}}
                                />
                                </div>

                            </div>




                            
                            <div className="user-gallery">
                                {userWorks ?
                                
                                <UserGallery userWorks={userWorks}  windowWidth={windowWidth} windowHeight={windowHeight} scaler={scaler} history={history}/>
                                :
                                <React.Fragment>
                                 ....sorry, these works didn't load.....
                                <Button  basic color="green" onClick={()=> this.reLoadData()}>reload page</Button>
                            </React.Fragment>
                            }
                                    
                            </div>





                            <div className="feedback-block">
                                    <div>
                                        here's what people have been saying about your work:
                                        {/* {this.checkthatvalue()}
                                        {console.log(Array.isArray(messagesAboutUserWork))}
                                        {console.log(messagesAboutUserWork[1])}
                                        {console.log(messagesAboutUserWork.length)} */}
                                        {
                                         messagesAboutUserWork != null ?    
                                            this.renderMessages(messagesAboutUserWork)
                                            :
                                            <React.Fragment>
                                                ....sorry, these comments didn't load, try refreshing your browser
                                                
                                            </React.Fragment>
                                        }
                                    </div>

                            </div>

                            <div className="user-discussion-block">
                                 {this.renderDiscussionMessages(userMessages)}
                            </div>

                           

                    </div>
                </div>

                    <ProfileModal profileModal={profileModal} closeModal={this.closeProfileModal} user={currentUser}/>
                    <SubmitRandomModal randomModal={randomModal} closeModal={this.closeRandomModal} user={currentUser} />  
            </div>

 


            
        )
    }

    return (
        <div>
            <LoginMessage />
        </div>
    )

}
}



const mapDispatchToProps = dispatch => ({
    updateWorks: works => dispatch(updateWorks(works)),
})

const mapStateToProps = (state, ownProps) => {
     console.log(ownProps.currentUser.id);
    return({
        currentUser: selectCurrentUser(state),
        userWorks: selectUserWorks(ownProps.currentUser.id)(state),
        // userCritMessages: selectUserCritMessages(ownProps.currentUser.id)(state),
        // userMessages: selectUserMessages(ownProps.currentUser.id)(state),
        nextDay: getNextDay()(state), 
        completedDays: getCompletedDays(Date.now())(state),
        remainingDays: getRemainingDays(Date.now())(state),
        userNotifications: state.users.currentUser.userNotifications,
    })
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserPage))