import React from 'react';
import { connect } from 'react-redux';
import { setCurrentTopic } from '../../redux/topics/topics.actions';
import { updateTopics } from '../../redux/topics/topics.actions';
import { setSelectedMessage } from '../../redux/messages/messages.actions';
import { updateUserNotifications } from '../../redux/user/user.actions';
import { Menu, Icon, Modal, Form, Input, Button, Label } from 'semantic-ui-react';
import { firestore, addItemToFirestore, convertTopicsSnapshotToMap, convertMessagesSnapshotToMap } from '../../firebase/firebase.utils';
import './discussion-topics.styles.scss';
class DiscussionTopics extends React.Component {

    state = {
        user: null,
        topics: [],
        loadedTopics: [],
        currentTopic: null,
        topicName: '',
        topicDetails: '',
        topicsRef: firestore.collection('topics'),
        messagesRef: firestore.collection('messages'),
        usersRef: firestore.collection('users'),
        // notificationsRef: firestore.collection('notifications'),
        notifications: null,
        modal: false,
        firstLoad: true,
        activeTopic: null,
        newTopic: null,
        topicsListeners: [],
    }


    componentDidMount(){
        const { topicsRef } = this.state; 
        const { currentTopic, currentUser, userNotifications, updateTopics } = this.props;
       this.setState({user: currentUser});
       
        let activeTopic;
        currentTopic ? activeTopic = currentTopic.id : activeTopic = null;
        // console.log(activeTopic);
        const currentUserNotifications = userNotifications ? userNotifications : [];
        currentTopic ? this.setState({firstLoad: false, user: currentUser, notifications: currentUserNotifications }) : this.setState({firstLoad: true})
        this.setState({activeTopic});
        this.unsubscribeFromTopics = topicsRef.onSnapshot(async snapshot => {
                //  console.log(snapshot);
                //  const topics = snapshot.docChanges();
                
                const topics = convertTopicsSnapshotToMap(snapshot); 
                // updateTopics(topics);
                // this.setState({ topics, currentTopic }, () => this.setFirstTopic());
                const filteredTopics = topics.filter(topic => topic.name !== 'castlist');
                const doubleFilteredTopics = filteredTopics.filter(topic => topic.name !== 'MUSIC!');
                this.setState({ topics: doubleFilteredTopics, currentTopic }, () => this.setFirstTopic());
                updateTopics(doubleFilteredTopics);
                
                // topics.map(topic => {
                //     this.addNotificationsListener(topic.id);
                //     return null
                // })
                

        })


        

          
    }

    componentWillUnmount(){
        // this.deleteFirestoreUserNotifications();
        this.unsubscribeFromTopics();
        // this.state.topicsListeners.map(unsubscribeFromListener => unsubscribeFromListener() );
    }


    addNotificationsListener = topicId => {
        const { messagesRef, currentTopic, notifications, topicsListeners } = this.state;
        const topicMessagesRef = messagesRef.where('topic', '==', topicId);
        
       const unsubscribeFromListener = topicMessagesRef.onSnapshot(snapshot => {
            // console.log(snapshot.docChanges());
            const messages = convertMessagesSnapshotToMap(snapshot);
            if(currentTopic){         
                this.handleNotifications(topicId, currentTopic.id, currentTopic, notifications, messages);
            }

        })
        topicsListeners.push(unsubscribeFromListener);
       
        
        
    }

    handleNotifications = (topicId, currentTopicId, currentTopic, notifications, messages) => {
        const { topics } = this.state;
        // console.log(topics);
        const topic = topics.filter(topic => topic.id === topicId);
        // console.log(topic);
        const {name} = topic[0]; 
        // console.log(name);
        let lastTotal = 0;
        if(notifications){
        let index = notifications.findIndex(notification => notification.id === topicId);
        // console.log(index);
        if(index !== -1){
            if(topicId !== currentTopicId){
                lastTotal = notifications[index].total;
                if(messages.length - lastTotal > 0){
                    notifications[index].count = messages.length  - lastTotal;
                    notifications[index].title = name;
                }
            }
            notifications[index].lastKnownTotal = messages.length;
            notifications[index].title = name;
        } else {
            notifications.push({
                id: topicId,
                title: name,
                total: messages.length,
                lastKnownTotal: messages.length,
                count: 0
            })
        }
        //  console.log("NOTIFICATIONS", notifications);
        this.setState({ notifications });
        const update = {userNotifications: notifications}
         this.updateFirestoreUserNotifications(update);
        // this.props.updateUserNotifications(notifications);

        };
    }


    // deleteFirestoreUserNotifications = () => {
    //     const { usersRef, user} = this.state;
       
    //     if(this.state.user){
    //         console.log(usersRef, user);
    //         this.state.usersRef.doc(this.state.user.id).update({userNotifications: []})
    //     }
        
    // }



    updateFirestoreUserNotifications = update => {
        // this.deleteFirestoreUserNotifications();
    // console.log(update);
    this.state.usersRef.doc(this.state.user.id).update(update)
        .then(()=> {
            console.log("updated user notifications");
            
        })
        .catch(function(error) {
            console.error("Error writing document: ", error);
        });
    
    }

    getNotificationCount = topic => {
        let count = 0;
        if(this.state.notifications)
        this.state.notifications.forEach(notification => {
            // console.log(notification.id, topic.id);
            if(notification.id === topic.id){
                count = notification.count;
            }
        })
        // console.log("COUNT", count)
        if(count > 0) return count
    }


    clearNotifications = topic => {
        const { notifications } = this.state;
        if(notifications){
            let index = this.state.notifications.findIndex(notification => notification.id === topic.id);
            if(index !== -1){
                let updatedNotifications = [...this.state.notifications];
                updatedNotifications[index].total = this.state.notifications[index].lastKnownTotal;        
                updatedNotifications[index].count = 0;
                this.setState({ notifications: updatedNotifications});
                const update = {userNotifications: updatedNotifications}
                this.updateFirestoreUserNotifications(update);
            }
        }

    }


    setFirstTopic = () => { 
        if(this.state.firstLoad && this.state.topics.length > 0){
            const firstTopic = this.state.topics[0];
            if(this.state.activeTopic == null){
                this.props.setCurrentTopic(firstTopic)
                this.setActiveTopic(firstTopic);
            }
 
        }
        this.setState({firstLoad: false});
        // this.setActiveTopic(activeTopic);
        //  this.props.setCurrentTopic(activeTopic);
       

           
    }

    addTopic = () => {
        const { topicName, topicDetails, user } = this.state;
        const now = new Date().getTime();
        // const key = topicsRef.push().key;
        const newTopic = {
            timestamp: now,
            name: topicName,
            details: topicDetails,
            createdBy: {
                name: user.displayName,
                avatar: user.photoURL,
            }
        }

        this.setState({newTopic: newTopic }, () => {
            this.addTopicToFirestore();
           
        }); 
    }


    addTopicToFirestore = () => {
        console.log('topic being added');
        const { newTopic } = this.state;
       
        if(newTopic){
            this.setState({ loading: true });
            addItemToFirestore('topics', newTopic)
                .then(()=>{
                    console.log('successfully added');
                    this.setState({ loading: false, topicName: '', topicDetails: '', firstLoad: false,
                    activeTopic: newTopic})
                    
                })
                .catch( err => {
                    console.error(err);
                    this.setState({
                        loading: false,
                        // errors: this.state.errors.concat(err)
                    })
                })
        }
    }




    displayTopics = topics => (
        topics.length > 0 && topics.map( topic => (
            <Menu.Item
                key={topic.id}
                onClick={() => this.changeTopic(topic)}
                name={topic.name}
                style={{ opacity : '0.9'}}  
                active={topic.id === this.state.activeTopic}  
            >
                {this.getNotificationCount(topic) && (
                    <Label color="red">{this.getNotificationCount(topic)}</Label>
                )}

                # {topic.name}
            </Menu.Item>
        ))
    )

    changeTopic = topic => {
        this.setActiveTopic(topic);
        this.props.setCurrentTopic(topic);
        this.clearNotifications(topic);
        this.props.setSelectedMessage(null);
        // this.props.setPrivateChannel(false);
        this.setState({ topic });

    }

    setActiveTopic = topic => {
        this.setState({ activeTopic: topic.id })
    }

    handleSubmit = e => {
        e.preventDefault();
        this.addTopic();
        this.closeModal();
        // if(this.isFormValid(this.state)){

        
        // }
        
    }

    isFormValid = ({ topicName, topicDetails }) => topicName && topicDetails;


    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value})
}
    closeModal = () => this.setState({ modal: false });
    openModal = () => this.setState({ modal: true });

    render(){
        const { topics, modal } = this.state;
        //  console.log(user, notifications);
        return (
            <React.Fragment>
            <Menu.Menu style={{ paddingBottom: '2em'}}>
                <Menu.Item>
                    <span>
                        <Icon name="exchange" /> topics
                    </span> {" "}
                    ({topics.length}) 
                    
                    
                    <Icon name="add" onClick={this.openModal} className="plus-icon"/>
                     
                    {
                        this.displayTopics(topics)
                    }


                </Menu.Item>

            </Menu.Menu>

            <Modal basic open={modal} onClose={this.closeModal}>

                <Modal.Header>add a topic</Modal.Header>
                <Modal.Content>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Field>
                            <Input 
                                fluid
                                label="name of topic"
                                name="topicName"
                                onChange={this.handleChange}    
                            />
                        </Form.Field>

                        <Form.Field>
                            <Input 
                                fluid
                                label="about the topic"
                                name="topicDetails"
                                onChange={this.handleChange}    
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="green" inverted onClick={this.handleSubmit}>
                        <Icon name="checkmark" />
                    </Button>
                    <Button color="red" inverted onClick={this.closeModal}>
                        <Icon name="remove" />
                    </Button>
                </Modal.Actions>
            </Modal>
            </React.Fragment>
        )
    }
}
const mapDispatchToProps = dispatch => {
 
    return ({
    updateTopics: topics => dispatch(updateTopics(topics)),
    updateUserNotifications: notifications => dispatch(updateUserNotifications(notifications)),
    setCurrentTopic: topic => dispatch(setCurrentTopic(topic)),
    setSelectedMessage: message => dispatch(setSelectedMessage(message)),
    })
}

const mapStateToProps = (state) => ({
    currentTopic: state.topics.currentTopic, 
    userNotifications: state.users.currentUser.userNotifications,
})


export default connect(mapStateToProps, mapDispatchToProps)(DiscussionTopics)