import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const HeaderContainer = styled.div`
font-family: 'Open Sans Condensed', sans-serif;
background-color: rgba(252,250,255,0.9);
// position: fixed;
font-size: 14px;
  height: 70px;
  position: fixed;
  top: 0;
  // width: 99%;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  margin-left: 0.5%;
  margin-bottom: 1em;
  border-radius: 0 0 10px 10px;
  border-bottom: yellowgreen 1px solid;
  border-left: yellow 1px solid;
  border-right: darkgreen 1px solid;
z-index: 100;
  @media screen and (max-width: 500px) {
    height: 80px;
    padding: 5px;
    margin: 5px;
  }
`;

export const LogoContainer = styled(Link)`
  height: 100%;
  width: 70px;
  padding: 15px 25px;


  @media screen and (max-width: 800px) {
    width: 50px;
    // padding: 5px;
  }
`;

export const OptionsContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;

  @media screen and (max-width: 500px) {
    width: 80%;
    height: 20%;
    padding: 5px;
     margin-top: 10px;
    flex-wrap:wrap;
  }
`;

export const OptionLink = styled(Link)`
  padding: 10px 15px;
  cursor: pointer;
  @media screen and (max-width: 500px) {
        padding: 5px;
        margin: 0;
  }
  
`;


export const DiscussionLink = styled(Link)`
  padding: 10px 15px;
  cursor: pointer;
  @media screen and (max-width: 600px) {
        display: none;
  }
  
`;
