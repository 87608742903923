import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestore } from '../../firebase/firebase.utils';
import { Comment, Button } from 'semantic-ui-react';
import moment from 'moment';
import Linkify from 'react-linkify';

import './discussion-message.styles.scss';


const timeFromNow = (timestamp) => moment(timestamp).fromNow();


class DiscussionMessage extends React.Component {
     state = 
     { 
         message: this.props.message,
         currentUser: this.props.currentUser,
         messagesRef: firestore.collection('messages'), 
         selectedMessage: this.props.selectedMessage,
    } 

    deleteMessage = (message) => {
        console.log('deleting', message.id);
        this.state.messagesRef.doc(message.id).delete().then(()=>{
            console.log('deleted');
            //this.state.updateParent();
        });
    }

    editMessage = (message) => {

    }



    isOwnMessage = (message, currentUser) => {
        // return  ? 'message__self' : ''
        return message.createdBy.id === currentUser.id
    }

    render(){
        const { message, currentUser } = this.state;
        const { selectedMessage, history } = this.props;
        const HTMLmessage = {__html: message.content };
        // console.log(message, currentUser, selectedMessage);
        if(selectedMessage){
            // const messageSelected = message.id === selectedMessage.id;
            // console.log(message.responses)

            return (
        
                <Linkify>


                {/* <Comment className={`comment-block selected ${messageSelected ? 'selected' : ''}`}> */}
                <Comment className="comment-block selected">
                    <Comment.Avatar src={message.createdBy.avatar}/>
                    {/* className={isOwnMessage(message, user)} */}
                    <Comment.Content >
                        <Comment.Author as="a" onClick={ ()=> history.push(`/students/${message.createdBy.id}`)}>{message.createdBy.name}</Comment.Author>
                        
                        <Comment.Metadata>{timeFromNow(message.timestamp)}</Comment.Metadata>
                        
                        <Comment.Metadata style={{color: 'red'}}>{message.responses ? `${message.responses.length} responses ` : ''}&#8594;</Comment.Metadata>
                           
                        <div className="comment-text" >{message.content}   </div> 
                           {this.isOwnMessage(message, currentUser) && 
                            (
                                <React.Fragment>
                                {/* <Button icon='pencil' basic onClick={this.editMessage} ></Button> */}
                                <Button icon='trash' basic color='red' size='mini' onClick={ () => this.deleteMessage(message)}></Button>
                                </React.Fragment>
                                   
                              
                            )
                    }
                    </Comment.Content>
                </Comment>
                </Linkify>
        
            )

        } else {
       

        return (
        
            <Comment className="comment-block">
                <Comment.Avatar src={message.createdBy.avatar}/>
                {/* className={isOwnMessage(message, user)} */}
                <Comment.Content >
                    <Comment.Author as="a">{message.createdBy.name}</Comment.Author>
                    
                    <Comment.Metadata>{timeFromNow(message.timestamp)}</Comment.Metadata>
                    <Comment.Metadata style={{color: 'red'}}>{message.responses ? `${message.responses.length} responses ` : ''}&#8594;</Comment.Metadata>
                       
                    <div className="comment-text">{message.content}</div> 
                       {this.isOwnMessage(message, currentUser) && 
                        (
                            <React.Fragment>
                            {/* <Button icon='pencil' basic onClick={this.editMessage} ></Button> */}
                            <Button icon='trash' basic color='red' size='mini' onClick={ () => this.deleteMessage(message)}></Button>
                            </React.Fragment>
                               
                          
                        )
                }
                </Comment.Content>
            </Comment>
    
    
        )
    }
    }

}

const mapStateToProps = (state) => ({
    currentUser: state.users.currentUser,
    selectedMessage: state.discussion.selectedMessage,
})


export default withRouter(connect(mapStateToProps)(DiscussionMessage))