import styled, { css } from 'styled-components';

const buttonStyles = css`
  background-color: black;
  color: white;
  border: none;

  &:hover {
    background-color: white;
    color: black;
    border: 3px solid black;
  }
`;

const invertedButtonStyles = css`
  background-color: white;
  color: black;
  border: 1px solid black;

  &:hover {
    background-color: black;
    color: white;
    border: none;
  }
`;

// const googleSignInStyles = css`
//   background-color: #4285f4;
//   color: white;

//   &:hover {
//     background-color: #357ae8;
//     border: none;
//   }
// `;

const githubSignInStyles = css`
  background-color: #f442e5;
  height: 100px;
  color: white;
  padding-top: 30px;
  border solid 1px #f442e5;
  border-radius: 10px;

  &:hover {
    background-color: #057aa8;
  }
`;

const logOutStyles = css`
background-color: rgba(77, 65, 92, 0.2);
 border: none;
border-radius: 45px;
color: black;

&:hover {
  background-color: rgba(230, 46, 223, 1.0);
  color: white;
}

`;

const profileButtonStyles = css`
background-color: rgba(77, 65, 92, 0.2);
// background-color: white;
border-radius: 45px;
border: none;
color: black;
&:hover {
  background-color: rgba(230, 46, 223, 1.0);
  color: white;
}
`;

const submitButtonStyles = css`
background-color: lightgreen;
border: 1px solid orangered;
color: black;
margin-left: 0px;
margin-top: 12px;
&:hover {
  background-color: lightyellow;
  color: black;
}
`;

const editButtonStyles = css`
background-color: hotpink;
border: 1px solid orangered;
color: white;
margin-left: 0px;
margin-top: 12px;
&:hover {
  background-color: lightyellow;
  color: black;
}
`;


const getButtonStyles = props => {
  if(props.isLogout){
    return logOutStyles;
  }
  if(props.isGithubSignIn){
    return githubSignInStyles;
  }

  if(props.isProfileButton){
    return profileButtonStyles;
  }

  if(props.isSubmitButton){
    return submitButtonStyles;
  }

  if(props.isEditButton){
    return editButtonStyles;
  }

  return props.inverted ? invertedButtonStyles : buttonStyles;

};

export const CustomButtonContainer = styled.button`
  min-width: 90px;
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
  margin-left: 20px;
  padding: 15px 35px 0 35px;
  font-size: 12px;
  border-radius: 6px;
  font-family: 'Open Sans Condensed';
  font-weight: bolder;
  cursor: pointer;
  display: flex;
  justify-content: center;

  ${getButtonStyles}
`;
