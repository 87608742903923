import { ASSIGNMENT20 } from '../../assignments/assignments.data';

const UNIT3_DAY3 = {
    id: 20,
    unit: 'unit3', 
    route: '/units/unit3/day20',    
    month: 'November',
    day: 3,
    name: 'expanded realities',
    description: "XR, or expanded reality, has been one of the biggest buzzwords in digital media lately.  And, by lately, I mean, for the last fifty years or so.  Today we'll start to talk about XR in all of it's various forms: interactive mulitmedia ad campaigns, interdimensional participatory art projects, virtual reality and augmented reality, and more. ",
    illustrationURL: 'https://res.cloudinary.com/chris-kubick/image/upload/v1597869455/side-effects/saraceno-cloud-cities-2_jfdp7z.jpg',
    assignmentRoute: '/units/unit3/day20/assignment', 
    assignment: ASSIGNMENT20,

    inspirationLinks: [
        {
            linkText:"The Participatory Act of Giving up Control",
            linkSrc:"https://thirdspacenetwork.com/symposium2018/participatory-act-giving-up-control/"
        },
        {
            linkText:"Jane McGonigal, Alternate Reality Gaming",
            linkSrc:""
        },	
        {
            linkText:"my year in Sf's exclusive secret society",
            linkSrc:"https://www.vice.com/en_us/article/xygykj/my-year-in-san-franciscos-2-million-secret-society-startup"
        },

        {
            linkText:"the jejeune institute",
            linkSrc:"https://www.vice.com/en_us/article/jp54ky/game-or-cult-the-alternate-reality-of-the-jejune-institute"
        },
        {
            linkText:"9 inch nails ARG",
            linkSrc:"https://www.rollingstone.com/music/music-news/zeros-heroes-nine-inch-nails-get-cryptic-56414/"
        },
        {
            linkText:"the black watchmen",
            linkSrc:"https://www.blackwatchmen.com/"
        },
        {
            linkText:"faust acoustic adventure",
            linkSrc:"http://www.acoustic-adventure.de/projekt.html#"
        },	

        
        {
            linkText:"second life art galleries",
            linkSrc:"http://secondlife.com/destinations/arts"
        },
        {
            linkText:"the art world market of second life",
            linkSrc:"http://minskyreport.com/artworld_market.pdf"
        },
        {						
            linkText:"tuple game",
            linkSrc:"http://games.ucla.edu/game/tupal#gallery-1"
        },
        {
            linkText:"camille utterback + romy achituv - text rain",
            linkSrc:"https://www.youtube.com/watch?v=f_u3sSffS78"
        },
        {
            linkText:"bodyspacemotionthings robert morris",
            linkSrc:"https://www.youtube.com/watch?v=IeUiL5vzSzA"
        },
        {
            linkText:"ay-o, rainbow room",
            linkSrc:"https://www.wikiart.org/en/ay-o/rainbow-environment-no-7-tactile-rainbow-room-1970"
        },
        {
            linkText:"yayoi kusama",
            linkSrc:"https://www.google.com/search?q=yayoi+kusama&sxsrf=ACYBGNRaaUBMsKhbt9cAt1xmeHQb3htXTw:1572931635511&source=lnms&tbm=isch&sa=X&ved=0ahUKEwjwwo_7qtLlAhWOpZ4KHXEiBOYQ_AUIEigB&biw=3164&bih=714"
        },

        {
            linkText:"thomas saraceno - cloud cities",
            linkSrc:"https://studiotomassaraceno.org/"
        },
        {
            linkText:"cat heaven",
            linkSrc:"https://www.youtube.com/watch?v=B21YtuOrKRI"
        },
        {
            linkText:"carsten holler",
            linkSrc:"https://www.dezeen.com/2015/06/09/carsten-holler-decision-london-south-bank-hayward-gallery-playground-slides/"
        },

        {
            linkText: 'Tayshawn Sorey, Autoschediasms, using these new technological approaches to music',
            linkSrc: 'https://www.nytimes.com/2020/10/30/arts/music/tyshawn-sorey-alarm-will-sound-music.html?action=click&module=Features&pgtype=Homepage',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        ],
        techLinks: [
            {
                linkText: 'there sure are a lot of webGL libraries',
                linkSrc: 'https://gist.github.com/dmnsgn/76878ba6903cf15789b712464875cfdc',
            },
            {
                linkText: 'webGL stars no p5',
                linkSrc: 'https://editor.p5js.org/socalledsound/sketches/_zWj2yPRT',
            },
            {
                linkText: 'webGL stars in p5',
                linkSrc: 'https://editor.p5js.org/socalledsound/sketches/HDnzYOp4d',
            },

            {
                linkText: 'creating a scene in three.js',
                linkSrc: 'https://threejs.org/docs/#manual/en/introduction/Creating-a-scene',
            },
            {
                linkText: 'a basic scene in aframe',
                linkSrc: 'https://aframe.io/docs/1.0.0/guides/building-a-basic-scene.html',
            },

            {
                linkText: 'coding train series of videos on webGL',
                linkSrc: 'https://www.youtube.com/watch?v=nqiKWXUX-o8&list=PLRqwX-V7Uu6bPhi8sS1hHJ77n3zRO9FR_',
            },
            {
                linkText: 'three spheres in p5',
                linkSrc: 'https://editor.p5js.org/socalledsound/sketches/px9HYA5rm',
            },
            {
                linkText: 'collapsing gradient cubes',
                linkSrc: 'https://editor.p5js.org/socalledsound/sketches/EbBW9bjW-',
            },

            {
                linkText: 'webGL sound boxes, pixels, fft',
                linkSrc: 'https://editor.p5js.org/socalledsound/sketches/STHJQaBF4',
            },

            {
                linkText: 'cat model',
                linkSrc: 'https://editor.p5js.org/socalledsound/sketches/4TtAlFgQv',
            },
            {
                linkText: 'cat model translated rotated',
                linkSrc: 'https://editor.p5js.org/socalledsound/sketches/yWFxTir8t',
            },

        ], 
        videos: [

            {
    
            },
            {
    
            },
            {
    
            },
            {
    
            },
        ],    
}
export default UNIT3_DAY3