import { ASSIGNMENT25 } from '../../assignments/assignments.data';

const UNIT4_DAY2 = {
    id: 25,
    unit: 'unit4', 
    route: '/units/unit4/day25', 
    name: 'chat',
    month: 'November',
    day: 19,  
    description: "'This website will self-destruct' is a website that's been around all year.  The idea is pretty simple, you can enter a message and/or read messages that other people have left.  If no one posts for a day, then the website dies.  I want to give you the tools to build web sites like this, over the next two days.  We'll look at one part today, text input.  I'll show you that -- and also how to wire that input to a chat service on your own server.  And, in a way, this would actually be enough to build the self-destructing website, you can just store an array on your server instance.  But next class we'll take that a step further, and incorporate an actual database!",
    illustrationURL:'https://res.cloudinary.com/chris-kubick/image/upload/v1597869193/side-effects/jim-campbell-eroding-wave_nf3txs.jpg',
    assignmentRoute: '/units/unit4/day25/assignment', 
    assignment: ASSIGNMENT25,
  
    inspirationLinks: [
        {
            linkText:"artist Jim Campbell",
            linkSrc:"http://www.jimcampbell.tv/"
        },						
        {
            linkText:"artist sara sze",
            linkSrc:"https://www.youtube.com/watch?v=lEjegKJwI0M"
        },
        {
            linkText:"neopixels at adafruit",
            linkSrc:"https://www.adafruit.com/category/168"
        },
        {
            linkText:"similar RGB LEDs at amazon",
            linkSrc:"https://www.amazon.com/s?k=ws2812b+neopixel&gclid=Cj0KCQjw8svsBRDqARIsAHKVyqEfYPwEPCbqtgQYYn2kTO8KZkpRoptLEwGT4ijnkPPIfTfln4quexIaAqJ2EALw_wcB&hvadid=323413849098&hvdev=c&hvlocphy=9032066&hvnetw=g&hvpos=1t1&hvqmt=b&hvrand=13033651025361872985&hvtargid=aud-649564993678%3Akwd-304290146850&hydadcr=16029_9865036&tag=googhydr-20&ref=pd_sl_1ay6p078sm_b"
        },
        {
            linkText:"box - projection mapping",
            linkSrc:"https://www.youtube.com/watch?v=lX6JcybgDFo"
        },
        {
            linkText:"artist sara sze",
            linkSrc:"https://www.youtube.com/watch?v=lEjegKJwI0M"
        },
        {
            linkText:"obscura digital at the exploratorium",
            linkSrc:"https://www.youtube.com/watch?v=N2ZMH1XIxBI"
        },
        {
            linkText:"another big obscura digital projection mapping project",
            linkSrc:"https://www.youtube.com/watch?v=Vga3HgtIQs8"
        },
        {
            linkText:"projection mapping onto warped surfaces",
            linkSrc:"https://gizmodo.com/this-real-time-image-warping-projector-will-blow-your-m-1788032732"
        },
        {
            linkText: 'scuttlebutt',
            linkSrc: 'https://blog.p2pfoundation.net/scuttlebutt-an-off-grid-social-network/2018/04/26',
        },
        {
            linkText: 'remote learning is here to stay, can we make it better?',
            linkSrc: 'https://www.theverge.com/21570482/remote-learning-khan-academy-interview-decoder-podcast',
        },
        {
            linkText: "a game designer's analysis of qanon",
            linkSrc: 'https://medium.com/curiouserinstitute/a-game-designers-analysis-of-qanon-580972548be5',
        },
        {
            linkText: 'inside a dali painting in web VR',
            linkSrc: 'https://thedali.org/dreams-of-dali-2/',
        },
        {
            linkText: 'jacolby satherwaite at art 21',
            linkSrc: 'https://art21.org/artist/jacolby-satterwhite/',
        },
        {
            linkText: 'this website will self-destruct',
            linkSrc: 'https://www.thiswebsitewillselfdestruct.com/',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        ],
        techLinks: [
            {
                linkText:"LED blink on raspberry pi",
                linkSrc:"http://johnny-five.io/examples/raspi-io/"
            },
            {
                linkText:"neopixels on r pi tutorial from adafruit",
                linkSrc:"https://learn.adafruit.com/neopixels-on-raspberry-pi"
            },
            {
                linkText:"another intro to neopixels on r pi",
                linkSrc:"https://thepihut.com/blogs/raspberry-pi-tutorials/using-neopixels-with-the-raspberry-pi",
            },
            {
                linkText:"a beginners guide to projection mapping",
                linkSrc:"https://www.youtube.com/watch?v=skU3T8bgEIM"
            },
            // {
            //     linkText:"shiffman video series on working with pixels and video in p5.js",
            //     linkSrc:"https://www.youtube.com/playlist?list=PLRqwX-V7Uu6aKKsDHZdDvN6oCJ2hRY_Ig"
            // },
            // {
            //     linkText:"my video particles thing",
            //     linkSrc:"https://editor.p5js.org/socalledsound/sketches/qF1reWJMy"
            // },
            {
                linkText: 'socket server game with chat (repo)',
                linkSrc: 'https://github.com/socalledsound/sound-circle-server',
            },
            {
                linkText: 'roblox studio is actually kind of fun',
                linkSrc: 'https://docs.google.com/document/d/1OZPtHp6Q_flbjMSNQqMTNYr2s3HCjyNHlA3OCMaSz8M/edit',
            },
            {
                linkText: '',
                linkSrc: '',
            },
            {
                linkText: '',
                linkSrc: '',
            },
        ], 
        videos: [

            {
    
            },
            {
    
            },
            {
    
            },
            {
    
            },
        ],  
}

export default UNIT4_DAY2