import React from 'react';
import { connect } from 'react-redux';
import { Segment, Comment } from 'semantic-ui-react';
import { updateSelectedMessageResponses } from '../../redux/messages/messages.actions';
import DiscussionResponse from '../discussion-response/discussion-response.component';
import DiscussionMessage from  '../discussion-message/discussion-message.component';
import DiscussionMessageFormTextArea from '../discussion-message-form-text-area/discussion-message-form-text-area.component';
import './discussion-thread-panel.styles.scss';
class DiscussionThreadPanel extends React.Component {


    state = { selectedMessage: null}

    componentDidMount() {
        const { selectedMessage } = this.props;
        this.setState({ selectedMessage });  
      }
  
    deleteResponse = (selectedMessage, message) => {
        // console.log(index);
        // console.log('deleting response', selectedMessage.responses[index]);
        // const update = [...selectedMessage.responses];
        // console.log('UPDATE:', update);
        // update.splice(index, 1);
        // console.log('UPDATED:', update);
        // this.props.updateSelectedMessageResponses(update);
        
    }


    renderResponses(){
        const { selectedMessage } = this.props;
        console.log('in responses');
        if(selectedMessage){

            console.log(selectedMessage.responses[0]);
            return selectedMessage.responses.map((response, index) => 
                 (
                    <DiscussionResponse className="message" key={index} message={response} deleteResponse={this.deleteResponse} index={index}/>
                )
        )
        }

    }


    render (){
        const { currentUser, currentTopic } = this.props;
        const { selectedMessage } = this.props;
        console.log(selectedMessage, 'in thread panel');
        if(selectedMessage){
            return (
                
                    <React.Fragment>
                        <Segment 
                        className="discussion-thread-container"
                        // style={{ background: '#FFF', fontSize: '1.2rem', marginTop: '0em', marginLeft: '-1.2em', height: '676px', paddingBottom: '100px', overflow: 'auto'   }}
                        >
                        

                                <Comment.Group className="threaded-messages">
                                        <DiscussionMessage className="message" key={selectedMessage.id} message={selectedMessage} />
                    
                                        <DiscussionMessageFormTextArea 
                                            className="discussion-message-form"
                                            topic={currentTopic}
                                            user={currentUser}  
                                            selectedMessage={selectedMessage}
                                        /> 

                                        
                                        {selectedMessage.responses &&
                                        selectedMessage.responses.map((response, index) => 
                                        (
                                             <DiscussionResponse className="message" key={index} message={response} deleteResponse={this.deleteResponse} index={index}/>
                                        )
                                        )}

                                </Comment.Group>    
                           
                        </Segment>
                    </React.Fragment>
               
            )
        } else {
            return (
                <div>
                <React.Fragment>
                    <Segment 
                    className="discussion-thread-container"
                    // style={{ background: '#FFF', fontSize: '1.2rem', marginTop: '0em', marginLeft: '-1.2em', height: '776px', }}
                    >
                        select a message to the left to see responses, or write one
                    </Segment>
                </React.Fragment>
            </div>
            )
        }

    }
}


const mapDispatchToProps = dispatch => ({
    // updateSelectedMessageResponses : update => dispatch(updateSelectedMessageResponses(update)),
})


const mapStateToProps = (state) => ({
    currentUser: state.users.currentUser,
    selectedMessage: state.discussion.selectedMessage,
})

export default connect(mapStateToProps, mapDispatchToProps)(DiscussionThreadPanel)