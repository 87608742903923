// https://side-effects-7f7e8.web.app

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// import './App.scss';
import { GlobalStyle } from './global.styles';
// import HomePage from './pages/homepage/homepage.component';
import GalleryPage from './pages/gallerypage/gallerypage.component';
import HomePage from './pages/home-page/home-page.component';
import ResourcesPage from './pages/resources-page/resources-page.component';
// import SyllabusPage from './pages/syllabuspage/syllabuspage.component';
import Syllabus from './components/syllabus/syllabus.component';
import UnitPage from './pages/unitpage/unitpage.component';
import DayPage from './pages/daypage/daypage.component';
import AssignmentPage from './pages/assignmentpage/assignmentpage.component';
import ProjectPage from './pages/projectpage/projectpage.component';
// import UserPage from './pages/userpage/userpage.component';
// import ProfilePage from './pages/profilepage/profilepage.component';
// import SubmitWorkPage from './pages/submit-work-page/submit-work-page.component';
import DiscussionPage from './pages/discussionpage/discussionpage.component';
import Header from './components/header/header.component.jsx';
import SignInPage from './pages/sign-in-page/sign-in-page.component';
// import AssignmentPage from './pages/assignmentpage/assignmentpage.component';
// import GalleryOverview from './components/gallery-overview/gallery-overview.component';
import GalleryItemPage from './pages/gallery-item-page/gallery-item-page.component';
import { auth, createUserProfileDocument, firestore, convertUsersSnapshotToMap } from './firebase/firebase.utils';
// import { auth,  firestore,  convertTopicsSnapshotToMap, convertWorksSnapshotToMap, convertCritMessagesSnapshotToMap, convertMessagesSnapshotToMap, createUserProfileDocument} from './firebase/firebase.utils';
import { setCurrentUser, updateUsers } from './redux/user/user.actions';


// import { getWorks } from './redux/works/works.selectors';
// import { createStructuredSelector } from 'reselect';
// import { selectCurrentUser } from './redux/user/user.selectors';
// import RegisterUserPage from './pages/register-user-page/register-user-page.component';
import AllStudentsPage from './pages/all-students-page/all-students-page.component';
import StudentPage from './pages/student-page/student-page.component';
import MeetingsPage from './pages/meetings-page/meetings-page.component';
// import LoadingSpinner from './components/loading-spinner/loading-spinner.component';
import RPiPage from './pages/rpi-page/rpi-page.component';
import FinalShowPage from './pages/final-show-page/final-show-page.component';
import FinalProjectItemPage from './pages/final-project-item-page/final-project-item-page.component';
import BookPage from './pages/book-page/book-page.component';
class App extends React.Component {



  unsubscribeFromAuth = null;

  componentDidMount(){
    
   

    const { setCurrentUser, updateUsers } = this.props;
    const usersRef = firestore.collection('users');
    this.unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
      if(userAuth){
        const userRef = await createUserProfileDocument(userAuth);
        userRef.onSnapshot(snapShot => {
         
          setCurrentUser({
              id: snapShot.id,
              ...snapShot.data()
          })
        });
        



      }
       setCurrentUser(userAuth);
       
       this.unsubscribeFromUsers = usersRef.onSnapshot(documentSnapshots => {
        // const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
        console.log(documentSnapshots);
        const users = convertUsersSnapshotToMap(documentSnapshots);
        console.log('USERS: ', users);
        updateUsers(users);
        this.setState({loading: false});

        })
      
    })



    

   

  

   





    //need to bring in notifications here and send them to the store
    //is that all new messages?




  }

  componentWillUnmount(){
    this.unsubscribeFromAuth();
      this.unsubscribeFromUsers();
    
    

    // this.unsusbscribeFromWorks();


  }

  render(){
    const {currentUser, users} = this.props;
    
    console.log('USERS in APP', users);

      return (
        <div>
           <GlobalStyle />
          <Header  />
          <div className='main-body'>
   
        
  
  
            <Switch>
              {/* <Route exact path='/' component={HomePage} /> */}
              <Route exact path='/gallery' component={(props) => (<GalleryPage {...props} currentUser={currentUser}/>) } />
              <Route exact path='/' component={(props) => (<HomePage {...props} currentUser={currentUser}/>) } />
              <Route exact path='/resources' component={ResourcesPage} />
              
              {/* <Route path='/units' component={SyllabusPage} /> */}
              <Route exact path='/units' component={Syllabus} />
              <Route exact path='/units/:unitId' component={UnitPage} />
              <Route exact path='/units/:unitId/:dayId' component={DayPage} />
              <Route exact path='/units/:unitId/:dayId/assignment' render={(props) => currentUser ? (<AssignmentPage {...props} currentUser={currentUser}/>) : (<SignInPage />)} /> 
              <Route exact path='/:unitId/project/:projectId' render={(props) => currentUser ? (<ProjectPage {...props} currentUser={currentUser}/>) : (<SignInPage />)} />
              {/* <Route exact path='/units/unit1' component={UnitPage} /> */}
              {/* <Route exact path='/userHome' component={UserPage} />  */}
              {/* <Route exact path='/register' render={ () => currentUser ? (<Redirect to='/' />) : (<RegisterUserPage />)}/> */}
              <Route exact path='/discussion' component={(props) => (<DiscussionPage {...props} currentUser={currentUser} students={users}/>) } /> 
              <Route exact path='/signin' render={ () => currentUser ? (<Redirect to='/' />) : (<SignInPage />)} /> 
              {/* <Route exact path='/unit' component={DiscussionPage}/> */}
              {/* <Route exact path='/profile' render={(props) => this.props.currentUser ? (<ProfilePage {...props} currentUser={this.props.currentUser}/>) : (<SignInPage />)}/> */}
              {/* <Route exact path='/assignment1' component={AssignmentPage} /> */}
              {/* <Route path ={'/gallery/:workId'} component={GalleryItemPage} /> */}
              <Route path ={'/gallery/:workId'} render={(props) => (<GalleryItemPage {...props} currentUser={currentUser}/>)} />
              <Route path={'/students/:userId'} render={(props) => (<StudentPage {...props} students={users} currentUser={currentUser}/>)} />
              <Route path={'/meetings/:projectId'} render={(props) => (<MeetingsPage {...props} currentUser={currentUser}/>)} />
              <Route path={'/students'} render={(props) => (<AllStudentsPage {...props} students={users} currentUser={currentUser}/>)} />
              <Route exact path='/rpi' component={RPiPage} /> 
              <Route exact path='/finalshow' component={FinalShowPage} /> 
              <Route path ={'/finalshow/:workId'} render={(props) => (<FinalProjectItemPage {...props} />)} />
              <Route exact path='/book' component={BookPage} /> 
  
            </Switch>
          </div>
  
        </div>
      )

      }

}

// const mapStateToProps = ({ user }) => ({
//   currentUser: user.currentUser,

// })

// const mapStateToProps = createStructuredSelector({
//   currentUser: selectCurrentUser,
//   // works: getWorks
// })

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
  users: state.users.users,
})

const mapDispatchToProps = dispatch => ({
  setCurrentUser: user => dispatch(setCurrentUser(user)),
  updateUsers: users => dispatch(updateUsers(users)),
  // updateUsers: users => dispatch(updateUsers(users)),
  // updateWorks: works => dispatch(updateWorks(works)),
  
})


export default connect(mapStateToProps, mapDispatchToProps)(App);
