import React from 'react';
import { withRouter } from 'react-router-dom';
import './syllabus-unit.styles.scss';
import SyllabusDay from '../syllabus-day/syllabus-day.component';


const SyllabusUnit = ({match, title, imageUrl, items, routeName, unit, history}) => {
    return (
            <div className='syllabus-unit-block'>
             
            <div className="background-image"
            style={{ backgroundImage: `url(${imageUrl})` }}
            />
           
            <div className='content'>
                
                    <h3 className={`title ${unit}`} onClick={()=>history.push(`${routeName}`)}>{title}</h3>
                    
                    <div className="syllabus-days-container">
                        {
                            items.map(({id, ...itemProps})=>(
                                <SyllabusDay key={id} {...itemProps} />
                            ))
                        }
                    </div>
 
                
  
            </div>
        </div>
    )

}

export default withRouter(SyllabusUnit)



    // console.log(match);
    // return(


        

    // )