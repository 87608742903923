import React from 'react';
import './resource-section.styles.scss';
const ResourceSection = ({ section }) => {
    const { links } = section;
    const renderLinks = () => (
        links.length > 0 && links.map((link, index) => 
        (
            <li key={index} className="resource-link"><a href={link.linkSrc}>{link.linkText}</a></li> 
        ))
    )
    

return(
   
        <div className='resource-section'>
            <h2>{section.title}</h2>
            <span> <p>{section.description}</p> </span>
           
            <ul className="links-list">
            {renderLinks()}
            </ul>
        </div>
        
  
)

}

export default ResourceSection