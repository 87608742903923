import React from 'react';
import { addItemToFirestore } from '../../firebase/firebase.utils';
import uuidv4 from 'uuid/v4';
import { Button, Input } from 'semantic-ui-react';
// import { Picker, emojiIndex} from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';


// import FileModal from './FileModal';
// import ProgressBar from './ProgressBar';


class BookMessageForm extends React.Component {

    state = {
        bookRef: 'book',
        name: '',
        comment: '',
        loading: false,
        errors: [],
        message: null,
    }


    componentDidMount(){

    }

    handleChange = event => {
        console.log(event.target.value);
        this.setState({ [event.target.name]: event.target.value});
    }

    handleKeyPress = (e) => {
        if (e.charCode === 13) {
          // Prevent the default action to stop scrolling when space is pressed
        //   e.preventDefault()
          console.log('Button received click with keyboard');
          this.sendMessage();
        }
      }

    createMessage = () => {
        const now = new Date().getTime();
        const message = {
          id: uuidv4(),
            timestamp: now,
            comment: this.state.comment,
            name: this.state.name,

        };
        this.setState({message: message }, () => {
          this.uploadMessage(); 
      }); 
    }

    uploadMessage = () => {
        const { updateParent }  = this.props;
        const { bookRef, message } = this.state;

        if(message){
            this.setState({ loading: true});
            //  addItemToFirestore(`${critMessagesRef}/${item.id}`, this.createMessage())
             addItemToFirestore(bookRef, message)
                .then(() => {
                    this.setState({ loading: false, message: null, comment: '', name: ''});
                    updateParent();
                    console.log('success');
                    // this.updateUserMessageTotal();
                })

        
        } else {
            this.setState({
                errors: this.state.errors.concat({message: 'add a message'})
            })
        }


    }



    render(){
        const { errors, loading, message, comment, name } = this.state;
        console.log(message);
        return (
           <div className="message__form">
           <Input 
                    
                    fluid
                    name="name"
                    style={{ marginBottom: '1.5em' }}
                    placeholder="your name"
                    value={name}
                    onChange={this.handleChange}
                    className={
                        errors.some( err => err.message.includes('title')) ? 'error' : ''
                    }
                    
                />   

            <textarea
              style={{
                height: '120px',
                width: '100%',
                lineHeight: '1em',
                fontFamily: 'Martel',
                padding: '6px',
              }}
              name="comment"
              placeholder="leave a comment"
              onChange={this.handleChange}
            //   value={this.state.textareaValue}
              value={comment}
            />
            <Button
                       icon = 'add'
                       color = 'teal'
                       onClick={()=> this.createMessage() } 
                    style={{width: '100%'}}

            > add comment
            </Button>
              </div>


        )
    }

}
export default BookMessageForm




// messagesRef
// .child(channel.id)
// .push()
// .set(this.createMessage())
// .then(() => {
//     this.setState({ loading: false, message: ''})
// })
// .catch(err=>{
//     console.error(err);
//     this.setState({
//         loading: false,
//         errors: this.state.errors.concat(err)
//     })
// })