import PROJ2 from '../projects/proj2.data';
import UNIT2_DAY1 from '../days/unit2.days/unit2.day1.data';
import UNIT2_DAY2 from '../days/unit2.days/unit2.day2.data';
import UNIT2_DAY3 from '../days/unit2.days/unit2.day3.data';
import UNIT2_DAY4 from '../days/unit2.days/unit2.day4.data';
import UNIT2_DAY5 from '../days/unit2.days/unit2.day5.data';
import UNIT2_DAY6 from '../days/unit2.days/unit2.day6.data';
import UNIT2_DAY7 from '../days/unit2.days/unit2.day7.data';
import UNIT2_DAY8 from '../days/unit2.days/unit2.day8.data';
import LOGO_UNIT_2 from '../../assets/pitagoru1.png';

const UNIT2 = {
    id: 2,
    title: 'unit 2 : goldbergian',
    routeName: '/units/unit2',
    unit: 'unit2',
    imageUrl: LOGO_UNIT_2,
    overview: "In the second unit we'll be thinking about systems, using Goldberg's famous machines as our model.  After getting a degree in engineering from UC Berkeley in 1904, Rube Goldberg quickly realized that working as an engineer for the fore-runner of EBMUD just wasn't for him.  Instead, he did the sensible thing and became a cartoonist.  Over the years, his drawings of intricate and whimsical machines have become iconic.  We're going to try to make our own playful versions of Goldberg machines in this unit, using javascript.  These can be games, or they can just be playful interactions.  Read more after the click.",
    project: PROJ2,
    items: [UNIT2_DAY1, UNIT2_DAY2, UNIT2_DAY3, UNIT2_DAY4, UNIT2_DAY5, UNIT2_DAY6, UNIT2_DAY7, UNIT2_DAY8],
    objectives: "On the technical side of things, in this unit we will be focusing mostly on javascript.  We'll explore functions, objects, classes and the various ways of composing more sophisticated programs using these building blocks.  ",
}
export default UNIT2