import React from 'react';
import { Comment } from 'semantic-ui-react';
import DiscussionResponse from '../discussion-response/discussion-response.component';
// import DiscussionMessageStripped from '../discussion-message-stripped/discussion-message-stripped.component';
import './message-with-responses.styles.scss';

const renderResponses = responses => {
    
   console.log(responses);
   return  ( responses.map(response => {
       return(
           <div className="response-block">
                <Comment.Group >
                    <DiscussionResponse message={response} />
                </Comment.Group>
            </div>
       )}
   ))
}



const MessageWithResponses = ({message}) => {
    console.log(message);
    console.log(message.responses);

    return (

            <div className="user-comment-block">

                Your discussion comment: <span >{message.content} </span>has <span >{message.responses.length}</span> replies:
                {/* <DiscussionMessageStripped message={message} /> */}
                {renderResponses(message.responses)}
            {/* <Comment.Group >
                <DiscussionMessage message={message} />
            </Comment.Group> */}
            </div>

    )


}

export default MessageWithResponses




