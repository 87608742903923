import PROJ3_MARKDOWN from './proj3.markdown.md';

const PROJ3 = {
    id: 3,
    title: 'project 3: sockets',
    due: null,
    route: '/unit3/project/project3',
    teaser: '',
    // description: "For your first project, I'd like you to make a collection.",
    markdown: PROJ3_MARKDOWN,
    inspirationLinks: [
        {
            linkText: 'Daniel Rozin',
            linkSrc: 'http://www.smoothware.com/danny/',
        },
        {
            linkText:"jane friedhof, scream em up",
            linkSrc:"https://vimeo.com/41629022"
        },
        {
            linkText:"hit me by kaho abe",
            linkSrc:"https://vimeo.com/29638917"
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: 'zimoun',
            linkSrc: 'https://www.zimoun.net/',
        },
        {
            linkText: '',
            linkSrc: '',
        },
    ]
}

export default PROJ3