import * as actionTypes from './messages.types';

export const updateMessages = messages => ({
    type: actionTypes.UPDATE_MESSAGES,
    payload: messages
})

export const setSelectedMessage = message => ({
    type: actionTypes.SET_SELECTED_MESSAGE,
    payload: message
})

export const updateSelectedMessageResponses = update => ({
    type: actionTypes.UPDATE_SELECTED_MESSAGE_RESPONSES,
    payload: update
}) 