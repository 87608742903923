import React from 'react';
import FinalProjectItem from '../final-project-item/final-project-item.component';
import './final-show.styles.scss';


class FinalShow extends React.Component {



    renderProjects(works, history){
       
        return works.map( item => {
            return <FinalProjectItem key={item.id} item={item} history={history} />
        })
    }
 
    


    render(){
        const {works, history} = this.props;
       
        return (
            <React.Fragment>
                {this.renderProjects(works, history)}
            </React.Fragment>
                
            
         
           
        )

        // this.renderStudents(students);
            
            
    }



}

export default FinalShow