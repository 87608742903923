import { createSelector } from 'reselect';
import { selectUnits } from '../syllabus/syllabus.selectors';

const UNIT_ID_MAP = {
  unit1: 1,
  unit2: 2,
  unit3: 3,
  unit4: 4,
}

export const selectCurrentUnit = unitUrlParam =>
  createSelector(
    [selectUnits],
    units => units.find(unit => unit.id === UNIT_ID_MAP[unitUrlParam])
  );

  // export const selectItems = createSelector(
  //   [selectCurrentUnit],
  //   unit => unit.items
  // );
  

  
