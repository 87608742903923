import { CritMessagesActionTypes } from './critMessages.types';

export const updateCritMessages = critMessages => ({
    type: CritMessagesActionTypes.UPDATE_CRIT_MESSAGES,
    payload: critMessages
})


export const updateItemCritMessages = critMessages => ({
    type: CritMessagesActionTypes.UPDATE_ITEM_CRIT_MESSAGES,
    payload: critMessages
})

export const concatCritMessages = critMessages => ({
    type: CritMessagesActionTypes.ADD_TO_CRIT_MESSAGES,
    payload: critMessages
})


export const updateUserCritMessages = critMessages => ({
    type: CritMessagesActionTypes.UPDATE_USER_CRIT_MESSAGES,
    payload: critMessages
})


// export const addSubmission = submission => ({
//     type: WorksActionTypes.ADD_SUBMISSION,
//     payload: submission
// })