import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUnits } from '../../redux/syllabus/syllabus.selectors';
import { selectDays } from '../../redux/days/days.selectors';
import SyllabusUnit from '../syllabus-unit/syllabus-unit'; 
// import DayDirectory from '../day-directory/day-directory.component';
import SyllabusOverview from '../syllabus-overview/syllabus-overview.component';
// import Directory from '../directory/directory.component';
import './syllabus.styles.scss';

const Syllabus = ({ units, days }) => {
        console.log('in syllabus', units);
        return (

           <React.Fragment>

               
                 <div className = 'syllabus-container'>
                    
                {
                        units.map(({id, ...otherProps}) =>(
                            
                                <SyllabusUnit key={id} {...otherProps} />
                            ))
                } 
                
                </div> 

                <SyllabusOverview />
            </React.Fragment>

        )
    
}

const mapStateToProps = createStructuredSelector({
    units: selectUnits,
    days: selectDays,
})

export default connect(mapStateToProps)(Syllabus)
