import React from 'react';

import { Loader, Dimmer } from 'semantic-ui-react';

const LoadingSpinner = () => {
    return (
        <Dimmer active>
            <Loader size="huge" content={"loading"}/>
        </Dimmer>

    )
}

export default LoadingSpinner