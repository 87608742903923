import React from 'react';
import { Link } from 'react-router-dom';
import './welcome-message.styles.scss';

const WelcomeMessage = ()=>(
    <div>
       <div className='block'>
       {/* <p>Hello!  My name is <Link to='/aboutme' className="welcome-link">Chris Kubick</Link> and I'm teaching this class.</p> */}

       <p>Hello!  If you want to learn how to make interesting art while you learn to code, you've come to the right place.</p>
       </div>
       <div className="block">
       <p>If you're interested, you can take a look at the <Link to='/units' className="welcome-link">syllabus</Link> or see what's going on in the <Link to='/gallery' className="welcome-link">gallery.</Link> </p>
       </div >
        {/* <div className='block'> 
        <p>In addition to the online material, we will be having twice weekly zoom meetings as a class.</p>
        <p>I am also happy to meet one on one with students on zoom by appointment.</p>
       
       </div >
        <div className='block'>
        <p>If you want to get in touch with me, and you are in the class, the best way to do so is by <Link to='/discussion'>messaging</Link> me.</p>
        <p>If you want to get in touch with me and you're not in the class, send me an <a href="mailto:socalledsound@gmail.com">email</a>.</p>
       
        </div > */}
        {/* <div className='block'>
        <p>To join the class, you'll need a <a href="https://github.com/join">github</a> account, then just head over to the <Link to="/signin">sign in page</Link> and log in.</p>
        <p></p>
        </div> */}
        {/* <div className="block">
            <p>To join the class, you need to <Link to="/register" className="welcome-link">register</Link>.</p>
        </div> */}

        <div className="block">
            <p>Class is full for the rest of the year, and I've closed registration to the site, but if you're interested in future cohorts or workshops, send me an <a href="mailto:socalledsound@gmail.com" style={{color: 'red'}}>email</a>!</p>
        </div>

        <div className="block">
            <p>If you're already in the class and already created an account on this site, you'll need to <Link to="/signin" className="welcome-link">login</Link> to see assignments or post to the discussions.</p>
        </div>

        <div className="block">
            <p> If you have any questions, please send me an <a href="mailto:socalledsound@gmail.com" style={{color: 'red'}}>email</a>.</p>
        </div>

    </div>
)

export default WelcomeMessage