import React from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
// import { createStructuredSelector } from 'reselect';
import { selectCurrentUnit } from '../../redux/units/unit.selectors';
// import CustomButton from '../../components/custom-button/custom-button.component';
// import MoreButton from '../../components/custom-more-button/custom-more-button';
import DayLink from '../../components/day-link/day-link.component'
import Directory from '../../components/directory/directory.component';
// import ProjectTeaser from '../../components/project-teaser/project-teaser.component';
import './unitpage.styles.scss'


const UnitPage = ({ unit, history }) => {
    const { title, items, imageUrl, overview, project, objectives, id } = unit;
// console.log(project.route);
// console.log(history);
    return (
        <div>
        <div className="directory-horizontal">
        <Directory />    
        </div> 
     
        <div className='unit-page'>

            <div className="background-image"
            style={{ backgroundImage: `url(${imageUrl})` }}
            />
            
            <div className="content">
                
                
                <div className={`header-block unit${id}`}>
                    <h2 className='title'>{title}</h2>
                  <h6 className='overview'>{overview}</h6>
                  {project.due && (
                  <h4 style={{color: 'blue', width: '100%', marginBottom: '1.2em'}}>project {id} due: {project.due} at midnight!</h4>
                  )}
                  {project.due && (
                  <div className="teaser-block" onClick={() => history.push(project.route)}>
                    {/* <ProjectTeaser project={project} /> */}
                    read more about project {id}
                 
                    </div>  
                  )}
                </div>
                    
                <div className='dates-block'>
                        <div className="dates-container">
                        {
                            items.map(item =>(
                                <DayLink key={item.id} item={item}/>
                            ))
                        }
                        </div>

                </div> 

                <div className="objectives-block">
                        <div className="objectives">
                             <p>{objectives}</p>
                        </div>
                        
                </div>

          
                    
        


 

                

            </div>     
        </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    // console.log(ownProps.match.params.unitId);
    return ({
        unit: selectCurrentUnit(ownProps.match.params.unitId)(state)
    }) 
} 

export default withRouter(connect(mapStateToProps)(UnitPage))