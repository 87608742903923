import { createSelector } from 'reselect';

const DAY_ID_MAP = {
    day1: 1,
    day2: 2,
    day3: 3,
    day4: 4,
    day5: 5,
    day6: 6,
    day7: 7,
    day8: 8,
    day9: 9,
    day10: 10,
    day11: 11,
    day12: 12,
    day13: 13,
    day14: 14,
    day15: 15,
    day16: 16,
    day17: 17,
    day18: 18,
    day19: 19,
    day20: 20,
    day21: 21,
    day22: 22,
    day23: 23,
    day24: 24,
    day25: 25,
    day26: 26,
    day27: 27,
    day28: 28,
    day29: 29,
    day30: 30,
    day31: 31,
  }


  const makeDate = (day) => {
    const newDate = Date.parse(`${day.month} ${day.day} 2020`);
    // console.log(newDate, 'make date in day selector');
    return newDate 
    
  }


  const makeDateMinus12 = (day) => {
    const newDateObj = new Date();
    const newDate = Date.parse(`${day.month} ${day.day} 2020`);
    newDateObj.setTime(newDate);
    newDateObj.setHours(newDateObj.getHours() + 24);

    // console.log(newDate, 'make date in day selector');
    return newDateObj 
  }

  export const selectDays = state => state.days.items;

  export const selectCurrentDay = dayUrlParam => 
  createSelector(
    [selectDays],
    days => days.find(day => day.id === DAY_ID_MAP[dayUrlParam])
);



export const selectDaysInCurrentMonth = currentMonth =>
    createSelector(
      [selectDays],
      days => days.find(day => day.month === currentMonth)
    );

export const getRemainingDays = (now) => 
      createSelector(
        [selectDays],
        days => days.filter(day => (makeDate(day) > now))
      );

export const getCompletedDays = (now) => 
      createSelector(
        [selectDays],
        days => days.filter(day => (makeDate(day) < now))
      );      

export const getIncludingCurrentDay = (now) => 
      createSelector(
        [selectDays],
        days => days.filter(day => (makeDate(day) >= now))
      );      



const remainingDaysForNextDay = (now) => 
      createSelector(
        [selectDays],
        days => days.filter(day => (makeDateMinus12(day) > now))
      );      



// export const getNextDay = () => {
  
// return(
//   createSelector(
//     [remainingDaysForNextDay(Date.now())],
//     days => days[0]
//     )
// )
// };

export const getNextDay = () => {
  
  return(
    createSelector(
      [remainingDaysForNextDay(Date.now())],
      days => days[0]
      )
  )
  };

  export const getCurrentDay = () => {
  
    return(
      createSelector(
        [getCompletedDays(Date.now())],
        days => days[days.length-1]
        )
    )
    };


// days => days.reduce((day => makeDate(day) > now).sort()
