import  { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './user/user.reducer';
import directoryReducer from './directory/directory.reducer';
import syllabusReducer from './syllabus/syllabus.reducer';
import daysReducer from './days/days.reducer';
import worksReducer from './works/works.reducer';
import critMessagesReducer from './critMessages/critMessages.reducer';
import topicsReducer from './topics/topics.reducer';
import messagesReducer from './messages/messages.reducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['users', 'works', 'critMessages', 'topics', 'discussion']
}

const rootReducer = combineReducers({
    users: userReducer,
    directory: directoryReducer,
    syllabus: syllabusReducer,
    days: daysReducer,
    works: worksReducer,
    critMessages: critMessagesReducer,
    topics: topicsReducer,
    discussion: messagesReducer,
})

export default persistReducer(persistConfig, rootReducer);