import { ASSIGNMENT16 } from '../../assignments/assignments.data';

const UNIT2_DAY7 = {
    id: 16,
    unit: 'unit2',
    name: 'boids',
    route: '/units/unit2/day16',
    month: 'October',
    day: 20, 
    description: "Today you should be mostly focused on finishing your midterm project, if you haven't, or refining it, if you have.  I'll take some time to work with as many of you as possible.  But first, I want to take a few minutes to talk about AI, which is often lurking under the surface of a game, usually in a pretty simple form.  We'll look a the classic boids algorithm and talk a bit about the larger implications of machine learning and machine unlearning.",
    illustrationURL:'https://res.cloudinary.com/chris-kubick/image/upload/v1603142033/side-effects/my-boids_zmmw21.jpg',
    assignmentRoute: '/units/unit2/day16/assignment', 
    assignment: ASSIGNMENT16,
    
    inspirationLinks: [

        {
            linkText:"Sunspring -- movie written by an AI that won a film festival",
            linkSrc:"https://arstechnica.com/gaming/2016/06/an-ai-wrote-this-movie-and-its-strangely-moving/"
        },
        {
            linkText:"Bina 48 robot Meets Bina Rothblatt, the artist who it is based on",
            linkSrc:"https://www.youtube.com/watch?v=KYshJRYCArE"
        },

        {
            linkText:"The Uncanniest Valleys youtube compilation",
            linkSrc:"https://www.youtube.com/watch?v=IM82RzN0urI"
        },
        {
            linkText:"The Uncanny Valley, Popular Science",
            linkSrc:"https://www.youtube.com/watch?v=CNdAIPoh8a4"
        },

        {
            linkText:"Kanye Lyric Generator",
            linkSrc:"http://kanyerest.xyz/serenade"
        },
        {
            linkText:"Massive networks of twitter bots",
            linkSrc:"http://www.bbc.com/news/technology-38724082"
        },
        {
            linkText:"cambridge analytica, the data that turned the world upside down",
            linkSrc:"https://motherboard.vice.com/en_us/article/mg9vvn/how-our-likes-helped-trump-win"
        },	

        {
            linkText:"speaking of power and data, Robert Mercer",
            linkSrc:"https://www.theguardian.com/politics/2017/feb/26/robert-mercer-breitbart-war-on-media-steve-bannon-donald-trump-nigel-farage"
        },
        {
            linkText:"a twitter bot that apologizes for every trump tweet",
            linkSrc:"https://github.com/ipc103/apology-trump"
        },
        {
            linkText:"chatbot that offers legal aid to refugees",
            linkSrc:"https://www.theguardian.com/technology/2017/mar/06/chatbot-donotpay-refugees-claim-asylum-legal-aid"
        },
        {
            linkText:"'the fall of humanity', list of twitter bots",
            linkSrc:"https://twitter.com/ckolderup/lists/the-fall-of-humanity/members"
        },
        {
            linkText:"uncharted atlas is a bot that makes imaginary maps",
            linkSrc:"https://twitter.com/unchartedatlas"
        },	
        {
            linkText:"Researchers shut down AI that invented its own language",
            linkSrc:"http://www.digitaljournal.com/tech-and-science/technology/a-step-closer-to-skynet-ai-invents-a-language-humans-can-t-read/article/498142#.WXmHKf3ZQ38.facebook"
        },
        {
            linkText:"Musician Taryn Southern on composing her new album entirely with AI",
            linkSrc:"https://www.theverge.com/2017/8/27/16197196/taryn-southern-album-artificial-intelligence-interview"
        },
        {
            linkText:"AI learns to re-create Super Mario Bros. by watching someone else play it",
            linkSrc:"https://www.theverge.com/2017/9/10/16276528/ai-video-games-game-engine"
        },
        {
            linkText:"google deep dream generator",
            linkSrc:"https://deepdreamgenerator.com/"
        },
        {
            linkText:"some cooler and less corporately whitewashed user generated deep dreams",
            linkSrc:"http://psychic-vr-lab.com/deepdream/"
        },

        {
            linkText:"sketch-rnn -- draw with AI",
            linkSrc:"https://magenta.tensorflow.org/assets/sketch_rnn_demo/index.html"
        },
        {
            linkText:"AI drum machine - kyle macdonald et al",
            linkSrc:"https://experiments.withgoogle.com/ai/drum-machine/view/"
        }, 

        {
            linkText: 'Someone let a GPT-3 bot loose on Reddit — it didn’t end well',
            linkSrc: 'https://thenextweb.com/neural/2020/10/07/someone-let-a-gpt-3-bot-loose-on-reddit-it-didnt-end-well/',
        },
        {
            linkText: 'the data scientist exposing white supremacists',
            linkSrc: 'https://www.theguardian.com/us-news/2020/oct/01/white-supremacist-protest-activism-emily-gorcenski',
        },
        {
            linkText: 'the dangerous rise of military AI',
            linkSrc: 'https://www.theguardian.com/news/2020/oct/15/dangerous-rise-of-military-ai-drone-swarm-autonomous-weapons',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        ],
        techLinks: [

            {
                linkText:"smart rockets coding challenge",
                linkSrc:"https://thecodingtrain.com/CodingChallenges/029-smartrockets.html"
            },

            {
                linkText:"smart rockets",
                linkSrc:"https://editor.p5js.org/socalledsound/sketches/6vsWtGZ9t"
            },
            {
                linkText: 'daniel shiffman: an introduction to runway ML',
                linkSrc: 'https://www.youtube.com/watch?v=ARnf4ilr9Hc&feature=youtu.be',
            },
            {
                linkText: 'daniel shiffman: AI rainbows with p5.js',
                linkSrc: 'https://www.youtube.com/watch?v=vEetoBuHj8g',
            },    
            {
                linkText:"flocking/boids in p5",
                linkSrc:"https://p5js.org/examples/hello-p5-flocking.html"
            },
            {
                linkText:"another boids implementation in p5",
                linkSrc:"https://editor.p5js.org/socalledsound/sketches/8yQ5f9lYE"
            },
            {
                linkText:"a flock of singing boids",
                linkSrc:"https://www.youtube.com/watch?v=COeC7UdIBn8&feature=youtu.be"
            },
            {
                linkText:"steering behaviors for autonomous characters",
                linkSrc:"https://www.red3d.com/cwr/steer/gdc99/"
            },
            {
                linkText:"flocking/boids exlplained",
                linkSrc:"http://harry.me/blog/2011/02/17/neat-algorithms-flocking/"
            },
            {
                linkText:"boids explained in a video (with processing sketch)",
                linkSrc:"https://www.youtube.com/watch?v=QbUPfMXXQIY"
            },
                        // {
            //     linkText:"wekinator -- gui-based machine learning",
            //     linkSrc:"http://www.wekinator.org/"
            // },
            // {
            //     linkText:"shiffman/make a twitter bot",
            //     linkSrc:"http://shiffman.net/a2z/twitter-bots/"
            // },
            // {
            //     linkText:"shiffman course on machine intelligence looks interesting!",
            //     linkSrc:"https://github.com/shiffman/NOC-S17-2-Intelligence-Learning"
            // },
            // {
            //     linkText:"build a neural net in 4 minutes",
            //     linkSrc:"https://www.youtube.com/watch?v=h3l4qz76JhQ"
            // },
            // {
            //     linkText:"Building A Simple AI Chatbot With Web Speech API And Node.js",
            //     linkSrc:"https://www.smashingmagazine.com/2017/08/ai-chatbot-web-speech-api-node-js/"
            // },
            // {
            //     linkText:"MarI/O - Machine Learning for Video Games",
            //     linkSrc:"https://www.youtube.com/watch?v=qv6UVOQ0F44"
            // },	
            // {
            //     linkText: 'Deep AI text generation API',
            //     linkSrc: 'https://deepai.org/machine-learning-model/text-generator',
            // },
            {
                linkText: '',
                linkSrc: '',
            },
            {
                linkText: '',
                linkSrc: '',
            },
            {
                linkText: '',
                linkSrc: '',
            },
            {
                linkText: '',
                linkSrc: '',
            },
        ], 
        videos: [

            {
    
            },
            {
    
            },
            {
    
            },
            {
    
            },
        ],    
}
export default UNIT2_DAY7