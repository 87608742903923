// import ARCHIVED_WORKS from './works.data';
import { WorksActionTypes } from './works.types';

// const INITIAL_STATE = ARCHIVED_WORKS;
const INITIAL_STATE = {
    items: null,
    currentItem: null,
};

const worksReducer = (state = INITIAL_STATE, action) => {
    console.log(action);
    switch(action.type){
       
       case WorksActionTypes.UPDATE_WORKS:
        
           const items = state.items != null ? state.items.slice() : [];
           if(items.length > 0){
            action.payload.map(newItem => {
                const itemExists = state.items.some(item => item.id === newItem.id);
                if(!itemExists){
                    items.push(newItem)
                }
                return null
            })
           } else {
            action.payload.map(newItem => {
                items.push(newItem)
                return null
            })
           }

           return {
               ...state,
               items 
           }
       case WorksActionTypes.SET_CURRENT_ITEM:
           return {
               ...state,
               currentItem : action.payload
           }

        case WorksActionTypes.UPDATE_CURRENT_ITEM:
            console.log(action.payload);
            const updatedItem = action.payload;
            console.log(updatedItem.id);
            const oldItems = state.items.slice();
            const newItems = oldItems.filter(item => {
                console.log(item.id);
                return item.id !== updatedItem.id
            });
            newItems.push(updatedItem)
            return {
                ...state,
                items: newItems
            }   

        case WorksActionTypes.REMOVE_ITEM:
            
            const itemsToModify = state.items.slice();
            const updatedItems = itemsToModify.filter(item => item.id !== action.payload.id);


        return {
            ...state,
            items: updatedItems,
            
        }

        // case WorksActionTypes.UPDATE_FEATURED:
        //     console.log(action.payload);

        //     return {
        //         ...state
        //     }   
        
           
        default:
            return state
    }
}
export default worksReducer
