import React from 'react';
// import { Link } from 'react-router-dom';
// import { auth } from '../../firebase/firebase.utils';
import { connect } from 'react-redux';
// import { ReactComponent as Logo } from '../../assets/crown.svg';
import Avatar from '../avatar/avatar.component';
import { Icon } from 'semantic-ui-react';
// import DiscussionOverview from '../discussion-overview/discussion-overview.component';
import './header.styles.scss';

import {
    HeaderContainer,
    LogoContainer,
    OptionsContainer,
    OptionLink,
    DiscussionLink
  } from './header.styles';


const Header = ({ currentUser }) => {
    

    
    
    
    return (
      <HeaderContainer>
        <LogoContainer to="/">
            {/* <Logo className='logo' /> home */}
            <Icon name='circle outline' size='large' color='violet'/>
        </LogoContainer>
        <OptionsContainer>
        <OptionLink className='option' to='/finalshow'>
                FINAL!
            </OptionLink>
            <OptionLink className='option' to='/gallery'>
                gallery
            </OptionLink>
            <OptionLink className='option' to='/resources'>
                resources
            </OptionLink>

            <OptionLink className='option' to='/units'>
                syllabus
            </OptionLink>
            {/* <Link className='option' to='/contact'>
                contact
            </Link> */}
            <DiscussionLink to='/students'>
                            people
            </DiscussionLink>
            {
                currentUser ? 
                    // <div className='options'>
                    <React.Fragment>
                        {/* <DiscussionLink to='/discussion'>
                            discussion
                        </DiscussionLink> */}


                        
                        <OptionLink className='option' to='/'>
                            <Avatar photoURL={currentUser.photoURL}/>
                        </OptionLink>
                    </React.Fragment>
                        :
                    <React.Fragment>
                        <OptionLink className='login' to='/signin'>log in</OptionLink>  
                    </React.Fragment>  
                }
        </OptionsContainer>
        </HeaderContainer>
    )

}

const mapStateToProps = ({users:{currentUser}}) => ({
    currentUser
})


export default connect(mapStateToProps)(Header);