import React from 'react';
import { firestore, addItemToFirestore } from '../../firebase/firebase.utils';
// import {  Segment, Button, Input, Icon } from 'semantic-ui-react';
import {  Input } from 'semantic-ui-react';
// import { Picker, emojiIndex} from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';


// import FileModal from './FileModal';
// import ProgressBar from './ProgressBar';


class MessageForm extends React.Component {

    state = {
        usersRef: 'users',
        uploadState: '',
        uploadTask: null,
        percentUploaded: 0,
        message: '',
        channel: this.props.channel,
        loading: false,
        user: this.props.user,
        errors: [],
        modal: false,
    }


    componentDidMount(){

    }


    openModal = () => this.setState({ modal: true});
    closeModal = () => this.setState({ modal: false});

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value, user: this.props.user})
    }

    handleKeyPress = (e) => {
        if (e.charCode === 13) {
          // Prevent the default action to stop scrolling when space is pressed
        //   e.preventDefault()
          console.log('Button received click with keyboard');
          this.sendMessage();
        }
      }

    createMessage = (fileUrl = null) => {
        const { user } = this.props;
        console.log(user, 'in create message');
        const { item } = this.props;
        const now = new Date().getTime();
        const message = {
            timestamp: now,
            content: this.state.message,
            topic: item.id,
            user: {
                id: this.state.user.id,
                name: user.displayName,
                avatar: user.photoURL,
            }
        };
        // if(fileUrl !== null){
        //     message['image'] = fileUrl;
        // } else {
        //     message["content"] = this.state.message;
        // }

        return message
    }

    sendMessage = () => {
        const { critMessagesRef, updateParent }  = this.props;
        const { message } = this.state;

        if(message){
            this.setState({ loading: true});
            //  addItemToFirestore(`${critMessagesRef}/${item.id}`, this.createMessage())
             addItemToFirestore(critMessagesRef, this.createMessage())
                .then(() => {
                    this.setState({ loading: false, message: ''});
                    updateParent();
                    console.log('success');
                    this.updateUserMessageTotal();
                })

        
        } else {
            this.setState({
                errors: this.state.errors.concat({message: 'add a message'})
            })
        }


    }

    updateUserMessageTotal (){
        const { user } = this.props;
        let updatedVal = 1;
        if(user.numCritMessages){
            updatedVal = user.numCritMessages + 1;
        } 
    
       const update = {numCritMessages: updatedVal}
       firestore.collection(this.state.usersRef).doc(user.id).update(update)
       .then(()=> {
           console.log("updated num user crit messages ");
       })
       .catch(function(error) {
           console.error("Error writing document: ", error);
       });
    }

    render(){
        const { errors, loading, message } = this.state;
        return (
           <div className="message__form">
               <Input 
                    
                    fluid
                    name="message"
                    style={{ marginBottom: '0.7em' }}
                    action={{
                        
                        icon : 'add',
                        color: 'teal',
                        onClick: ()=> this.sendMessage()
                        }}
                    // label={<Button icon={'add'}/>}
                    // labelPosition="left"
                    placeholder="leave a comment"
                    disabled={loading}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    value={message}
                    className={
                        errors.some( err => err.message.includes('message')) ? 'error' : ''
                    }
               />
            <div className="button-group">
                {/* <Button icon onClick={this.sendMessage} basic className="emoji-button">
                    <Icon name="smile" />                    
                </Button> */}
                {/* <Button
                    onClick={this.openModal}
                    color="teal"
                    content="upload media"
                    labelPosition="right"
                    icon="cloud upload"
                /> */}

            </div>

            {/* <FileModal
                    modal={modal}  
                    uploadFile={this.updloadFile}
                    closeModal={this.closeModal}
            /> */}

            {/* <ProgressBar
                uploadState={uploadState}
                percentUploaded={percentUploaded}
            /> */}

           </div>
        )
    }

}
export default MessageForm




// messagesRef
// .child(channel.id)
// .push()
// .set(this.createMessage())
// .then(() => {
//     this.setState({ loading: false, message: ''})
// })
// .catch(err=>{
//     console.error(err);
//     this.setState({
//         loading: false,
//         errors: this.state.errors.concat(err)
//     })
// })