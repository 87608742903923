import { ASSIGNMENT19 } from '../../assignments/assignments.data';

const UNIT3_DAY2 = {
    id: 19,
    unit: 'unit3', 
    route: '/units/unit3/day19',    
    month: 'October',
    day: 29,
    name: 'cameras and pixels',
    description: "Today we'll learn how to use a camera to take data in to the web browser.  We'll also learn how to maipulate that data at the pixel level.  This means we'll work in nested for loops, to create grids of color info.",
    illustrationURL:'https://res.cloudinary.com/chris-kubick/image/upload/v1603987612/zombie_jts9s0.jpg',
    assignmentRoute: '/units/unit3/day19/assignment', 
    assignment: ASSIGNMENT19,
  
    inspirationLinks: [

        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        ],
        techLinks: [
            {
                linkText: 'Daniel Shiffman - the pixel array',
                linkSrc: 'https://www.youtube.com/watch?v=nMUMZ5YRxHI',
            },
            {
                linkText: 'Daniel Shiffman; painting with pixels',
                linkSrc: 'https://www.youtube.com/watch?v=NbX3RnlAyGU&vl=en',
            },
            {
                linkText: 'pixelate video example',
                linkSrc: 'https://editor.p5js.org/socalledsound/sketches/MHpS_is5i',
            },
            {
                linkText: 'video particles',
                linkSrc: 'https://editor.p5js.org/socalledsound/sketches/f3mHA8cO2',
            },
            {
                linkText: 'clmtrackr',
                linkSrc: 'https://github.com/auduno/clmtrackr',
            },
            {
                linkText: 'my clmtrackr starter',
                linkSrc: 'https://github.com/socalledsound/clmtrackr-mask',
            },

            {
                linkText: '',
                linkSrc: '',
            },
            {
                linkText: '',
                linkSrc: '',
            },
            {
                linkText: '',
                linkSrc: '',
            },
        ], 
        videos: [

            {
    
            },
            {
    
            },
            {
    
            },
            {
    
            },
        ],   
}
export default UNIT3_DAY2