import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getNextDay } from '../../redux/days/days.selectors';
import AssignmentBlock from '../assignment-block/assignment-block.component';
import './day-overview.styles.scss';
// import { Button } from 'semantic-ui-react';
// import CustomButton from '../custom-button/custom-button.component';
class DayOverview extends React.Component {
    

    state = {
        modal: false,

    };
    // 

    // const assignmentButton = detailed === true ? <CustomButton isSubmitButton onClick={() => history.push('./assignment1')}>today's instructions</CustomButton> : <div></div>;


    openModal = ()=> this.setState({modal: true})



    render() {
        const {unit, name, month, day, description, assignment, illustrationURL, assignmentRoute, inspirationLinks, techLinks, history, assignmentRouteHandler, nextDay} = this.props;
        
       console.log(month, day);

    return (
        <div className="day-overview-section">
            
            <div className="content" >
                <div className="topline">
                    <div className={`unit-button ${unit}`} onClick={()=>history.push(`/units/${unit}`)}>{unit}</div>
                    <h5 className="title">{month} {day} : {name}</h5>
                </div>

                <div className="day-description">
                    <div className="description-border">

                        {illustrationURL && <img src={illustrationURL} alt="" className="illustration"/>}

                        <p className="description-text">{description}</p>
                    </div>
                    
                </div>
                

               
                <AssignmentBlock assignment={assignment} assignmentRoute={assignmentRoute} assignmentRouteHandler={assignmentRouteHandler} nextDay={nextDay} month={month} day={day}/>
                
                
                
            </div>
               
               <div className="links-section">
                  
                    <ul className="links-list">
                        <span className='links-title'>inspiration & ideas :</span>
                    {inspirationLinks.map((link, index) => (
                                <li key={index} className="day-overview-link"><a href={link.linkSrc}>{link.linkText}</a></li>            
                        ))}
                    </ul>
                    <ul className="links-list">
                    <span className='links-title'>tools & techniques :</span>
                    {techLinks.map((link, index) => (
                                <li key={index} className="day-overview-link"><a href={link.linkSrc}>{link.linkText}</a></li>            
                        ))}
                    </ul>

                    {/* <div className="to-videos">watch today's videos</div> */}
                </div> 

            
        </div>    
        )
    }
}

const mapStateToProps = (state) => ({
    nextDay: getNextDay()(state)
})

export default withRouter(connect(mapStateToProps)(DayOverview))