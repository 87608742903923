import React from 'react';
import { Progress } from 'semantic-ui-react';


const UserCritMessages = ({ numCritMessages, daysCompleted }) => {
    // console.log(daysCompleted);
    // // const daysElapsed = 4;
    // const numCritMessages = userCritMessages.length;
    const critMessagePercent = ((numCritMessages * 80) / daysCompleted);

    // console.log(userCritMessages);
    
    if(numCritMessages > 0) {

            return (
                <React.Fragment>
                     you've left {numCritMessages} comments on other people's work
                    {/* {renderUserCritMessages()} */}
                <Progress percent={critMessagePercent} color="purple">
                  
                </Progress>
               
                </React.Fragment>
            )
        }
        return (
            <div>head over to the gallery and comment on some of the pieces you see there, it's an important part of this process!</div>
        )
            
            
        



}

export default UserCritMessages