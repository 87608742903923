import { ASSIGNMENT11 }  from '../../assignments/assignments.data';

const UNIT2_DAY2 = {
    id: 11,
    unit: 'unit2',
    name: 'pendulum music',
    route: '/units/unit2/day11',
    month: 'October',
    day: 1,  
    description: "The notion that art can be a process rather than an object is, in my opinion, one of the most important art historical developments of the 20th century, and I think it is largely due to the technological and social changes that we've already been discussing.  One of my favorite articulations of this idea came in the 60's in Steve Reich's 'Music As A Gradual Process'.  We'll talk about this short text today, and explore some of the art works that I think are examples of these ideas in action.",
    illustrationURL:'https://res.cloudinary.com/chris-kubick/image/upload/v1597702132/side-effects/Output-14303_hcqpcw.jpg',
    assignmentRoute: '/units/unit2/day11/assignment', 
    assignment: ASSIGNMENT11,
       
    inspirationLinks: [
        {
            title: 'steve reich, music as a gradual process',
            link : 'https://drive.google.com/file/d/1clbQPUwUO2FBwk4dfUnQH0XXuS-b0ORr/view?usp=sharing',
        },        {
            linkText:"Steve Reich, Music For 18 Musicians",
            linkSrc:"https://www.youtube.com/watch?v=ZXJWO2FQ16c"
        },
        {
            linkText: 'steve reich - come out',
            linkSrc: 'https://www.youtube.com/watch?v=g0WVh1D0N50',
        },
        {
            linkText:"Pendulum Music",
            linkSrc:"https://www.youtube.com/watch?v=fU6qDeJPT-w"
        },
        {
            linkText:"Steve Reich + Aphex Twin Pendulum Music",
            linkSrc:"https://www.youtube.com/watch?v=xvdXc-7hkcE"
        },
        {
            linkText: 'Camille Norment, Lull',
            linkSrc: 'https://www.sfmoma.org/read/camille-norment/',
        },
        {
            linkText:"Henry Flynt - Hillbilly Tape Music",
            linkSrc:"https://www.youtube.com/watch?v=LstLpd_iVWA"
        },
        {
            linkText:"gifsound mashups",
            linkSrc:"https://gifsound.com/"
        },
        {
            linkText:"Pamela Z is a composer who works with loops a lot",
            linkSrc:"https://vimeo.com/channels/pamelaz"
        },

        {
            linkText:"WEB AUDIO LOOP MIXER",
            linkSrc:"http://middleearmedia.com/webaudioloopmixer/"
        },
        {
            linkText:"Yoshi Sodeoka -- 10 Years of Psychic TV's Frenetic Visuals Become Looping Video Art",
            linkSrc:"https://creators.vice.com/en_us/article/d7475j/10-years-of-psychic-tv-become-looping-video-art"
        },

        {
            linkText:"Richard Serra Throws Molten Lead Inside sfmoma",
            linkSrc:"https://www.sfmoma.org/richard-serra-throws-molten-lead-inside-sfmoma/"
        },				
        {
            linkText:"Michael Snow's Wavelength in 2.5 minutes",
            linkSrc:"https://www.youtube.com/watch?v=d3nIQs52UXI"
        },
        {
            linkText:"Bruce Naumann violin tuned dead",
            linkSrc:"https://www.youtube.com/watch?v=yMYgZJBNsm0"
        },
        {
            linkText:"Having Never Written a Note for Percussion -- James Tenney",
            linkSrc:"https://www.youtube.com/watch?v=Zd5WNTiwiU8"
        },

        {
            linkText: 'Paul Pfeiffer, an artist who does great work with loops',
            linkSrc: 'http://bombmagazine.org/article/2543/paul-pfeiffer',
        },
        {
            linkText: '8 creativew coders who make amazing generative sketches',
            linkSrc: 'https://blog.kadenze.com/creative-technology/8-creative-coders-who-create-amazing-algorithmic-sketches/',
        },

        {
            linkText: '',
            linkSrc: '',
        },


        {
            linkText: 'lissajous curves',
            linkSrc: 'https://mcellin.me.uk/artfulcomputing/index.php/sketchbooks/lissajous-curves?showall=1',
        },
        ],
        techLinks: [
            {
                linkText: 'my pendulum on codepen',
                linkSrc: 'https://codepen.io/Fleischut/pen/WNQYXVp',
            },
            {
                linkText: 'three.js pendulum on codepen',
                linkSrc: 'https://codepen.io/iondrimba/pen/zbOBRO?editors=1010',
            },

            {
                linkText:'wave pendulum in three.js',
                linkSrc:'https://github.com/franspaco/pendulums',
            },

            {
                linkText:"9.1: JavaScript setTimeout() Function - p5.js Tutorial",
                linkSrc:"https://www.youtube.com/watch?v=nGfTjA8qNDA"
            },

            {
                linkText:"9.2: JavaScript setInterval() Function - p5.js Tutorial",
                linkSrc:"https://www.youtube.com/watch?v=CqDqHiamRHA"
            },
            {
                linkText:"reich oscillators",
                linkSrc:"https://editor.p5js.org/socalledsound/sketches/SkjKp2Udm"
            },
            {
                linkText:"ck mellow squares",
                linkSrc:"https://editor.p5js.org/socalledsound/sketches/BkEc6TIO7"
            },
            {
                linkText:"ck crickets waveforms",
                linkSrc:"https://editor.p5js.org/socalledsound/sketches/L2DeHDtUX"
            },				
            {
                linkText:"intro to working with sound in p5.js",
                linkSrc:"https://www.youtube.com/watch?v=Pn1g1wjxl_0&list=PLRqwX-V7Uu6aFcVjlDAkkGIixw70s7jpW"
            },

            {
                linkText:"simple sine functions",
                linkSrc:"https://editor.p5js.org/socalledsound/sketches/B1YOirE_Q"
            },
            {
                linkText:"simple fm synth",
                linkSrc:"https://editor.p5js.org/socalledsound/sketches/By2AbIN_m"
            },
            {
                linkText:"fm synth with waveform",
                linkSrc:"https://editor.p5js.org/socalledsound/sketches/Sy2oOU4uX"
            },
            {
                linkText: 'harmonograph - 1',
                linkSrc: 'https://editor.p5js.org/socalledsound/sketches/hzLiRa-5_',
            },
            {
                linkText: 'harmonograph - 2',
                linkSrc: 'https://editor.p5js.org/socalledsound/sketches/3yGgeN_3I',
            },
            {
                linkText: 'github repo for our pendulum sketch',
                linkSrc: 'https://github.com/socalledsound/side-effects-unit2-day11-pendulum',
            },
            {
                linkText: 'p5.js sound reference',
                linkSrc: 'https://p5js.org/reference/#/libraries/p5.sound',
            },
        ], 
        videos: [
            {
                id : 'UB1O30fR-EE',
                title:'html basics from traversy media',
                description: 'an hour long dive into the basics of html'
            },
            {
                id : 'yfoY53QXEnI',
                title: 'css basics from traversy media',
                description: 'a video about the basics of css from one of my favorite teachers of web coding'
            },
            {
    
            },
            {
    
            },
            {
    
            },
            {
    
            },
        ], 
}

export default UNIT2_DAY2