import React from 'react';
import { connect } from 'react-redux';
// import { setCurrentUser } from '../../redux/user/user.actions';

import { firestore, convertTopicsSnapshotToMap, } from '../../firebase/firebase.utils';
import { Grid } from 'semantic-ui-react';
import { getTopics } from '../../redux/topics/topics.selectors';
// import DiscussionColorPanel from '../../components/discussion-color-panel/discussion-color-panel.component';
import DiscussionSidePanel from '../../components/discussion-side-panel/discussion-side-panel.component';

import DiscussionMessagesPanel from '../../components/discussion-messages-panel/discussion-messages-panel.component';

import DiscussionThreadPanel from '../../components/discussion-thread-panel/discussion-thread-panel.component';
import { updateTopics } from '../../redux/topics/topics.actions';
// import { updateMessages } from '../../redux/messages/messages.actions';
import LoadingSpinner from '../../components/loading-spinner/loading-spinner.component';


// import MetaPanel from './MetaPanel/MetaPanel';
// import { createStructuredSelector } from 'reselect';
// import { selectCurrentUser } from '../../redux/user/user.selectors';
// import Discussion from '../../components/discussion-overview/discussion-overview.component';
// import DiscussionOverview from '../../components/discussion-overview/discussion-overview.component';


class DiscussionPage extends React.Component {

  state = {
    loading : true,
    topicsCollectionRef: firestore.collection('topics'),
    // messagesCollectionRef: firestore.collection('messages'),
    currentUser: null,
    currentTopic: null,
}


  componentDidMount(){
    const { currentUser, currentTopic, topics } = this.props;
    const { topicsCollectionRef, messagesCollectionRef } = this.state;
    this.setState({ currentUser, currentTopic, loading: false });

    // this.unsubscribeFromTopics = topicsCollectionRef.onSnapshot(async snapshot => { 
    //   const topics = convertTopicsSnapshotToMap(snapshot); 
    //   updateTopics(topics);
    //   this.setState({loading: false});
    // })


    window.scrollTo(0, 0);

  }

  componentWillUnmount(){
  // this.unsubscribeFromTopics();
  // this.unsubscribeFromMessages();
  }

  updateSelectedMessage = (selectedMessage) => {
    console.log('updating discussion page state')
    this.setState({ selectedMessage});
  }


  render()  {
    // const { currentUser } = this.state;
    
    const { currentTopic, currentUser, notifications, topics } = this.props;
    const { selectedMessage}  = this.state;
    console.log(currentTopic);
    if(currentUser != null ) {

    
    return this.state.loading ? <LoadingSpinner style={{ marginTop: '-1.0em', marginLeft: '-1.0em', width: '800px'}}/> : 
    
  
    (
      <div className="discussion-container">


      <Grid columns="equal" style={{ marginTop: '-1.0em'}}>
          
              {/* <DiscussionColorPanel /> */}
              <DiscussionSidePanel 
              key={ currentUser && currentUser.id}
              currentUser={currentUser} 
              notifications={notifications}
              />
         
          <Grid.Column  style={{ marginLeft: `180px`, marginTop: 20, position: 'fixed', top: '55px', width: '440px', overflowX: 'hidden' }}>

            {currentTopic != null ? 
              <DiscussionMessagesPanel 
                key={ currentTopic && currentTopic.id}
                currentTopic={currentTopic}
                currentUser={currentUser}
                updateSelectedMessage={this.updateSelectedMessage}
                // currentTopicId={typeof currentTopic === 'object' ? currentTopic.id : null}
              />
              :
              <div>loading...</div>
            }
          </Grid.Column>
          
          <Grid.Column style={{ marginLeft: '620px', marginTop: 0, position: 'fixed', top: '55px', width: '480px', maxWidth: '480px'}}>
              <DiscussionThreadPanel                 
                currentTopic={currentTopic}
                currentUser={currentUser}
                selectedMessage={selectedMessage}
                // currentTopicId={typeof currentTopic === 'object' ? currentTopic.id : null}
              />
          </Grid.Column>
      </Grid>
      </div>
  )
  } else {
    return <div>loading</div>
  }

  } 

}


// const mapDispatchToProps = dispatch => ({
//   setCurrentUser: user => dispatch(setCurrentUser(user)),
//   // updateWorks: works => dispatch(updateWorks(works)),
//   // updateCritMessages: critMessages => dispatch(updateCritMessages(critMessages)),
//   // setCurrentWorkItem: works => dispatch(setCurrentWorkItem)
// })

// const mapDispatchToProps = dispatch => ({
 
// })

// const mapDispatchToProps = dispatch => ({
// updateTopics: topics => dispatch(updateTopics(topics)),
// // updateMessages: messages => dispatch(updateMessages(messages)),
// })

const mapStateToProps = (state) => ({
     //currentUser: state.users.currentUser,
    currentTopic: state.topics.currentTopic,
    topics: getTopics(state),
    selectedMessage: state.discussion.selectedMessage,
    
  })


export default connect(mapStateToProps)(DiscussionPage)