import * as actionTypes from './messages.types';

// const INITIAL_STATE = ARCHIVED_WORKS;
const INITIAL_STATE = {
    messages: null, 
    notifications: null,
    selectedMessage: null,
};

const messagesReducer = (state = INITIAL_STATE, action) => {
   
//    console.log('updating crit messages');
//    console.log(action.payload);
    switch(action.type){
       

        // return {
        //     ...state,
        //     data: {
        //       ...state.data,
        //       list: [].concat(action.payload.list, state.data.list.filter(val=>val.id==action.payload.list.id))
        //     }
        //   }



       case actionTypes.UPDATE_MESSAGES:
           console.log(action.payload);
           const previousMessages = state.messages != null ? [...state.messages] : [];
           const newMessages = Array.from(new Set(previousMessages.concat(action.payload)));
           return {
               ...state,
               messages : newMessages
           }
       
        case actionTypes.SET_SELECTED_MESSAGE:
            return {
                ...state,
                selectedMessage: action.payload
            }   

        case actionTypes.UPDATE_SELECTED_MESSAGE_RESPONSES :

            // const newResponses = state.selectedMessage.responses.slice().push(action.payload);
            console.log(action.payload, "UPDATING RESPONSES!!!!!!!!")
            return {
                ...state,
                selectedMessage: {
                    ...state.selectedMessage,
                    responses: action.payload
                }
                

            }    

        default:
            return state
    }
}
export default messagesReducer
