import * as actionTypes from './topics.types';


const INITIAL_TOPICS_STATE = {
    currentTopic: null,
    topics: null,
}


const topicsReducer = (state = INITIAL_TOPICS_STATE, action) => {
    switch(action.type) {
        case actionTypes.SET_CURRENT_TOPIC : 
            return {
                ...state,
                currentTopic: action.payload.currentTopic
            }

        case actionTypes.UPDATE_TOPICS : 
            return {
                ...state,
                topics: action.payload
            }    

        default: 
            return state    

    }
}

export default topicsReducer