import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter} from 'react-router-dom';
// import { createStructuredSelector } from 'reselect';
import { selectCurrentDay } from '../../redux/days/days.selectors';
// import VideoOverview from '../../components/video-overview/video-overview.component';
import DayOverview from '../../components/day-overview/day-overview.component';
import DayDirectory from '../../components/day-directory/day-directory.component';

import './daypage.styles.scss';

const DayPage = ({ day, history, match, params}) => {

    const [currentDay, setCurrentDay] = useState(day);
   
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const dayRouteHandler = (day) => {     
        history.push(day.route);
        setCurrentDay(day);
    }
   
    const assignmentRouteHandler = (route) => history.push(route)
    
    return(
        <div>
            <DayDirectory id={currentDay.id} dayRouteHandler={dayRouteHandler}/>
            <DayOverview {...currentDay} assignmentRouteHandler={assignmentRouteHandler}/>
            {/* <VideoOverview videos={currentDay.videos}/> */}
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    // console.log(ownProps.match.params.unitId);
    console.log('ownprops', ownProps.match.params);
    return ({
        // item: selectCurrentDay(ownProps.match.params.dayId)(state)
         day: selectCurrentDay(ownProps.match.params.dayId)(state)
    }) 
} 

export default withRouter(connect(mapStateToProps)(DayPage))
