import { createSelector } from 'reselect';
import { selectProjects } from '../syllabus/syllabus.selectors';

const PROJECTS_ID_MAP = {
  project1: 1,
  project2: 2,
  project3: 3,
  project4: 4,
}

export const selectCurrentProject = projUrlParam =>
  createSelector(
    [selectProjects],
    projects => projects.find(project => project.id === PROJECTS_ID_MAP[projUrlParam])
  );

  // export const selectItems = createSelector(
  //   [selectCurrentUnit],
  //   unit => unit.items
  // );
  

  
