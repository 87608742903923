import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
// import  moment  from 'moment';
import { firestore, convertUsersSnapshotToMap } from '../../firebase/firebase.utils';
// import { addUserMeeting, markMeetingComplete } from '../../redux/user/user.actions';
import { updateUsers } from '../../redux/user/user.actions';
import './meetings-page.styles.scss';
import MeetingsStudentRow from '../../components/meetings-student-row/meetings-student-row.component';

class MeetingsPage extends React.Component {

    state = {
        usersRef: firestore.collection('users'),
        loading: true,

    }


    componentDidMount(){
        const { students, currentUser, updateUsers } = this.props;
        const { usersRef } = this.state;

        this.unsubscribeFromUsers = usersRef.onSnapshot(snapshot => {
            const users = convertUsersSnapshotToMap(snapshot);
            console.log('updating users received');
            updateUsers(users);
            this.setState({loading: false, students});
        })

        window.scrollTo(0, 0);
    }

    componentWillUnmount(){
        this.unsubscribeFromUsers();
    }

    markMeetingComplete = student => {
        const { usersRef } = this.state;
        this.setState({ loading: true});
        const update = {hasMeeting: false};
        usersRef.doc(student.id).update(update).then(snap => {
            console.log(snap);
            console.log('updated user/hasMeeting');
            this.setState({ loading: false});
        });
    }

    assignTime = students => {
        const { currentUser } = this.props;
        const { usersRef } = this.state;
        // const scheduledMeetings = students ? students.filter(student => student.hasMeeting) : [];
        this.setState({ loading: true});
        const update = {hasMeeting: true};
        usersRef.doc(currentUser.id).update(update).then(snap => {
            console.log(snap);
            console.log('updated user/hasMeeting');
            this.setState({ loading: false});
        });
        }




   

        // updateUserMeetingInFirestore = update => {
        //     const { currentUser } = this.props;
        //     const { usersRef } = this.state;

        // }

        renderStudents = students => {
            const { currentUser } = this.props;
            const {time, expired } = this.state;
            console.log(students);
            return students.map(student => {
                return(
                        <div className="meetings">

                            <MeetingsStudentRow currentUser={currentUser} student={student} markMeetingComplete={this.markMeetingComplete} />    
                        </div>
                )
                
            })
        }

        renderNone = () => (<div className="no-meetings">there are currently no students waiting to have a meeting</div>)

    render(){
        const { students, currentUser } = this.props;
        const { loading } = this.state;
        console.log(students, loading);
        let studentList = [];
        if(students){
            studentList = students.filter(student => student.hasMeeting);
        }

       

        return (

            <div className="meetings-container">
                <p>Hi everyone!  Today I want to meet with as many of you as possible in a slightly sort of one on one way.  That is, I want to give you some time, but we'll do it in the public zoom room so everyone else can follow along, just in case they may be having the same problem.  You can sign up for a slot by clicking the button below.  Each slot will last five minutes, pretty much exactly -- there's a timer on the page to help me stay on track.  That should help you guage how long you have before your meeting, which will begin whether you're ready or not, when your turn comes up!  Please have your code ready to go, and your questions at hand, because five minutes really isn't a lot of time.  Feel free to use the time however you think best, whether with technical or artist questions, or just to chat.  And, if I don't finish with your question before your five minutes is up, feel free to sign up again!</p>
            {currentUser.hasMeeting != true &&
            
                <Button 
                    onClick={()=>this.assignTime(students)}
                > 
                request help
                </Button>
            }
                {studentList.length > 0 ? this.renderStudents(studentList) : this.renderNone()}
            </div>
            
        )
    }

}

const mapDispatchToProps = dispatch => ({
    // addMeeting: meeting => dispatch(addMeeting(meeting)),
    // markMeetingComplete: meeting => dispatch(markMeetingComplete(meeting)),
    // addUserMeeting: meeting => dispatch(addUserMeeting(meeting)),
    // markMeetingComplete: meeting => dispatch(markMeetingComplete(meeting)),
    updateUsers: users => dispatch(updateUsers(users)),
})


const mapStateToProps =  (state) => ({
    students: state.users.users,
    // currentUser: state.users.currentUser,
    // meetingsToday: getTodaysMeetings(state), 
})

export default connect(mapStateToProps, mapDispatchToProps)(MeetingsPage)