import React from 'react';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { Comment } from 'semantic-ui-react';
import { firestore, convertWorksSnapshotToMap  } from '../../firebase/firebase.utils';
// import { Button } from 'semantic-ui-react';
import { selectCurrentStudent } from '../../redux/user/user.selectors';
import { getRemainingDays, getCompletedDays } from '../../redux/days/days.selectors';
import { selectUserWorks } from '../../redux/works/works.selectors';
import { updateWorks } from '../../redux/works/works.actions';
import { updateCritMessages } from '../../redux/critMessages/critMessages.actions';
import UserGallery from '../../components/user-gallery/user-gallery.component';
import UserCritMessages from '../../components/user-crit-messages/user-crit-messages.component';
import UserMessages from '../../components/user-messages/user-messages.component';
import UserAssignmentsList from '../../components/user-assignments-list/user-assignments-list.component';
import UserMissingAssignmentsList from '../../components/user-missing-assignments-list/user-missing-assignments-list.component';
import LoadingSpinner from '../../components/loading-spinner/loading-spinner.component';
import './student-page.styles.scss';



class StudentPage extends React.Component {

    state = {
        worksRef: firestore.collection('works'),
        critMessagesRef: firestore.collection('critMessages'),
        scaler: 3,
        windowHeight: null,
        windowWidth: null,
        messageModal: false,
        loading: true,
    }


    componentDidMount(){

        const { currentStudent, updateWorks, remainingDays, completedDays } = this.props;
        const { worksRef } = this.state;
        const { numCritMessages, numDiscussionMessages, completedAssignments } = currentStudent;
        const daysCompleted = 30 - (remainingDays.length-2);
        const completedAssignmentsTemp = completedAssignments === undefined ? [] : completedAssignments;
        const assignmentsTilNow = completedDays.map(day=>day.assignment.title).slice(1);
        const randomContributions = completedAssignmentsTemp.filter(item => item === 'random').length;
        // const nonRandomCompletedAssignments = completedAssignmentsTemp.filter(item => item != 'random');
        const missingAssignments = assignmentsTilNow.filter(item => completedAssignmentsTemp.indexOf(item) === -1);

        
        // critMessagesRef.where(`user.id`, '==',  currentStudent.id).get().then(snapshot => {
        //     const critMessages = convertCritMessagesSnapshotToMap(snapshot);
        //     updateCritMessages(critMessages);
        //     this.setState({ loading: false})
        // }) 

        worksRef.where(`user.id`, '==',  currentStudent.id).get().then(snapshot => {
            const works = convertWorksSnapshotToMap(snapshot);
             updateWorks(works);
             this.setState({ loading: false})
        }) 


        this.setState({
            currentStudent, 
            // userNotifications,
            // nextDay,
            numCritMessages,
            numDiscussionMessages,
            completedAssignments,
            // remainingDays,
            daysCompleted,
            // userWorks,
           randomContributions,
           missingAssignments,
            });



        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);  
        window.scrollTo(0, 0);
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.updateWindowDimensions);
        // this.unsubscribeFromUser();
    }


    updateWindowDimensions = () => {
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight});
    }

    toggleMessageModal(){
        this.setState({ messageModal: true })
    }

    render(){
        const { currentStudent, userWorks, history } = this.props;
        const { windowWidth, windowHeight, scaler, numCritMessages, 
                numDiscussionMessages, completedAssignments, daysCompleted, 
                missingAssignments, randomContributions, loading  } = this.state;
                console.log(completedAssignments);
                console.log(missingAssignments);
                console.log(userWorks);

        if(currentStudent != null ) {

            if(loading){
                return (
                    <LoadingSpinner />
                )
            }
            else {

                return (
                    <div className="student-page">
                        <div className="student-content">
        
                            <div className="greeting-block">
                                
                                
                                <div className="greeting-text">
                                    <span className="user-image"><Comment.Avatar src={currentStudent.photoURL}/></span>
                                    <span className='name'>{currentStudent.displayName ? currentStudent.displayName : currentStudent.email }</span>
                                    {/* <Button basic color='pink' onClick={()=> this.toggleMessageModal()}>send message to {currentStudent.displayName}</Button> */}
                                </div>
                            </div>
        
        
                            <div className="user-gallery">
                                            <UserGallery userWorks={userWorks}  windowWidth={windowWidth} windowHeight={windowHeight} scaler={scaler} history={history}/>
                            </div>
        
                            {/* {currentUser.admin && 
                           <div className="progress-block">
        
                                <div className="user-critMessages">
        
                                        {numCritMessages ? <UserCritMessages numCritMessages={numCritMessages} daysCompleted={daysCompleted}/> : <div>hasn't posted any comments on people's work yet</div>}
        
                                </div>
        
                                <div className="user-messages">
                                    {numDiscussionMessages ? <UserMessages numDiscussionMessages={numDiscussionMessages} daysCompleted={daysCompleted}/> : <div> hasn't posted anything to the discussion forums yet</div>}
                                     <UserMessages userMessages={this.state.userMessages}/> 
                                </div>
        
                                <div className="">
                                    {randomContributions ?
                                    <div> you've contributed {randomContributions} submissions to the random gallery.</div>
                                        :
                                    <div> you haven't made any random contributions</div>
        
                                }
                                    
                                </div>    
        
                                <div className="user-assignments-list">
                                    
                                    {completedAssignments ? 
                                    <UserAssignmentsList completedAssignments={completedAssignments} />
                                    :
                                    <div>hasn't submitted any assignments yet</div>
                                    }  
                                </div> 
        
                                <div className="user-missing-assignments-list">
                                    
                                    {missingAssignments ? 
                                    <UserMissingAssignmentsList missingAssignments={missingAssignments} />
                                    :
                                    <div>you don't have any missing assignments</div>
                                    }  
                                </div> 
                            </div>        
                            } */}
        
                        </div>  
                    </div>              
                )
            }

        
    }
    }
} 


const mapDispatchToProps = dispatch => ({
    updateWorks: works => dispatch(updateWorks(works)),
    updateCritMessages: critMessages => dispatch(updateCritMessages(critMessages)),
})

const mapStateToProps = (state, ownProps) => {

    return ({
         currentStudent: selectCurrentStudent(ownProps.match.params.userId)(state), 
         userWorks: selectUserWorks(ownProps.match.params.userId)(state),   
         remainingDays: getRemainingDays(Date.now())(state),   
         completedDays: getCompletedDays(Date.now())(state),
    }) 
} 



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentPage));