import React from 'react';

// import FormInput from '../form-input/form-input.component';
// import CustomButton from '../custom-button/custom-button.component';

import { auth } from '../../firebase/firebase.utils';

// import { Link } from 'react-router-dom';
import { Grid, Form, Segment, Button, Header, Message } from 'semantic-ui-react';


// import './sign-in.styles.scss';

class SignIn extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            email: '',
            password: '',
            errors: [],
            loading: false,
        }
       

    }


    componentDidMount(){
        
    }

    handleSubmit = async (e) =>{
        e.preventDefault();
        const { email, password } = this.state;
        try {
            await auth.signInWithEmailAndPassword(email, password);
            this.setState({ email: '', password: '' })
        } catch(error) {
            console.log(error);
            this.setState({
                errors: this.state.errors.concat(error),
                loading: false,
            })
        }

        
    }


    displayErrors = errors => errors.map((err, index) => (<p key={index}>{err.message}</p>)); 

    isFormValid = ({ email, password }) => email && password;


    handleChange = (e)=>{
       const { value, name } = e.target;

       this.setState({ [name]: value })

    }

    render(){

        const { email, password, loading, errors } = this.state;

        return (
            <Grid textAlign="center" verticalAlign="middle" className="signin">
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Header>
                        <h5>log in below</h5>

                    </Header>
                    <Form onSubmit={this.handleSubmit}>
                        <Segment stacked>
                            <Form.Input fluid name="email" icon="mail" iconPosition="left" type="email" placeholder="email address" onChange={this.handleChange} value={email} required/>
                            <Form.Input fluid name="password" icon="lock" iconPosition="left" type="password" placeholder="password" onChange={this.handleChange} value={password} required/>
                            <Button disabled={loading} className={loading ? 'loading' : ''} color="violet" fluid size="large">submit</Button>
                        </Segment>
                    </Form>

                    {errors.length > 0 && (
                        <Message error>
                            <h3>Error</h3>
                            {this.displayErrors(errors)}
                        </Message>
                    )}


                    {/* <Message>
                        don't have an account? <Link to="/register" style={{ color: 'red'}}>register</Link>
                    </Message> */}
                    <Message>
                        forgot your password? send me an <a href="mailto:socalledsound@gmail.com" style={{color: 'red'}}>email</a> and remind me to set up password recovery
                    </Message>


                </Grid.Column>
            </Grid>
        )
    }
}

export default SignIn



// return (
//     <div className="sign-in">
//         <h2>I already have an account</h2>
//         <span>sign in with your email and password</span>

//         <form onSubmit={this.handleSubmit}>
//             <FormInput name="email" value={this.state.email} type="email" label='email' handleChange={this.handleChange} required/>
//             <FormInput name="password" value={this.state.password} type="password" label='password' handleChange={this.handleChange} required/>
//             <div className="buttons">
//             <CustomButton type="submit" value="submit">sign in</CustomButton>
//             {/* <CustomButton type="button" onClick={signInWithGoogle} isGoogleSignIn>sign in with gmail</CustomButton> */}
//             </div>

//         </form>    
//     </div>
// )