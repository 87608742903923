import { ASSIGNMENT1 } from "../../assignments/assignments.data"

const UNIT1_DAY1 = {
    id: 1, 
    unit: 'unit1',
    route: '/units/unit1/day1', 
    assignmentRoute: '/units/unit1/day1/assignment', 
    month: 'August',
    day: 27,
    name: 'hello! world',
    illustrationURL: 'https://res.cloudinary.com/chris-kubick/image/upload/v1596822596/side-effects/handswtf_p5ubjn.jpg',
    description: "Hello! And welcome to the world of (mostly intentional) side effects!  We'll spend this first class getting acquainted.  I'll introduce the key concepts, and tools we'll be using in the course, outline the schedule for the course, introduce the projects we'll be working on, and show you some art that I like.",
    assignment: ASSIGNMENT1,
    videos: [
        {
            linkText: 'navigating the side efects course site',
            linkSrc: 'https://youtu.be/daLV3K0NYXg',
        },
        {
            linkText: 'resizing an image using gimp',
            linkSrc: 'https://youtu.be/8ukaQw6oPM8',
        },
        {
            linkText: 'making a screenshot in the chrome browser',
            linkSrc: 'https://youtu.be/lkBm5UPu5wg',
        },
        {
            linkText: 'submitting an assignment',
            linkSrc: 'https://developer.mozilla.org/en-US/',
        },
    ],  
    inspirationLinks: [
        // {
        //     linkText:"Pauline Oliveros, 'Some Sound Observations'",
        //     linkSrc:"https://drive.google.com/open?id=1OAnH99RQi2uKMdw5ri6_RB7UzNi98p6y"
        // },
        {
            linkText:"a piece I made called 'hum -human'",
            linkSrc:"https://www.youtube.com/watch?v=eYW16vkYZg4"
        },
                        {
            linkText:"socalled sound website",
            linkSrc:"http://socalledsound.com/"
        },
        {
            linkText:"language removal services",
            linkSrc:"http://www.languageremoval.com"
        },
        // {
        //     linkText:"doublearchive",
        //     linkSrc:"http://www.doublearchive.com"
        // },
        // {
        //     linkText:"Christian Marclay mini documentary",
        //     linkSrc:"https://www.youtube.com/watch?v=boWmP0os2e8"
        // },	
        // {
        //     linkText:"zimoun",
        //     linkSrc:"http://www.zimoun.net/works.html"
        // },
        // {
        //     linkText:"Jim Campbell's LED images swim through space",
        //     linkSrc:"https://www.youtube.com/watch?v=4x48aCX1uK8"
        // },	
        // {
        //     linkText:"Camille Utterback Text Rain",
        //     linkSrc:"https://www.youtube.com/watch?v=f_u3sSffS78"
        // },														
        // {
        //     linkText:"jenny holzer, nowness",
        //     linkSrc:"https://www.nowness.com/story/jenny-holzer-light-stream"
        // },
        // {
        //     linkText:"carsten holler",
        //     linkSrc:"https://www.dezeen.com/2015/06/09/carsten-holler-decision-london-south-bank-hayward-gallery-playground-slides/"
        // },	

        
        // {
        //     linkText:"yoko ono bicycle piece",
        //     linkSrc:"https://socalledsound.github.io/gap2018/images/non-sense/yokoBicycle.jpg"
        // },					{
        //     linkText:"Christina Kubisch Electrical Walks",
        //     linkSrc:"https://vimeo.com/54846163"
        // },					{
        //     linkText:"Max Neuhaus, Times Square",
        //     linkSrc:"http://www.max-neuhaus.info/timessquare.htm"
        // },	
        // {
        //     linkText:"Thomas Hirschorn Gramsci Monument",
        //     linkSrc:"https://www.youtube.com/watch?v=O5yyegM2u88"
        // },
        // {
        //     linkText:"olafur eliasson 'little sun' project",
        //     linkSrc:"http://littlesun.com/"
        // },	

        {
            linkText: "tim hawkinson's uber organ",
            linkSrc: 'https://www.youtube.com/watch?v=Pd9nU1J7QMo',
        },


        {
            linkText: 'this website will self-destruct',
            linkSrc: 'https://www.thiswebsitewillselfdestruct.com/',
        },
        {
            linkText:"Chatbot that overturned 160,000 parking fines now helping refugees claim asylum",
            linkSrc:"https://www.theguardian.com/technology/2017/mar/06/chatbot-donotpay-refugees-claim-asylum-legal-aid"
        },					{
            linkText:"the fall of humanity",
            linkSrc:"https://twitter.com/ckolderup/lists/the-fall-of-humanity/members"
        },


    

        {
            linkText:"San Andreas Streaming Deer Cam",
            linkSrc:"http://www.sanandreasanimalcams.com/"
        },
        {
            linkText:"hands.wtf",
            linkSrc:"http://hands.wtf/"
        },							
        {
            linkText:"typedrummer",
            linkSrc:"http://typedrummer.com/"
        },								
        {
            linkText:"pixelSynth",
            linkSrc:"https://ojack.github.io/PIXELSYNTH/"
        },				{
            linkText:"pink trombone",
            linkSrc:"https://experiments.withgoogle.com/pink-trombone"
        },	
        {
            linkText:"",
            linkSrc:""
        },	
        {
            linkText:"",
            linkSrc:""
        },	
        {
            linkText:"corey arcangel -- super mario clouds",
            linkSrc:"http://www.coryarcangel.com/things-i-made/2002-001-super-mario-clouds"
        },	
        {
            linkText:"jodi",
            linkSrc:"http://wwwwwwwww.jodi.org/"
        },	

        
        {
            linkText:"Yoshi Sodeoka mutations",
            linkSrc:"https://vimeo.com/115066659"
        },

        {
            linkText:"ITEOTA",
            linkSrc:"http://iteota.com/experience/start-your-journey"
        },					
        {
            linkText:"food that looks like iggy azalea",
            linkSrc:"http://foodthatlookslikeiggyazalea.tumblr.com/"
        },				


        {
            linkText:"Aaron Koblin, 'Artfully Visualizing Our Humanity'",
            linkSrc:"https://www.youtube.com/watch?v=4v4XxlfVk3o"
        },	
        {
            linkText:"AI drum machine - kyle macdonald et al",
            linkSrc:"https://experiments.withgoogle.com/ai/drum-machine/view/"
        },
        {
            linkText: 'citizen dj - remixing the library of congress',
            linkSrc: 'https://citizen-dj.labs.loc.gov/',
        },    
    ],
    techLinks: [


        {
            linkText: 'navigating the side efects course site',
            linkSrc: 'https://youtu.be/daLV3K0NYXg',
        },
        {
            linkText: 'resizing an image using gimp',
            linkSrc: 'https://youtu.be/8ukaQw6oPM8',
        },
        {
            linkText: 'making a screenshot in the chrome browser',
            linkSrc: 'https://youtu.be/lkBm5UPu5wg',
        },
        {
            linkText: 'submitting an assignment',
            linkSrc: "https://youtu.be/HUs52zqxADM",
        },
    ]
        
     
}

export default UNIT1_DAY1