import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestore } from '../../firebase/firebase.utils';
import { Comment, Button } from 'semantic-ui-react';
import moment from 'moment';
import Linkify from 'react-linkify';
import './message.styles.scss';
// import moment from 'moment';

// const isImage = message => {
//     console.log(message);
//     return message.hasOwnProperty("image") && !message.hasOwnProperty("content");
//   };



const timeFromNow = (timestamp) => moment(timestamp).fromNow();


class Message extends React.Component{
    // console.log(message.user.id);
    // console.log(currentUser.id, 'in message component');
    // console.log(isOwnMessage(message, currentUser));


    state = {
        message: this.props.message, 
        currentUser: this.props.currentUser,
        messagesRef: firestore.collection('critMessages'), 
      
    }


    deleteMessage = (message) => {
        console.log('deleting', message.id);
        this.state.messagesRef.doc(message.id).delete().then(()=>{
            console.log('deleted');
            //this.state.updateParent();
        });
    }

    editMessage = (message) => {

    }



    isOwnMessage = (message, currentUser) => {
        if(currentUser){
            return message.user.id === currentUser.id
        }
        return false
    }


    render(){

        const { message, currentUser } = this.state;
        const { history } = this.props;

    return (
        <Linkify>
        <Comment  className="comment-block">
            {currentUser ?
            <Comment.Avatar src={message.user.avatar} onClick={ ()=> history.push(`/students/${message.user.id}`)}/>
            :
            <Comment.Avatar src={message.user.avatar} />
                
        }
            
           
            <Comment.Content >
                <Comment.Author as="a" onClick={ ()=> history.push(`/students/${message.user.id}`)}>{message.user.name}</Comment.Author>
                
                <Comment.Metadata>{timeFromNow(message.timestamp)}</Comment.Metadata>

                      <div className="comment-text">{message.content} </div> 
                      {this.isOwnMessage(message, currentUser) && 
                        (
                            <React.Fragment>
                            {/* <Button icon='pencil' basic onClick={this.editMessage} ></Button> */}
                            <Button icon='trash' basic color='red' size='mini' onClick={ () => this.deleteMessage(message)}></Button>
                            </React.Fragment>
                               
                          
                        )
                }
               
            </Comment.Content>


        </Comment>
        </Linkify>

    )
    }
}

const mapStateToProps = ({users:{currentUser}}) => ({
    currentUser
})


export default withRouter(connect(mapStateToProps)(Message))