import UNIT1 from '../units/unit1.data';
import UNIT2 from '../units/unit2.data';
import UNIT3 from '../units/unit3.data';
import UNIT4 from '../units/unit4.data';
import PROJ1 from '../projects/proj1.data';
import PROJ2 from '../projects/proj2.data';
import PROJ3 from '../projects/proj3.data';
import PROJ4 from '../projects/proj4.data';

// import { UserActionTypes } from '../user/user.types';

const INITIAL_STATE = {
    units: [UNIT1, UNIT2, UNIT3, UNIT4],
    projects: [PROJ1, PROJ2, PROJ3, PROJ4],
};

const syllabusReducer  = (state=INITIAL_STATE, action) => {
    switch (action.type) {
        default: 
            return state;
    }
}

export default syllabusReducer