import React from 'react';
import { connect } from 'react-redux';
import { firestore, convertMessagesSnapshotToMap } from '../../firebase/firebase.utils';
import { updateMessages, setSelectedMessage } from '../../redux/messages/messages.actions';
// import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../redux/user/user.selectors';

// import { selectCurrentTopicMessages } from '../../redux/critMessages/critMessages.selectors';
// import { addItemToFirestore } from '../../firebase/firebase.utils';
import { Segment, Comment } from 'semantic-ui-react';
import DiscussionMessage from '../discussion-message/discussion-message.component';
import DiscussionMessagesHeader from '../discussion-messages-header/discussion-messages-header.component';
// import DiscussionMessageForm from '../discussion-message-form/discussion-message-form.component';
import DiscussionMessageFormTextArea from '../discussion-message-form-text-area/discussion-message-form-text-area.component';
import LoadingSpinner from '../loading-spinner/loading-spinner.component';
import './discussion-messages-panel.styles.scss';
import { selectCurrentTopicMessages, selectSelectedMessage } from '../../redux/messages/messages.selectors';

import './discussion-messages-panel.styles.scss';

class DiscussionMessagesPanel extends React.Component {

    state = {
        messagesCollectionRef: firestore.collection('messages'),
        currentUser: this.props.currentUser,
        // messagesRef: 'messages',
        messages: [],
        messagesLoading : true,
        topic: this.props.currentTopic,
        user: null,
        numUniqueUsers: '',
        searchTerm: '',
        searchLoading: false,
        searchResults: [],
        selectedMessage: this.props.selectedMessage,
        updateSelectedMessage: this.props.updateSelectedMessage
    }

    componentDidMount(){
      
        const { messagesCollectionRef } = this.state;
        const { updateMessages, currentTopic } = this.props;
          console.log(currentTopic.id);
        this.unsubscribeFromMessages = messagesCollectionRef.where('topic', '==', `${currentTopic.id}`).onSnapshot(async snapshot => {
          const messages = convertMessagesSnapshotToMap(snapshot); 
          console.log(messages);
          updateMessages(messages);
          this.setState({messages: messages, messagesLoading: false}, console.log(this.state.messages));
          
        })



        // this.setState({ 
        //     messages: this.props.messages,  
        //     user: this.props.currentUser, 
        //     // topic: this.props.item.id
        // })
    }


    componentWillUnmount(){
        //this.unsubscribeFromTopics();
        this.unsubscribeFromMessages();
        }


    updateParent = (topic, selectedMessage)=>{
        // console.log(topic);
        if(topic){
            this.setState({
                topic,
                messagesLoading: false, 
                selectedMessage
                // messages: this.props.messages
            }, )
        }
        

    }
    

    renderMessages = (messages) => {
        console.log(messages);
        if(messages) {

           const filteredMessages = messages.filter(message => !message.response).sort((a,b) => b.timestamp - a.timestamp);
            //const filteredMessages = messages;
            console.log(filteredMessages);
       return (
          
         filteredMessages.map(message => {
            //  console.log(message.responses);
            return (
            <div className="message-container" key={message.id} onClick={()=>this.selectMessage(message)}>
                    {/* {message.responses ? message.responses.length : ''} */}
                    <DiscussionMessage 
                        // fixed="top"
                        className="message"
                        key={message.id}
                        message={message}                
                    />
            </div>
        )}
    )
        )
    }
    }
        
    editMessage(){

    }


    selectMessage = message => {
        const { topic } = this.state;
        console.log(message);
        this.props.setSelectedMessage(message);
        console.log()
        const selectedMessage = message;
        this.setState({ selectedMessage }, this.updateParent(topic, selectedMessage));
        this.props.updateSelectedMessage(selectedMessage);
        
    }


    // displayTopicName = topic => topic ? `#${topic.name}` : ''; 

 

    render(){
        // console.log(this.props);
        const { messagesRef, messages } = this.state;
        const { currentUser, currentTopic, selectedMessage } = this.props;
        console.log(selectedMessage);
        // console.log(currentTopicId, 'current Topic ID in d-panel');
        return this.state.messagesLoading ? (
        <div className="loading-container">
            <LoadingSpinner /> 
        </div>
        
        
        )
        : 
        (
            <React.Fragment>
                
                <Segment 
                className="discussion-messages-container"
                // style={{ background: '#FFF', fontSize: '1.2rem', marginTop: '-1.2em', marginLeft: '-3.6em', height: '676px'}}
                >
                    {/* <DiscussionMessagesHeader topic={currentTopic} className="discussion-message-header"/> */}
                    <DiscussionMessageFormTextArea 
                            className="discussion-message-form"
                            messagesRef={messagesRef} 
                            topic={currentTopic}
                            user={currentUser}  
                            updateParent={this.updateParent}
                            />
                    <Comment.Group className="discussion-messages">
                       
                        {this.renderMessages(messages)}
                    </Comment.Group>     
  
                </Segment>

            </React.Fragment>
        )
    }

}

// const mapStateToProps = createStructuredSelector({
//     currentUser: selectCurrentUser,
//     critMessages : selectCurrentCritMessages('IUvcAsRUAWqz9n0fcpVF'),
//     // currentTopic: selectCurrentTopic
//     // works: getWorks
//   })

const mapDispatchToProps = dispatch => ({
    updateMessages: messages => dispatch(updateMessages(messages)),
    setSelectedMessage: message => dispatch(setSelectedMessage(message)),
    })


  const mapStateToProps = (state, ownProps) => {
    return ({
        currentUser: selectCurrentUser(state),  
        messages: selectCurrentTopicMessages(ownProps.currentTopic.id)(state),
        selectedMessage: selectSelectedMessage(state),
    }) 
} 




export default connect(mapStateToProps, mapDispatchToProps)(DiscussionMessagesPanel)