import React from 'react';
import './student.styles.scss';

const Student = ({student, history}) => {
    console.log(student.hidden);
    if(student.hidden){
        return (
            <React.Fragment />
        )
    } else {
        return     (
            <div className="student-container" key={student.id} onClick={()=> history.push(`/students/${student.id}`)}>
                <img className="student-image" src={student.photoURL} alt={student.displayName}/>
                <div className="name">{student.displayName}</div>     
            </div>
        )
    }

}




    export default Student