import React, {useState} from 'react';
import './day-directory-item.styles.scss';
const DayDirectoryItem = ({day, color, selected, dayRouteHandler}) => {
    const [isHovering, toggleHover] = useState(false)

    const handleMouseHover = ()=>{
        const toggleValue = isHovering ? false : true;
        toggleHover(toggleValue);    
    }

    const getMonthFromString = (mon)=>{
        
        return new Date(Date.parse(mon + " 1, 20122")).getMonth()+1
    }
    
    if(isHovering){
        return (
            <div 
            key={day.id} 
            className={`item ${color} ${selected}`}
            onClick={()=>dayRouteHandler(day)}
            onMouseEnter={handleMouseHover}
            onMouseLeave={handleMouseHover}
            >
           <p className="dd-text">{getMonthFromString(day.month)}/{day.day}</p>
        </div>
        )
       }
        
       if(day.crit) {
        return (
            <div 
                key={day.id} 
                className={`item ${color} ${selected}`}
                onClick={()=>dayRouteHandler(day)}
                onMouseEnter={handleMouseHover}
                onMouseLeave={handleMouseHover}
                >
               <span role="img" aria-label="big red circle">&#128308;</span> 
            </div>
        )
       }

       return (
        <div 
            key={day.id} 
            className={`item ${color} ${selected}`}
            onClick={()=>dayRouteHandler(day)}
            onMouseEnter={handleMouseHover}
            onMouseLeave={handleMouseHover}
            >
           
        </div>
    )
}
export default DayDirectoryItem






