import React from 'react';
import {withRouter } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';

import './final-project-item.styles.scss';


class FinalProjectItem extends React.Component{
    
        // state = {
        //     item: this.props.item,
        //     history: this.props.history,
            
        // }
    
    render(){
        const { item, history } = this.props;
        console.log(item);
        const itemWidth = 200;
        if(itemWidth){
            return (

                <React.Fragment>
        
                         <div className="final-work-container" style={{width: `${itemWidth}px`, height: `${itemWidth}px`}} key={item.id} onClick={()=> history.push(`/finalshow/${item.id}`)}>
                        <img className="final-work-image" src={item.imageURL} alt={item.title}/>
                        <div className="final-work-title">{item.title}</div>  
                        <div className="final-work-artist"><h2>{item.user.name}</h2></div>     
                        {/* <Icon name="heart outline" size="large" color="red" onClick={()=>console.log('hearted')} />  */}
                    </div>
                    
        
                </React.Fragment>
            )
        }
        else {
            return (
                <div>something is wrong here</div>
            )
        }


    }

}

export default withRouter(FinalProjectItem)