import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import md5 from 'md5';

const config = {
    apiKey: "AIzaSyC0ZB_1p1rTNO76UrGLfmsv1gpi9V5U6HY",
    authDomain: "side-effects-7f7e8.firebaseapp.com",
    databaseURL: "https://side-effects-7f7e8.firebaseio.com",
    projectId: "side-effects-7f7e8",
    storageBucket: "side-effects-7f7e8.appspot.com",
    messagingSenderId: "70253180518",
    appId: "1:70253180518:web:b22eae6cab7ac5b7c25fa7",
    measurementId: "G-2RHZBTQNT9"
};


export const createUserProfileDocument = async (userAuth, additionalData) => {
    if(!userAuth) return;
    const userRef = firestore.doc(`users/${userAuth.uid}`);
    const snapShot = await userRef.get();
    // console.log(userAuth, 'in firebase');
    if(!snapShot.exists){
        const { displayName, email} = userAuth;
        const createdAt = new Date();
        try {
            await userRef.set({
                displayName,
                email,
                createdAt,
                photoURL: `http://gravatar.com/avatar/${md5(email)}?d=identicon`,
                ...additionalData
            })
        } catch(error) {
            console.log(error.message);
        }
    }
    return userRef
}


firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();

const providerGoog = new firebase.auth.GoogleAuthProvider();
const providerGit = new firebase.auth.GithubAuthProvider();
providerGoog.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => auth.signInWithPopup(providerGoog);
export const signInWithGithub = () => auth.signInWithPopup(providerGit);



// export const firestoreListener = async (collectionKey) => {
//     const collectionRef = firestore.collection(collectionKey);
//     collectionRef.onSnapshot(()=> {

//     })
// }


export const addCollectionAndDocuments = async (collectionKey, objectsToAdd) => {
    const collectionRef = firestore.collection(collectionKey);
   

    const batch = firestore.batch();
    objectsToAdd.forEach(object => {
        const newDocRef = collectionRef.doc();
        console.log(newDocRef);
        batch.set(newDocRef, object);
    })

    return await batch.commit();
}

export const addItemToFirestore = async(collectionKey, objectToAdd) => {
    const collectionRef = firestore.collection(collectionKey);
    console.log(objectToAdd, 'in firestore');
    console.log("collectionref:", collectionRef);
    
    return await collectionRef.add(objectToAdd)
}

export const addImageToStorage = async(collectionKey, imageToAdd, setImageAsUrl) => {
    console.log(imageToAdd);
    const uploadTask = storage.ref(`${collectionKey}/${imageToAdd.name}`).put(imageToAdd);
  //  initiates the firebase side uploading 
      uploadTask.on('state_changed', 
      (snapShot) => {
      //takes a snap shot of the process as it is happening
      console.log(snapShot)
      }, (err) => {
      //catches the errors
      console.log(err)
      }, () => {
      // gets the functions from storage refences the image storage in firebase by the children
      // gets the download url then sets the image from firebase as the value for the imgUrl key:
      storage.ref(`${collectionKey}`).child(imageToAdd.name).getDownloadURL()
      .then(fireBaseUrl => {
          setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}));
          
      })
      })
    console.log(uploadTask);
    return uploadTask
}



export const convertWorksSnapshotToMap = (works) => {
    
    const transformedArray = works.docs.map(doc => {
        const { assignment, description, featured, random, imageURL, linkURL, code, title, user, timestamp  } = doc.data();

        return {
            
            id: doc.id,
            assignment,
            description, 
            featured,
            random,
            imageURL,
            linkURL,
            code,
            title,
            user,
            timestamp,
        }
    })
    // console.log("transformed array", transformedArray);
    return transformedArray

    // return transformedCollection.reduce((acc, collection) => {
    //     acc[collection.title.toLowerCase()] = collection;
    //     return acc
    // }, {})
}


export const convertCritMessagesSnapshotToMap = (critMessages) => {
    
    const transformedArray = critMessages.docs.map(doc => {
        const { content, timestamp, topic, user  } = doc.data();

        return {
            
            id: doc.id,
            content,
            timestamp, 
            topic,
            user,
        }
    })
    // console.log("transformed array", transformedArray);
    return transformedArray


}


export const convertTopicsSnapshotToMap = (topics) => {
    
    const transformedArray = topics.docs.map(doc => {
        const { timestamp, name, details, createdBy  } = doc.data();

        return {
            
            id: doc.id,
            timestamp, 
            name,
            details,
            createdBy,
        }
    })
    // console.log("transformed array", transformedArray);
    return transformedArray


}



export const convertMessagesSnapshotToMap = (messages) => {
    
    const transformedArray = messages.docs.map(doc => {
        const { timestamp, content, createdBy, topic, response, responses } = doc.data();

        return {
            
            id: doc.id,
            timestamp, 
            content,
            createdBy,
            topic,
            response,
            responses,
        }
    })
    // console.log("transformed array", transformedArray);
    return transformedArray


}



export const convertUserSnapshotToMap = (user) => {
    
    const transformedArray = user.docs.map(doc => {
        const { timestamp, content, createdBy, topic } = doc.data();

        return {
            id: doc.id,
            timestamp, 
            content,
            createdBy,
            topic,
        }
    })
    // console.log("transformed array", transformedArray);
    return transformedArray


}


export const convertUsersSnapshotToMap = (users) => {
    
    const transformedArray = users.docs.map(doc => {
        const { hidden, admin, completedAssignments, createdAt, displayName, email, numCritMessages, numDiscussionMessages, photoURL, userNotifications, hasMeeting } = doc.data();

        return {
            id: doc.id,
            hidden,
            admin,
            completedAssignments, 
            createdAt, 
            displayName, 
            email, 
            numCritMessages, 
            numDiscussionMessages, 
            photoURL, 
            userNotifications,
            hasMeeting
        }
    })
    // console.log("transformed array", transformedArray);
    return transformedArray


}




export default firebase;