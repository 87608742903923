import React, { Fragment } from 'react';
import { withRouter, Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { getWorks } from '../../redux/works/works.selectors'; 
// import { getCurrentDay, getCompletedDays } from '../../redux/days/days.selectors';
import { Icon } from 'semantic-ui-react';
// import { createStructuredSelector } from 'reselect';
import { firestore,  convertWorksSnapshotToMap } from '../../firebase/firebase.utils';
import { updateWorks } from '../../redux/works/works.actions';
// import { updateCritMessages } from '../../redux/critMessages/critMessages.actions';
// import GalleryRow from '../../components/gallery-row/gallery-row.component';
// import { ASSIGNMENT_TITLES } from '../../redux/assignments/assignments.data';
// import './gallerypage.styles.scss';

import FinalShow from '../../components/final-show/final-show.component';
//import GalleryItem from '../../components/gallery-item/gallery-item.component';
import LoadingSpinner from '../../components/loading-spinner/loading-spinner.component';
import './final-show-page.styles.scss';



// const galleryCategories = ASSIGNMENT_TITLES;
document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
document.body.scrollTop = 0; // For Safari
class FinalShowPage extends React.Component {
    state = {
        finalRef: firestore.collection('works').where('assignment', '==', 'final-final'),
        loading: true,
        finalWorks: [],
    }
    
    componentDidMount(){
        const { finalRef } = this.state;

        this.unsubscribeFromFinal = finalRef.onSnapshot(async snap => {
            const finalWorks = await convertWorksSnapshotToMap(snap);
            console.log(finalWorks.length, 'in component did mount');
            console.log(updateWorks(finalWorks));
            this.props.updateWorks(finalWorks);
            this.setState(prevState => ({finalWorks: prevState.finalWorks.concat(finalWorks)}))
        })


        //  const { students } = this.props;
        this.setState({ loading: false });
         // const { usersRef } = this.state;

         window.scrollTo(0, 0);
         window.addEventListener('onbeforeunload', this.onbeforeunload);
    }

    componentWillUnmount(){
       this.unsubscribeFromFinal();
      }


    onbeforeunload = function () {
        window.history.scrollRestoration = "manual";
        window.scrollTo(0, 0);
      }



    render(){
        const { history, works } = this.props;
        const { loading, finalWorks } = this.state;
        console.log(works, finalWorks)
        // const finalWorks = works.filter(work => work.assignment === 'final-final');

        if(Array.isArray(finalWorks)){

            const randomizedWorks = finalWorks.sort((a,b) => (0.5 - Math.random()) );
            console.log(randomizedWorks);
            
        if(!loading){

                return (
                    <React.Fragment>

{/* <h2>side effects: end of semester show for art 173!</h2>
                        <p>Welcome, everyone!  Below are the final projects for UCB art 173.  It's been a fun semester!  Please have a look around.  If you click through at the images below, you'll find a link to a live project.   You'll also find a full description of each project, as well as a link to the source code.    We also have a <a>guest book </a>for the show, please leave a few kind words for the artists!  If you're interested to see more work by anyone, you can find all of the students at the people link, up above in the header, and if you want to see more of the amazing things that people have done, all of the works from the semester are in the course gallery!  </p>    */}







                    <div className="final-works-container">
                    <div className='final-show-intro'>
                        <h1>side effects</h1>
                        <h2>art 173 final show</h2>
                        <p>Welcome, everyone!  Below are the final projects for UCB art 173.  These people have worked hard and done amazing things!  If you click through at the images below, you'll find a link to a live project, as well as project description and a link to the source code.  </p>

                    </div>
                    <div className="final-show-intro">
                                {/* <p>All of these are interactive, some are games, there's even a multiplayer online game, so grab a friend!</p> */}
                                <p>There's also a <Link to='/book' className="show-link">guest book</Link> for the show, please take a moment to show some love for the artists!</p>
                                <p>If you want more, head over to the <Link to="/gallery" className="show-link"> gallery </Link> or look for a specific person in the <Link to="/students" className="show-link">roster</Link>.</p>
                                
                                <p>Thanks, everyone, for one of the most memorable semesters ever!</p>
                        </div>

                        {/* <div className="guest-book">
                            guest book
                        </div> */}
                        <FinalShow  works={randomizedWorks} history={history} />
                    </div>
                    </React.Fragment>

                )
            }

        } else {
            return (
                <LoadingSpinner />
            )
        }

        return (
            <LoadingSpinner />
        )
    
    }


}

const mapDispatchToProps = dispatch => ({
    updateWorks: works => dispatch(updateWorks(works)), 
})

const mapStateToProps = (state) => ({
    works: getWorks(state),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FinalShowPage));

