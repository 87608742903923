import React from 'react';
import { Menu } from 'semantic-ui-react';

import DiscussionUserPanel from '../discussion-user-panel/discussion-user-panel.component';
import DiscussionTopics from '../discussion-topics/discussion-topics.component';

class DiscussionSidePanel extends React.Component {
    render(){

        const { currentUser } = this.props;
        // console.log(currentUser);
        return (
            <Menu
                size="small"
                inverted
                fixed="top"
                vertical
                style={{ background: '#4c3c4c', fontSize: '1.0rem', marginTop: '6.6em', height: '760px' }}
                // style={{ background: '#2b910c', color: '#000', fontSize: '1.0rem', marginTop: '6.6em', height: '760px' }}
            >

            <DiscussionUserPanel currentUser={currentUser}/>
            <DiscussionTopics currentUser={currentUser} />
            </Menu>
        )
    }
}


export default DiscussionSidePanel