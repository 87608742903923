import { ASSIGNMENT8 } from "../../assignments/assignments.data"

const UNIT1_DAY8 = {
    id: 8,
    unit: 'unit1',
    route: '/units/unit1/day8',
    assignmentRoute: '/units/unit1/day8/assignment', 
    month: 'September',
    day: 22, 
    name: 'looking back, looking ahead',
    description: "Today we're going to review some of the things we've learned, but mostly I want to work with you one and one or in groups, on your first project.  Let's make those monsters shine!",
    illustrationURL: '',
    assignment: ASSIGNMENT8,
    videos: [
        {
            id : 'UB1O30fR-EE',
            title:'html basics from traversy media',
            description: 'an hour long dive into the basics of html'
        },
        {
            id : 'yfoY53QXEnI',
            title: 'css basics from traversy media',
            description: 'a video about the basics of css from one of my favorite teachers of web coding'
        },
        {

        },
        {

        },
        {

        },
        {

        },
    ],     
    inspirationLinks: [
        // {
        //     title: 'generative backgrounds with the css paint api',
        //     link : 'https://medium.com/better-programming/how-to-create-dynamic-backgrounds-with-the-css-paint-api-ebd733254014',
        // },
        // {
        //     title:'generative art with css',
        //     link : 'https://generative-art-with-css.commons.host/',
        // },
        // {
        //     title:'mona lisa with pure css',
        //     link: 'https://codepen.io/jaysalvat/pen/HaqBf',
        // },
        // {
        //     title:'magritte son of man with css',
        //     link: 'https://codepen.io/cgorton/pen/EWgoYq',
        // },
        {
            linkText: 'apple has finally met its fortnite match',
            linkSrc: 'https://www.theverge.com/2020/8/14/21368651/apple-fortnite-ios-app-store-ban-lawsuit-epic-games-payments',
        },
        {
            linkText: 'Free icons for use in your projects',
            linkSrc: 'https://gomakethings.com/free-icons-for-use-in-your-projects/?mc_cid=6eef277a4d&mc_eid=[UNIQID]',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        ],
        techLinks: [
            {
                linkText:'UI design shapes & objects basics: Shadows and Blurs',
                linkSrc : 'https://uxdesign.cc/ui-design-shapes-objects-basics-shadows-and-blurs-e42bf2d32864',
            },
            {
                linkText: 'king vs pawn game in ui design',
                linkSrc  : 'https://learnui.design/blog/king-vs-pawn-game-ui-design.html',
            },
            {
                linkText:'buttons in ui design',
                linkSrc : 'https://uxplanet.org/buttons-in-ui-design-the-evolution-of-style-and-best-practices-56536dc5386e',
            },
            {
                linkText:"Scott Snibbe, 'To Make You completely present In The Moment'",
                linkSrc:"https://drive.google.com/open?id=0B2LpnVlBWDK2ekpZa1k5UjlpX3M"
            },
            {
                linkText: 'installing git',
                linkSrc: 'https://git-scm.com/book/en/v2/Getting-Started-Installing-Git',
            },

            {
                linkText: '',
                linkSrc: '',
            },
            {
                linkText: 'first intro to the command line',
                linkSrc: 'https://youtu.be/0lDy6B2qtuY',
            },
            {
                linkText: 'cloning a github repo on the command line',
                linkSrc: 'https://youtu.be/0fVr7JPhLG0',
            },
            {
                linkText: 'first push to github using git',
                linkSrc: 'https://youtu.be/69OtLHz5VeY',
            },
            {
                linkText: 'a first look at branch in git',
                linkSrc: 'https://youtu.be/8tBRkQV8Vdk',
            },
            {
                linkText: "Gloria's project with a few changes",
                linkSrc: 'https://github.com/socalledsound/remixGloriasRoom/tree/changes1',
            },

        ], 
}

export default UNIT1_DAY8