import React from 'react';
// import Message from '../message/message.component';
import { Progress } from 'semantic-ui-react';

const UserMessages = ({ numDiscussionMessages, user, daysCompleted }) => {
   
    //  const daysElapsed = 10;
    console.log(daysCompleted);
    // const numMessages = userMessages.length;
    const messagePercent = ((numDiscussionMessages*100)/daysCompleted);

    // console.log(userMessages);
    
    if(numDiscussionMessages  > 0) {

            return (
                <React.Fragment>
                     you've left {numDiscussionMessages} messages on the discussion boards
                    {/* {renderUserCritMessages()} */}
                <Progress percent={messagePercent} color="pink">
                  
                </Progress>
               
                </React.Fragment>
            )
        }
        return (
            <div>you haven't participated in the discussion yet, head over to the discussion boards and get involved!</div>
        )


}


export default UserMessages


// const renderMessages = (messages) => (
        
//     messages.length > 0 && messages.map(message => (
//         <div className="message-container">
//         <Message className="message"
//             key={message.timestamp}
//             message={message}
//             currentUser={user}
//         />
//         </div>

//     ))

// )



    // return (
    //     <React.Fragment>
    //         {renderMessages(userMessages)}
    //     </React.Fragment>
    // )