import UNIT1 from '../units/unit1.data';
import UNIT2 from '../units/unit2.data';
import UNIT3 from '../units/unit3.data';
import UNIT4 from '../units/unit4.data';

const units = [UNIT1, UNIT2, UNIT3, UNIT4];

const items = units.reduce( (acc,unit) => {
  const { items } = unit;
  return acc.concat(items)
}, [])
// console.log(items);



const INITIAL_STATE = {
  items
};

// const INITIAL_STATE = {
//   units: [UNIT1, UNIT2, UNIT3, UNIT4]
// };


const daysReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      default:
        return state;
    }
  };
  
  export default daysReducer;