import { createSelector} from 'reselect';


const selectTopics = state => state.topics;

export const getTopics = createSelector(
    [selectTopics],
    topics => topics.topics
)

// export const selectCurrentCritMessages = currentTopic => 
// createSelector(
//   [getCritMessages],
//   critMessages => critMessages.filter(item => item.topic === currentTopic)
// );

// export const selectUserCritMessages = currentUserId => createSelector(
//   [getCritMessages],
//   critMessages => critMessages.filter(item => item.user.id === currentUserId)
// );