import logo1 from '../../assets/purecss-francine.png';
import logo2 from '../../assets/pitagoru1.png';
import logo3 from '../../assets/hvbrd2.jpg';
import logo4 from '../../assets/unit1-temp.png';

const INITIAL_STATE = {
    units: [
        {
            title: 'unit 1',
            subtitle: 'monsters',
            id: 1,
            imageUrl: logo1,
            linkUrl: 'unit1'
        },
        {
            title: 'unit2',
            subtitle: 'goldbergian',
            id: 2,
            imageUrl: logo2,
            linkUrl: 'unit2'
        },
        {
            title: 'unit3',
            subtitle: 'sockets',
            id: 3,
            imageUrl: logo3,
            linkUrl: 'unit3'
        },
        {
            title: 'unit4',
            subtitle: 'virtually',
            id: 4,
            imageUrl: logo4,
            linkUrl: 'unit4'
        }
    ]
}

const directoryReducer = (state=INITIAL_STATE, action) => {
    switch(action.type){
        default:
            return state
    }
} 

export default directoryReducer