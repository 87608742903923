import React from 'react';
import { firestore } from '../../firebase/firebase.utils';
import { Segment, Comment } from 'semantic-ui-react';
import LoadingSpinner from '../../components/loading-spinner/loading-spinner.component';
import BookMessage from '../../components/book-message/book-message.component';
import BookMessageForm from '../../components/book-message-form/book-message-form.component';
import './book-page.styles.scss';


class BookPage extends React.Component {

    state = {
        loading: true,
        bookRef: firestore.collection('book'),
        messages: [],
    }

    componentDidMount(){
        const { bookRef }  = this.state;
        this.unsubscribeFromBook = bookRef.onSnapshot( snapshot => {
            const update = snapshot.docChanges();
            update.forEach( message => {

                const newData = message.doc.data();
                const newMessage = {
                    id: newData.id,
                    timestamp: newData.timestamp,
                    name: newData.name,
                    message: newData.comment,
                }
                this.setState( prevState => ({
                    messages: prevState.messages.concat(newMessage)
                }))
            })
        })
        window.scrollTo(0, 0);
    }

    componentWillUnmount(){
        this.unsubscribeFromBook();
        window.onbeforeunload = function(){
           window.scrollTo(0, 0);
         }
    }



    renderMessages = (messages) => {
        console.log(messages);
        if(messages) {

           const filteredMessages = messages.filter(message => !message.response).sort((a,b) => b.timestamp - a.timestamp);
            //const filteredMessages = messages;
            console.log(filteredMessages);
       return (
          
         filteredMessages.map(message => {
            //  console.log(message.responses);
            return (
            <div className="message-container" key={message.id}>
                    {/* {message.responses ? message.responses.length : ''} */}
                    <BookMessage 
                        // fixed="top"
                        className="message"
                        key={message.id}
                        
                        message={message}                
                    />
            </div>
        )}
    )
        )
    }
    }

    updateParent(){

    }

    render(){
        const { messages, bookRef }  = this.state; 

                
        return this.state.messagesLoading ? (
        
        <div className="loading-container">
            <LoadingSpinner /> 
        </div>
        
        
        )
        : 
        (
            <React.Fragment>
                <div className="book-container">

               
                <div className='book-header'>
                    <h4>thank you for taking the time to sign the book!  Leave your name and comment:</h4>
                    <BookMessageForm 
                            bookRef={bookRef} 
                            updateParent={this.updateParent}
                            />
                </div>      
             
                        {this.renderMessages(messages)}

                </div>
            </React.Fragment>
        )

    }


}

export default BookPage