import { ASSIGNMENT15 } from '../../assignments/assignments.data';

const UNIT2_DAY6 = {
    id: 15,
    unit: 'unit2',
    name: 'enemy mine',
    route: '/units/unit2/day15',
    month: 'October',
    day: 15, 
    // description:"Today I want to talk about a thing called State.  Not 'the State', mind you, but 'State' in computer programs, which is something almost as pernicious and painful to deal with, quite honestly.  We're talking about the 'meta' (god?) level of our programs now, the way in which we organize relationships between the various parts of our program.",
    description: "The second part of the mid-term project assignment is to introduce a foil for your character.  Or, an enemy.  Or, a thing that the character wants but doesn't have.  Or, a thing that they have but don't want.  or, a thing that they don't want to have but have.  Wait a second...this is complicated!  Anyway, today we'll talk about foils, and spoils, and other things that our character might encounter as we make our way towards our midterm project crit day.",
    illustrationURL:'https://res.cloudinary.com/chris-kubick/image/upload/v1597762563/side-effects/uncanny-valley_fvwqg0.jpg',
    assignmentRoute: '/units/unit2/day15/assignment', 
    assignment: ASSIGNMENT15,  
    inspirationLinks: [
        
        {
            linkText: 'Handvaaska, by Ramsey Nasser and Jane Friedhoff',
            linkSrc: 'https://nas.sr/handv%C3%A4ska/',
        },
        {
            linkText: 'among us',
            linkSrc: 'http://www.innersloth.com/gameAmongUs.php',
        },
        {
            linkText: 'My Barbarian: Double Agency',
            linkSrc: 'https://vimeo.com/124203527',
        },
        {
            linkText: 'cow clicker',
            linkSrc: 'https://cowclicker.com/',
        },
        {
            linkText: 'angela washko, the game',
            linkSrc: 'https://angelawashko.com/home.html',
        },
        {
            linkText: 'star choir',
            linkSrc: 'https://vimeo.com/441187530',
        },
        {
            linkText: 'star choir: nomads dissolution',
            linkSrc: 'https://vimeo.com/mybarbarian',
        },
        {
            linkText: 'top 10 games from js13k 2020',
            linkSrc: 'https://github.blog/2020-10-11-top-ten-games-from-the-js13k-2020-competition/',
            
        },
        {
            linkText: 'i really like this one!',
            linkSrc: 'https://js13kgames.com/entries/choch',
        },
        {
            linkText: 'massively multipler tetris that i built with that meth meth method tutorial below',
            linkSrc: 'https://tetris-clone-6000.herokuapp.com/'
        }
        ],
        techLinks: [
           
            
            {
                linkText:'keycode is deprecated, use key instead',
                linkSrc: 'https://stackoverflow.com/questions/35394937/keyboardevent-keycode-deprecated-what-does-this-mean-in-practice',
            },
            {
                linkText:'remember window.addEventListener() ? -- you can use it for keyboard input if you prefer',
                linkSrc: 'https://editor.p5js.org/socalledsound/sketches/NBosLc6cQ',
            },

            {
                linkText:"make angry birds with p5.js and matter.js",
                linkSrc:"https://thecodingtrain.com/CodingChallenges/138-angry-birds.html"
            },

            {
                linkText: 'build space invaders with daniel shiffman',
                linkSrc: 'https://thecodingtrain.com/CodingChallenges/005-space-invaders-p5.html',
            },
            {
                linkText: 'frogger in p5.js',
                linkSrc: 'https://editor.p5js.org/codingtrain/sketches/crMMFw8vD',
            },
            {
                linkText: 'code super mario in vanilla js (25 parts!  maybe save it for later?)',
                linkSrc: 'https://www.youtube.com/watch?v=g-FpDQ8Eqw8',
            },

            {
                linkText: 'come to think of it, an hour to build tetris is probably more appropriate!',
                linkSrc: 'https://www.youtube.com/watch?v=H2aW5V46khA',
            },
            {
                linkText: 'collision detection book (processing)',
                linkSrc: 'http://www.jeffreythompson.org/collision-detection/table_of_contents.php',
            },
            {
                linkText: 'super stupid landscape game',
                linkSrc: 'https://editor.p5js.org/socalledsound/sketches/cjxO852kb',
            },
            {
                linkText: "soundlands, a badlands clone I've been working on",
                linkSrc: 'https://github.com/socalledsound/soundgame-badlandsclone',
            },

        ], 
        videos: [

            {
    
            },
            {
    
            },
            {
    
            },
            {
    
            },
        ],  
}

export default UNIT2_DAY6