import React from 'react';
import moment from 'moment';
import { Comment } from 'semantic-ui-react';
import './book-message.styles.scss'

const timeFromNow = (timestamp) => moment(timestamp).fromNow();


const BookMesage = ({message}) => {
    console.log(message);
    return (
        <React.Fragment>
            <div className="comment">
            <Comment >
                <Comment.Content>
                <Comment.Author>{message.name}</Comment.Author>
                <Comment.Metadata>{timeFromNow(message.timestamp)}</Comment.Metadata>
                
                <div className="comment-text" >{message.message}</div> 
                </Comment.Content>
            </Comment>

            </div>
        </React.Fragment>
    )
}

export default BookMesage