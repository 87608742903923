import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectDirectoryUnits } from '../../redux/directory/directory.selectors';
import DirectoryItem from '../directory-item/directory-item.component';
import './directory.styles.scss';

const Directory = ({ units }) => {
        
        return (
            <div className="directory-menu">
              {  
                units.map(unit => (
                    <DirectoryItem key={unit.id} unit={unit} />
                ))
              }
            </div>
        )
}

const mapStateToProps = createStructuredSelector({
    units: selectDirectoryUnits
})

export default connect(mapStateToProps)(Directory)