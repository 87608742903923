import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestore } from '../../firebase/firebase.utils';
import { Comment, Button } from 'semantic-ui-react';
import moment from 'moment';
import Linkify from 'react-linkify';
import './discussion-response.styles.scss';


const timeFromNow = (timestamp) => moment(timestamp).fromNow();


class DiscussionResponse extends React.Component {
     state = 
     { 
         message: this.props.message,
         currentUser: this.props.currentUser,
         messagesRef: firestore.collection('messages'), 
         selectedMessage: this.props.selectedMessage,
    } 


    editMessage = (message) => {

    }



    isOwnMessage = (message, currentUser) => {
        // return  ? 'message__self' : ''
        return message.createdBy.id === currentUser.id
    }

    render(){
        const { message, currentUser } = this.state;
        const { index, selectedMessage, deleteResponse, history, key } = this.props;
        console.log(message);
        console.log(index);
        
        if(selectedMessage){
            const messageSelected = message.id === selectedMessage.id;
            const response = selectedMessage.responses[index];

            return (
                <Linkify>
                <Comment>
                    <Comment.Avatar src={response.createdBy.avatar}/>
                    {/* className={isOwnMessage(message, user)} */}
                    <Comment.Content >
                        <Comment.Author as="a" onClick={ ()=> history.push(`/students/${response.createdBy.id}`)}>{response.createdBy.name}</Comment.Author>
                        
                        <Comment.Metadata>{timeFromNow(response.timestamp)}</Comment.Metadata>
    
                           
                        <div className="comment-text">{response.content}   </div> 
                           {this.isOwnMessage(response, currentUser) && 
                            (
                                <React.Fragment>
                                {/* <Button icon='pencil' basic onClick={this.editMessage} ></Button> */}
                                {/* <Button icon='trash' basic color='red' size='mini' onClick={ () => deleteResponse(selectedMessage, message)}></Button> */}
                                </React.Fragment>
                                   
                              
                            )
                    }
                    </Comment.Content>
                </Comment>
                </Linkify>
        
            )

        } else {
       

        return (
            <Linkify>
            <Comment>
                <Comment.Avatar src={message.createdBy.avatar}/>
                {/* className={isOwnMessage(message, user)} */}
                <Comment.Content >
                    <Comment.Author as="a">{message.createdBy.name}</Comment.Author>
                    
                    <Comment.Metadata>{timeFromNow(message.timestamp)}</Comment.Metadata>

                       
                    <div className="comment-text">{message.content}</div> 
                       {this.isOwnMessage(message, currentUser) && 
                        (
                            <React.Fragment>
                            {/* <Button icon='pencil' basic onClick={this.editMessage} ></Button> */}
                            <Button icon='trash' basic color='red' size='mini' onClick={ () => this.deleteMessage(message)}></Button>
                            </React.Fragment>
                               
                          
                        )
                }
                </Comment.Content>
            </Comment>
            </Linkify>
    
        )
    }
    }

}

const mapStateToProps = (state) => ({
    currentUser: state.users.currentUser,
    selectedMessage: state.discussion.selectedMessage,
})


export default withRouter(connect(mapStateToProps)(DiscussionResponse))