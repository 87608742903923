import React from 'react';
import Student from '../student/student.component';
import './students-roster.styles.scss';


class StudentsRoster extends React.Component {



    renderStudents(students, history){
       
        return students.map(student => {
            return <Student key={student.id}student={student} history={history} />
        })
    }
 
    


    render(){
        const {students, history} = this.props;
       
        return (
            <div>
                {this.renderStudents(students, history)}
            
            </div>
           
        )

        // this.renderStudents(students);
            
            
    }



}

export default StudentsRoster