import React from 'react';
import './syllabus-day.styles.scss';
import { withRouter } from 'react-router-dom';

const SyllabusDay = ({name, month, day, route, history}) => (
    <div 
        className='syllabus-day-item'
        onClick={()=>{history.push(route)}}
        >
        <span>{month} </span>
        <span>{day}  :  </span>
        <span>{name}</span>
    </div>
)

export default withRouter(SyllabusDay)