import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

body {
    font-family: 'Raleway', sans-serif;
  
    font-size: 20px;
    @media screen and (max-width: 800px){
        //padding: 10px;
    }

  }
  
  a {
    text-decoration: none;
    color: black;
   
  }
  
  .main-body {
    background-image: url('https://res.cloudinary.com/chris-kubick/image/upload/v1597648404/side-effects/thing-2_isfec3.png'); 
   
    height: 100%;
    padding: 10px 30px;
    padding-top: 100px;
    margin-top:-12px;

    @media screen and (max-width: 600px){
        margin-top: -8px;
        // padding: 10px;
        // width: 110%;
    }
    
    }
  

`