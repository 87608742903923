import { ASSIGNMENT22 } from '../../assignments/assignments.data';

const UNIT3_DAY5 = {
    id: 22,  
    unit: 'unit3',
    route: '/units/unit3/day22', 
    month: 'November',
    day: 10,
    name: 'augmented',
    description: "Augmented reality is, in my view, the most interesting of the versions of virtual reality that exist, because it doesn't try to deny reality, it enhances it.  We can experience an augmented reality with other people and we can do it without ignoring the world.  Today we'll look at one of the easiest ways to get started in AR, a tool called A-frame.",
    illustrationURL:'https://res.cloudinary.com/chris-kubick/image/upload/v1597869667/side-effects/Untitled_xko40r.png',
    assignmentRoute: '/units/unit3/day22/assignment', 
    assignment: ASSIGNMENT22,
  
    inspirationLinks: [

        {
            linkText: 'aframe showcase',
            linkSrc: 'https://aframe.io/showcase/',
        },
        {
            linkText: 'augmented reality in aframe',
            linkSrc: 'https://aframe.io/blog/arjs/',
        },
        {
            linkText: "a nice little 'house designer' app made using p5 and aframe",
            linkSrc: 'https://leoyouyang.github.io/house-designer/',
        },
        {
            linkText: 'ar in p5 with aframe (needs a hiro image)',
            linkSrc: 'https://editor.p5js.org/viztopia/sketches/S17RKjtJ4',
        },
        {
            linkText: 'light melody ar',
            linkSrc: 'https://a-frame-light-melody.glitch.me/',
        },
        {
            linkText: 'WebVR Technologies: React 360 vs A-frame and Three.js',
            linkSrc: 'https://litslink.com/blog/web-vr-technologies-react-360-vs-a-frame-and-threejs',
        },

        {
            linkText: 'entity component system',
            linkSrc: 'https://en.wikipedia.org/wiki/Entity_component_system',
        },
        {
            linkText: 'nifty zoom machine learning annotation tutorial with daniel shiffman',
            linkSrc: 'https://www.youtube.com/watch?v=9z9mbiOZqSs',
        },  
        {
            linkText: 'four seasons total landscaping in vr',
            linkSrc: 'https://twitter.com/thecoopertom/status/1325710953305026560',
        },
        ],
        techLinks: [
            {
                linkText: 'sound-circle-waves from last class',
                linkSrc: 'https://editor.p5js.org/socalledsound/sketches/Lv1ebN2QBg',
            },
            {
                linkText: 'perlin noise generative landscape in p5 from friday',
                linkSrc: 'https://editor.p5js.org/socalledsound/sketches/daLyawjn-',
            },
            {
                linkText: 'aframe with p5 using createElement',
                linkSrc: 'https://editor.p5js.org/socalledsound/sketches/hwpG3IOWN',
            },


            {
                linkText: 'basic aframe sketch in my living room',
                linkSrc: 'https://github.com/socalledsound/basic-aframe',
            },
            {
                linkText: 'my snowy living room',
                linkSrc: 'https://github.com/socalledsound/snow-room',
            },
            {
                linkText: '',
                linkSrc: '',
            },
            {
                linkText: '',
                linkSrc: '',
            },
            {
                linkText: '',
                linkSrc: '',
            },

        ], 
        videos: [
 
            {
    
            },
            {
    
            },
            {
    
            },
            {
    
            },
        ],   
}
   

export default UNIT3_DAY5