import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import Iframe from 'react-iframe';
import firebase, { firestore } from '../../firebase/firebase.utils';
import uuidv4 from 'uuid/v4';
import {  Button, Input, Icon, Comment } from 'semantic-ui-react';
import { updateCurrentItem, removeItem } from '../../redux/works/works.actions';
import FileModal from '../file-modal/file-modal.component';
import './work-panel.styles.scss';

class WorkPanel extends React.Component {
    
    state = {
        user: this.props.currentUser,
        createdBy: '',
        item: this.props.item,
        updateItem: this.props.updateItem,
        title: '',
        description: '',
        linkURL: '',
        code: '',
        imageURL: '',
        storageref: firebase.storage().ref(),
        worksRef: firestore.collection('works'),
        modal: false,
        uploadState: '',
        uploadTask: null,
        errors: [],
        titleActive: false,
        descriptionActive: false,
        linkActive: false,
        activeInputs: false,
        isFeatured: false,
       
    }


    componentDidMount(){
        const { item } = this.props;
        const { worksRef } = this.state;
        console.log(item);
        
        const { updateCurrentItem } = this.props;
        const itemRef = worksRef.doc(item.id);

        this.unsubscribeFromItem = itemRef.onSnapshot(async snapshot => {
            if(snapshot.data()){
                console.log(snapshot.id);
                const updatedItem = snapshot.data();
                updatedItem.id = snapshot.id;
                console.log(updatedItem);
              await updateCurrentItem(updatedItem);
              this.setState({loading: false, item: updatedItem});
              const { title, description, linkURL, imageURL, featured, user, code } = updatedItem;
              this.setState({ title, description, linkURL, code, imageURL, isFeatured: featured, createdBy: user.name}, console.log(this.state.isFeatured));
            }

        })
        
    }

    componentWillUnmount(){
        this.unsubscribeFromItem();
    }


    toggleFeature = () => {
        console.log(this.state.isFeatured);
        const featureState = !this.state.isFeatured;
        console.log(this.state.isFeatured);

        this.setState({ isFeatured: featureState}, this.updateFirestore);
        
        
    }


    toggleInputs = () => {
        const { activeInputs}  = this.state;
        this.setState({ activeInputs: !activeInputs });
        if(activeInputs){
            this.updateFirestore();
            this.setState({loaded: true})
        }
        
        
    }


    deleteItem = item => {
        const { history } = this.props;
        console.log('deleting item', item.id);
        this.state.worksRef.doc(item.id).delete().then(()=>{
            console.log('deleted');
            this.unsubscribeFromItem();
            history.push('/gallery');
            this.props.removeItem(item);
            
           
            //need to navigate to a page that says item has been successfully removed
        });
    }


    toggleTitle = () => {
        const activeTitle = this.state.titleActive;
        this.updateFirestore();
        this.setState({ titleActive: !activeTitle });
    }

    toggleDescription = () => {
        const activeDescription = this.state.descriptionActive;
        this.updateFirestore();
        this.setState({ descriptionActive: !activeDescription });
    }

    toggleLink = () => {
        const activeLink = this.state.linkActive;
        this.updateFirestore();
        this.setState({ linkActive: !activeLink });
    }

    updateFirestore (){
        console.log(this.state.isFeatured);
        const { worksRef, item } = this.state;
        console.log(worksRef, item);

        const update = {title: this.state.title, description: this.state.description, linkURL: this.state.linkURL, imageURL: this.state.imageURL, featured: this.state.isFeatured};
        
        worksRef.doc(item.id).update(update)
        .then(()=> {
            console.log('this should go');
            this.props.updateItem(update);
            this.setState({title: update.title, description: update.description, linkURL: update.linkURL});
            
         
        })
        .catch(function(error) {
            console.error("Error writing document: ", error);
        });
    }

    // handleKeyPress = (e) => {
    //     if (e.charCode === 13) {
    //       // Prevent the default action to stop scrolling when space is pressed
    //     //   e.preventDefault()
    //       console.log('Button received click with keyboard');
    //       this.sendMessage();
    //     }
    //   }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value});
        
    }

    openModal = () => this.setState({ modal: true});
    closeModal = () => this.setState({ modal: false}); 
    
    updloadFile = (file, metadata) => {
        // const pathToUpload = this.state.channel.id;
        // const ref = this.props.messagesRef;
        const filePath = `images/${uuidv4()}.jpg`;
        const update = {title: this.state.title, description: this.state.description, linkURL: this.state.linkURL, featured: this.state.isFeatured};
        this.setState({ 
            uploadState: 'uploading',
            uploadTask: this.state.storageref.child(filePath).put(file, metadata)
        },
             () => {
                 this.state.uploadTask.on('state_changed', snap => {
                     const percentUploaded = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
                     this.setState({ percentUploaded })
                 },
                 err => {
                     console.error(err);
                     this.setState({
                         errors: this.state.errors.concat(err),
                         uploadState: 'error',
                         uploadTask: null
                     })
                 },
                 () => {
                     this.state.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                        //  this.sendFileMessage(downloadURL, ref, pathToUpload);
                        const updateWithURL = {...update, imageURL: downloadURL};
                        this.props.updateItem(updateWithURL);
                        this.setState({ imageURL: downloadURL, imageAttached: true, form: true});
                        this.state.worksRef.doc(this.state.item.id).update({
                            imageURL: downloadURL
                        })
                        .then(()=> {
                            console.log("Document successfully written!");
                            this.setState({ uploadState: 'finished'})
                        })
                        .catch(function(error) {
                            console.error("Error writing document: ", error);
                        });



                     })
                     .catch( err => {
                         console.error(err);
                         this.setState({
                             errors: this.state.errors.concat(err),
                             uploadState: 'error',
                             uploadTask: null
                         })
                     })
                 }    
                 )
             }
        )
     }

    render(){
       const {item, currentUser, currentItem } = this.props;
       const { modal, errors, title, linkURL, code, description, isFeatured, createdBy } = this.state;
        console.log(item);

       if(currentUser && item){
           console.log(isFeatured);
        return (
            <div className='work-panel-container'>
                {/* <Iframe src="https://codepen.io/Fleischut/embed/VweNmNQ?height=265&theme-id=light&default-tab=css,result" width='100%' height='600px' /> */}
                {/* <Iframe src={item.linkURL} width='550px' height='550px' /> */}
                <img src={currentItem != null ? currentItem.imageURL : item.imageURL} alt="item.title" />
                {currentUser.id === item.user.id &&
                
                // <CustomButton isEditButton onClick={this.openModal}> change image </CustomButton>
               <React.Fragment>
                <Button
                onClick={this.openModal}
                color="red"
                content="change image"
                labelPosition="right"
                icon="paperclip"
                style={{ marginBottom: '1em'}}
            />
             </React.Fragment>
                }

            {currentUser.admin && 
            <React.Fragment>
            <Button
                onClick={this.toggleFeature}
                color={isFeatured ? "purple" : "black"}
                content="feature"
                labelPosition="right"
                icon="star"
                style={{ marginBottom: '1em'}}
            />
           </React.Fragment>
             }
            
           
               
                <Comment>
                {/* <Comment.Avatar src={createdBy.photoURL}/> */}
                    
                    <Comment.Content >
                        <Comment.Author as='h2'>{createdBy}</Comment.Author>
     
                    </Comment.Content>
                </Comment>
                
                {currentUser.id === item.user.id &&
                <span>
                <Button icon onClick={this.toggleInputs} color={this.state.activeInputs ? 'red' : 'orange'}>
                    <Icon name='edit' />
                    {this.state.activeInputs ?  'submit assignment changes' : 'edit assignment'}
                </Button>   
                {/* <Button icon onClick={()=>this.deleteItem(item)} color={'red'}>
                    <Icon name='trash' />
                    delete assignment
                </Button>    */}

                </span>    
                } 
                {
                    this.state.activeInputs ?                
                    <Input 
                        
                        fluid
                        name="title"
                        style={{ marginBottom: '0.7em' }}
                        placeholder=""
                        onChange={this.handleChange}
                        onKeyPress={this.handleKeyPress}
                        value={title}
                        className={
                            errors.some( err => err.message.includes('title')) ? 'error' : ''
                        }
                    />
               : 
               <span><h3 style={{marginBottom: '1em'}}> {currentItem != null ? currentItem.title : item.title} </h3></span>
                }
                
                
    
                {
                    this.state.activeInputs ?                
                    <Input 
                        
                        fluid
                        name="linkURL"
                        style={{ marginBottom: '0.7em' }}
                        placeholder=""
                        onChange={this.handleChange}
                        onKeyPress={this.handleKeyPress}
                        value={linkURL}
                        className={
                            errors.some( err => err.message.includes('linkURL')) ? 'error' : ''
                        }
                    />
               : 
               <div>
                   <p className="work-label">live project:  </p> 
               <a className="work-link" href={`${item.linkURL}`}>{currentItem != null ? currentItem.linkURL : item.linkURL}</a>
               </div>
                }

{
                    this.state.activeInputs ?                
                    <Input 
                        
                        fluid
                        name="code"
                        style={{ marginBottom: '0.7em' }}
                        placeholder="github repo"
                        onChange={this.handleChange}
                        onKeyPress={this.handleKeyPress}
                        value={code}
                        className={
                            errors.some( err => err.message.includes('code')) ? 'error' : ''
                        }
                    />
               : 
               <div>
                   <p className="work-label">project code:  </p>
               <a  className="work-link" href={`${item.code}`}>{currentItem != null ? currentItem.code : item.code}</a>
               </div>
                }
    
    
    
    
    
    {
                    this.state.activeInputs ?                
                    <Input 
                        
                        fluid
                        name="description"
                        style={{ marginBottom: '0.7em' }}
                        placeholder=""
                        onChange={this.handleChange}
                        onKeyPress={this.handleKeyPress}
                        value={description}
                        className={
                            errors.some( err => err.message.includes('description')) ? 'error' : ''
                        }
                    />
               : 
               <div>
                    <p className="work-label">description:  </p> 
               <span><p style={{marginBottom: '1em'}}> {currentItem != null ? currentItem.description : item.description} </p></span>
               </div>
               }
                
    
    
                
                <FileModal
                        modal={modal}  
                        uploadFile={this.updloadFile}
                        closeModal={this.closeModal}

                      
                    />  
            </div>
        )
       } else {
        return (
            <div className='work-panel-container'>

                <img src={currentItem != null ? currentItem.imageURL : item.imageURL} alt=""/>
                
        
                <Comment>
                    {/* <Comment.Avatar src={createdBy.photoURL}/> */}
                    
                    <Comment.Content >
                        <Comment.Author as='h2'>{createdBy}</Comment.Author>
     
                    </Comment.Content>
                </Comment>
                
               <span><h3 style={{marginBottom: '1em'}}> {item.title} </h3></span>
                
               <div>
                   <p className="work-label">live project:  </p> 
               <a className="work-link" href={`${item.linkURL}`}>{currentItem != null ? currentItem.linkURL : item.linkURL}</a>
               </div>
               <div>
                <p className="work-label">project code:  </p>
               <a  className="work-link" href={`${item.code}`}>{currentItem != null ? currentItem.code : item.code}</a>
               </div>
               <div>
               <p className="work-label">description:  </p> 
               <span><p style={{marginBottom: '1em'}}> {item.description} </p></span>
               </div>
                 
            </div>
        )
       }
    
    }

}



const mapDispatchToProps = dispatch => ({
    updateCurrentItem: item => dispatch(updateCurrentItem(item)),
    removeItem: item => dispatch(removeItem(item)),
})



export default withRouter(connect(null, mapDispatchToProps)(WorkPanel))


