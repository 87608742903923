import React from 'react';
import firebase, { firestore } from '../../firebase/firebase.utils';
import uuidv4 from 'uuid/v4';
import {Modal, Input, Button, Icon, Image} from 'semantic-ui-react';
import FileModal from '../../components/file-modal/file-modal.component';



class ProfileModal extends React.Component {
    state = {
        user: this.props.user,
        collectionRef: 'users',
        docRef: this.props.user.id,
        errors : [],
        displayName: this.props.user.displayName,
        fileModal: false,
        storageref: firebase.storage().ref(),
        uploadState: '',
        uploadTask: null,
    }


    updateProfile = () => {
        const { closeModal } = this.props;
        this.updateFirestore();
        closeModal();

    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value});
        
    }

    openFileModal = ()=> this.setState({ fileModal: true });
    closeFileModal = ()=> this.setState({ fileModal: false });


    updateFirestore (){
        // console.log(this.state.isFeatured);
        const update = {displayName: this.state.displayName}
        firestore.collection(this.state.collectionRef).doc(this.state.docRef).update(update)
        .then(()=> {
            console.log("Document successfully written!");
        })
        .catch(function(error) {
            console.error("Error writing document: ", error);
        });
    }


    updloadFile = (file, metadata) => {
        // const pathToUpload = this.state.channel.id;
        // const ref = this.props.messagesRef;
        const filePath = `images/${uuidv4()}.jpg`;
 
        this.setState({ 
            uploadState: 'uploading',
            uploadTask: this.state.storageref.child(filePath).put(file, metadata)
        },
             () => {
                 this.state.uploadTask.on('state_changed', snap => {
                     const percentUploaded = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
                     this.setState({ percentUploaded })
                 },
                 err => {
                     console.error(err);
                     this.setState({
                         errors: this.state.errors.concat(err),
                         uploadState: 'error',
                         uploadTask: null
                     })
                 },
                 () => {
                     this.state.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                        //  this.sendFileMessage(downloadURL, ref, pathToUpload);
                        this.setState({ imageURL: downloadURL, imageAttached: true, form: true});

                        firestore.collection(this.state.collectionRef).doc(this.state.user.id).update({
                            photoURL: downloadURL
                        })
                        .then(()=> {
                            console.log("Document successfully written!");
                            this.setState({ uploadState: 'finished'})
                        })
                        .catch(function(error) {
                            console.error("Error writing document: ", error);
                        });



                     })
                     .catch( err => {
                         console.error(err);
                         this.setState({
                             errors: this.state.errors.concat(err),
                             uploadState: 'error',
                             uploadTask: null
                         })
                     })
                 }    
                 )
             }
        )
     }

    render(){
        const { profileModal, closeModal } = this.props;
        const { user, errors, displayName, fileModal } = this.state;
        return (
            <Modal size="small" open={profileModal} onClose={closeModal}>

                <Modal.Content>
                <Image src={user.photoURL} size="tiny" alt="user profile image" style={{ marginBottom: "1em"}}/>
                <Button
                    onClick={this.openFileModal}
                    color="red"
                    content="change image"
                    labelPosition="right"
                    icon="paperclip"
                    style={{ marginBottom: '1em'}}
        />
                
                <div>display name</div>
                <Input 
                    
                    fluid
                    name="displayName"
                    style={{ marginBottom: '0.7em' }}
                    placeholder={user.displayName}
                    onChange={this.handleChange}
                    // onKeyPress={this.handleKeyPress}
                    value={displayName}
                    className={
                        errors.some( err => err.message.includes('displayName')) ? 'error' : ''
                    }
                    
                />
                
                
                </Modal.Content>
                <Modal.Actions>
                    <Button 
                    onClick={this.updateProfile}
                        color="green"
                        inverted
                        >
                            <Icon name="checkmark" /> select
                    </Button>
                    <Button 
                        onClick={closeModal}
                        color="red"
                        inverted
                        >
                            <Icon name="remove" /> cancel
                    </Button>
                </Modal.Actions>
            <FileModal
            modal={fileModal}  
            uploadFile={this.updloadFile}
            closeModal={this.closeFileModal}
            />  
            </Modal>


        )
    }
}

export default ProfileModal