import { ASSIGNMENT7 } from "../../assignments/assignments.data"

const UNIT1_DAY7 = {
    id: 7,
    unit: 'unit1',
    route: '/units/unit1/day7',
    assignmentRoute: '/units/unit1/day7/assignment', 
    month: 'September',
    day: 17, 
    name: 'listening',
    description: "Today we are going to talk about listening, which is, personally, one of my favorite things to do.  To the right, you can see a score by a composer named Max Neuhaus for a series of concerts he gave under the Brooklyn Bridge, where the concert was just, listening to the bridge.  He, Pauline Oliveros (whose 'deep listening' completely changed my life) and John Cage are among composers who taught us that music is all around us, if we will just listen.  Today, we'll do a little listening, together, and think about what it means to collect sounds -- can sounds be 'Art'?",
    illustrationURL: 'https://res.cloudinary.com/chris-kubick/image/upload/v1597030532/side-effects/neuhaus1_jggnxk.jpg',
    assignment: ASSIGNMENT7,
    videos: [
        {
            id : 'UB1O30fR-EE',
            title:'html basics from traversy media',
            description: 'an hour long dive into the basics of html'
        },
        {
            id : 'yfoY53QXEnI',
            title: 'css basics from traversy media',
            description: 'a video about the basics of css from one of my favorite teachers of web coding'
        },
        {

        },
        {

        },
        {

        },
        {

        },
    ],    
    inspirationLinks: [

        {
            linkText: "Pauline Oliveros, 'Some Sound Observations'",
            linkSrc: 'https://drive.google.com/file/d/1OAnH99RQi2uKMdw5ri6_RB7UzNi98p6y/view',
        },
        {
            linkText:"Max Neuhaus, Times Square",
            linkSrc:"http://www.max-neuhaus.info/timessquare.htm"
        },
        {
            linkText: 'John Cage 4:33, on a bus',
            linkSrc: 'https://www.youtube.com/watch?v=WYQhXN1UFbU',
        },
        {
            linkText:"Christian Marclay mini documentary",
            linkSrc:"https://www.youtube.com/watch?v=boWmP0os2e8"
        },	
        {
            linkText:"yoko ono bicycle piece",
            linkSrc:"https://socalledsound.github.io/gap2018/images/non-sense/yokoBicycle.jpg"
        },
        {
            linkText:"pink trombone",
            linkSrc:"https://experiments.withgoogle.com/pink-trombone"
        },	
        {
            linkText:"typedrummer",
            linkSrc:"http://typedrummer.com/qqd87t"
        },
        {
            linkText:"Springs by Berenger Recoules",
            linkSrc:"http://b2renger.github.io/pages_p5js/springs/index.html"
        },
        {
            linkText:"pixelSynth",
            linkSrc:"https://ojack.github.io/PIXELSYNTH/"
        },
        {
            linkText:"gary hill, soundings",
            linkSrc:"https://vimeo.com/111166472"
        },
        {
            linkText:"robert morris, box with the sound of it's own making",
            linkSrc:"https://www.wikiart.org/en/robert-morris/box-with-the-sound-of-its-own-making-1961"
        },
        {
            linkText:"Francisco Lopez, La Selva",
            linkSrc:"https://www.youtube.com/watch?v=1_slk9NmgGM"
        },
        {
            linkText:"this is not la selva",
            linkSrc:"http://thisisnotlaselva.net/"
        },
        {
            linkText:"Head Rhythm 1 and Plaything 2 - Maryanne Amacher",
            linkSrc:"https://www.youtube.com/watch?v=_MahrtRVhkA"
        },

        {
            linkText:"open that window",
            linkSrc:"http://www.openthatwindow.com/"
        },
        {
            linkText:"a selection of Chris Watson's work at the BBC",
            linkSrc:"https://www.bbc.co.uk/programmes/p01qcldf"
        },
        {
            linkText:"Janet Cardiff and George Bures Miller, Sound Walks",
            linkSrc:"http://www.cardiffmiller.com/artworks/walks/"
        },
        {
            linkText: 'freesound.org',
            linkSrc: 'https://freesound.org/',
        },
        {
            linkText: "the ghost in the mp3: tom's diner",
            linkSrc: 'https://www.youtube.com/watch?v=4-ISLpKhQJI',
        },
        {
            linkText: 'Scientists Invent Noise-Canceling Windows',
            linkSrc: 'https://www.insidescience.org/news/scientists-invent-noise-canceling-windows#:~:text=(Inside%20Science)%20%2D%2D%20A%20device,a%20microphone%20outside%20the%20window.',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        ],
        techLinks: [
            {
                linkText: 'howler.js',
                linkSrc: 'https://howlerjs.com/',
            },
            {
                linkText: 'using a third party javascript library',
                linkSrc: 'https://creative-coding.decontextualize.com/external-libraries/',
            },
            {
                linkText: 'addEventListener() method at MDN',
                linkSrc: 'https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener',
            },
            {
    
                linkText: 'web events @ MDN',
                linkSrc: 'https://developer.mozilla.org/en-US/docs/Web/Events',
            },
            {
                linkText: 'objects and arrays in javascript',
                linkSrc: 'https://medium.com/javascript-in-plain-english/introduction-to-javascript-data-structures-af1ecb2d38eb',
            },
            {
                linkText: 'king vs pawn game in ui design',
                linkSrc  : 'https://learnui.design/blog/king-vs-pawn-game-ui-design.html',
            },
            {
                linkText:'buttons in ui design',
                linkSrc : 'https://uxplanet.org/buttons-in-ui-design-the-evolution-of-style-and-best-practices-56536dc5386e',
            },
            {
                linkText: 'video: a brief introduction to audacity',
                linkSrc: 'https://youtu.be/6fL3t7S8AU0',
            },
            {
                linkText: 'sampler starter code',
                linkSrc: 'https://github.com/socalledsound/sampler-starter',
            },
            {
                linkText: 'sampler finished code',
                linkSrc: 'https://github.com/socalledsound/sampler-starter/tree/simple-finished',
            },
            {
                linkText: 'fancier sampler code',
                linkSrc: 'https://github.com/socalledsound/sampler-starter/tree/fancier-sampler',
            },        {
                linkText: 'a simple looping sequencer in js',
                linkSrc: 'https://github.com/socalledsound/js-sequencer',
            },
            {
                linkText: '',
                linkSrc: '',
            },
            {
                linkText: '',
                linkSrc: '',
            },
        ], 
}

export default UNIT1_DAY7