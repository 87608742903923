import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';
export const MessagePanelContainer = styled(Segment)`
    
    @media screen and (max-width:600px){    
        display: none;
    }


`;
