import React from 'react';
import { withRouter } from 'react-router-dom';
import firebase, { firestore, addItemToFirestore } from '../../firebase/firebase.utils';
import uuidv4 from 'uuid/v4';
import { Modal, Input, Button, Icon, Image } from 'semantic-ui-react';
import FileModal from '../../components/file-modal/file-modal.component';



class SubmitWorkModal extends React.Component {
    state = {
        storageref: firebase.storage().ref(),
        collectionRef: 'works',
        usersRef: 'users',
        assignment: '',
        uploadState: '',
        uploadTask: null,
        user: null,
        day: null,
        title: '',
        description: '',
        linkURL: '',
        code: '',
        imageURL: 'https://res.cloudinary.com/chris-kubick/image/upload/v1596140717/side-effects/flyingSaucerPeople_dq8etw.jpg',
        errors: [],
        fileModal: false,
        imageAttached: false,
        form: false,
        submission: null,
        featured: false,
    }

    componentDidMount(){
    const { user, day } = this.props;
    this.setState({
        docRef: this.props.user.id,
        displayName : user.displayName,
        assignment: day.assignment.title,
        user,
        day
    })
    }


    // updateProfile = () => {
    //     const { closeModal } = this.props;
    //     this.updateFirestore();
    //     closeModal();

    // }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value});
        
    }

    openFileModal = ()=> this.setState({ fileModal: true });
    closeFileModal = ()=> this.setState({ fileModal: false });

    createSubmission = () => {
        const { title, description, linkURL, imageURL, assignment, code } = this.state;
    
        const now = new Date().getTime();

        const submission = {
            timestamp: now,
            assignment,
            featured: this.state.featured,
            user: {
                id: this.props.user.id,
                name: this.props.user.displayName,
            },
            code,
            title,
            description,
            linkURL,
            imageURL,
        }
        this.setState({submission: submission }, () => {
            this.uploadSubmission();
            this.updateUser(submission);
           
        }); 
 
    }



    isFormFieldEmpty = ({ title, linkURL, imageAttached}) => {
        return !title.length || !linkURL.length || !imageAttached
    }

    isImageValid = ({ imageURL }) => {
        return true
    };


    isFormValid = () => {
        return true

        // let errors = [];
        // let error;

        // if(this.isFormFieldEmpty(this.state)){
        //     error = { message: 'please fill in all fields'}
        //     this.setState({ errors: errors.concat(error)});
        //     return false
        // } else if (!this.isImageValid(this.state)){
        //     error = {message: 'image must be 800x800 or lower'};
        //     this.setState({ errors: errors.concat(error) });
        //     return false
        // } else {
        //     return true
        // }
    }




    submitAssignment = () => {
        if(this.isFormValid(this.state)){
            this.setState({ errors: [], loading: true });
            this.createSubmission(this.state);
          
        }
    }

    uploadSubmission = () => {
        console.log('upload submission');
        const { submission, collectionRef } = this.state;
        const { history } = this.props;
        console.log('submission in upload', submission);
        if(submission){
            this.setState({ loading: true });
            addItemToFirestore(collectionRef, submission)
                .then(()=>{
                    console.log('successfully added');
                    this.setState({ loading: false, title: '', description: '', linkURL: '', imageAttached: false})
                    history.push('/gallery');
                })
                .catch( err => {
                    console.error(err);
                    this.setState({
                        loading: false,
                        errors: this.state.errors.concat(err)
                    })
                })
        }
    }

    // updateFirestore (){
    //     console.log(this.state.isFeatured);
    //     const update = {displayName: this.state.displayName}
    //     firestore.collection(this.state.collectionRef).doc(this.state.docRef).update(update)
    //     .then(()=> {
    //         console.log("Document successfully written!");
    //     })
    //     .catch(function(error) {
    //         console.error("Error writing document: ", error);
    //     });
    // }



    uploadFile = (file, metadata) => {
        // const pathToUpload = this.state.channel.id;
        // const ref = this.props.messagesRef;
        const filePath = `images/${uuidv4()}.jpg`;
 
        this.setState({ 
            uploadState: 'uploading',
            uploadTask: this.state.storageref.child(filePath).put(file, metadata)
        },
             () => {
                 this.state.uploadTask.on('state_changed', snap => {
                     const percentUploaded = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
                     this.setState({ percentUploaded })
                 },
                 err => {
                     console.error(err);
                     this.setState({
                         errors: this.state.errors.concat(err),
                         uploadState: 'error',
                         uploadTask: null
                     })
                 },
                 () => {
                     this.state.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                        //  this.sendFileMessage(downloadURL, ref, pathToUpload);
                        this.setState({ imageURL: downloadURL, imageAttached: true, form: true})
                     })
                     .catch( err => {
                         console.error(err);
                         this.setState({
                             errors: this.state.errors.concat(err),
                             uploadState: 'error',
                             uploadTask: null
                         })
                     })
                 }    
                 )
             }
        )
     }


     updateUser (submission){
         const { user } = this.props;
         const completed = user.completedAssignments || [];
        const update = {completedAssignments: completed.concat(submission.assignment)}
        firestore.collection(this.state.usersRef).doc(user.id).update(update)
        .then(()=> {
            console.log("Document successfully written!");
        })
        .catch(function(error) {
            console.error("Error writing document: ", error);
        });
     }

    render(){
        const { user, modal, closeModal } = this.props;
        const { errors, title, description, linkURL, fileModal, imageURL, code } = this.state;
        console.log(modal);
        return (
            <Modal size="small" open={modal} onClose={closeModal}>

                <Modal.Content>
                    {user && 
                    <Image src={imageURL} size="tiny" alt="user profile image" style={{ marginBottom: "1em"}}/>
                    }
                
                <Button
                    onClick={this.openFileModal}
                    color="red"
                    content="attach image"
                    labelPosition="right"
                    icon="paperclip"
                    style={{ marginBottom: '1.5em'}}
        />
                <div></div>
                <label>title</label>
                <Input 
                    
                    fluid
                    name="title"
                    style={{ marginBottom: '1.5em' }}
                    placeholder="add a title for your work"
                    onChange={this.handleChange}
                    // onKeyPress={this.handleKeyPress}
                    value={title}
                    className={
                        errors.some( err => err.message.includes('title')) ? 'error' : ''
                    }
                    
                />
                <label>link to your site at github pages:</label>
                <Input 
                    
                    fluid
                    name="linkURL"
                    style={{ marginBottom: '1.5em' }}
                    placeholder="add a link to your site, hosted at github pages -- starts with https://github.io"
                    onChange={this.handleChange}
                    // onKeyPress={this.handleKeyPress}
                    value={linkURL}
                    className={
                        errors.some( err => err.message.includes('linkURL')) ? 'error' : ''
                    }
                    
                />

                <label>link to github repo</label>
                <Input 
                    fluid
                    name="code"
                    style={{ marginBottom: '0.7em' }}
                    placeholder="github here"
                    onChange={this.handleChange}
                    value={code}

                />
                <label>description</label>
                <Input 
                    
                    fluid
                    name="description"
                    style={{ marginBottom: '1.5em' }}
                    placeholder="describe your piece, how you made it and your inspirations"
                    onChange={this.handleChange}
                    // onKeyPress={this.handleKeyPress}
                    value={description}
                    className={
                        errors.some( err => err.message.includes('description')) ? 'error' : ''
                    }
                    
                />
                
                
                </Modal.Content>
                <Modal.Actions>
                    <Button 
                    onClick={this.submitAssignment}
                        color="green"
                        inverted
                        >
                            <Icon name="checkmark" /> submit
                    </Button>
                    <Button 
                        onClick={closeModal}
                        color="red"
                        inverted
                        >
                            <Icon name="remove" /> cancel
                    </Button>
                </Modal.Actions>
            <FileModal
            modal={fileModal}  
            uploadFile={this.uploadFile}
            closeModal={this.closeFileModal}
            />  
            </Modal>


        )
    }
}

export default withRouter(SubmitWorkModal)