import { createSelector} from 'reselect';

const selectWorks = state => state.works;

export const getWorks = createSelector(
    [selectWorks],
    works => works.items
)
export const getRandomWorks = createSelector(
  [selectWorks],
  works => works.items.sort((a,b) => (0.5 - Math.random()))
)


export const selectCurrentWork = itemUrlParam => 
createSelector(
  [getWorks],
  works => works.find(item => item.id === itemUrlParam)
);

export const selectUserWorks = (userId) => 
createSelector(
  [getWorks],
  items => items ? items.filter(item => item.user.id === userId) : null
);


export const getFeaturedWorks = createSelector(
  [getWorks],
  works => works ? works.filter(item => item.featured) : null
);