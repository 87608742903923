import React, { Fragment } from 'react';
import { getWorks, getFeaturedWorks } from '../../redux/works/works.selectors'; 
import { getCurrentDay, getCompletedDays } from '../../redux/days/days.selectors';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
// import { createStructuredSelector } from 'reselect';
import { firestore,  convertWorksSnapshotToMap } from '../../firebase/firebase.utils';
import { updateWorks } from '../../redux/works/works.actions';
// import { updateCritMessages } from '../../redux/critMessages/critMessages.actions';
import GalleryRow from '../../components/gallery-row/gallery-row.component';
import { ASSIGNMENT_TITLES } from '../../redux/assignments/assignments.data';
import './gallerypage.styles.scss';

const galleryCategories = ASSIGNMENT_TITLES;
document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
document.body.scrollTop = 0; // For Safari
class GalleryPage extends React.Component {
    // console.log(works, 'in component');
   
    state = {
        collectionRef: firestore.collection('works'),
        // loading: true,
        currentDayLastVisible: null,
        randomLastVisible: null,
        lastVisibleObject: {},
        featuredWorks: null,
        works: [],
    }


componentDidMount(){
    const { updateWorks, currentDay, completedDays  } = this.props;
    const { collectionRef, featuredWorks } = this.state;
    console.log(featuredWorks);
    const currentDayAssignmentTitle = currentDay.assignment.title;
    const currentDayRef = collectionRef.where('assignment', '==', currentDayAssignmentTitle);
    const project2Ref = collectionRef.where('assignment', '==', 'project 2: goldberg machine');

    this.unsubscribeFromFeatured = collectionRef.where('featured', '==', true).onSnapshot(async snapshot => {
        const featuredWorks = convertWorksSnapshotToMap(snapshot);
        updateWorks(featuredWorks);
        console.log('featured works length:  ', featuredWorks.length);
        this.setState(prevState => { 
            return({
                works: prevState.works.concat(featuredWorks)
            });
        })
    })

    this.unsusbscribeFromCurrentWorks = currentDayRef.onSnapshot(snap => {
        const currentDayWorks = convertWorksSnapshotToMap(snap);
        updateWorks(currentDayWorks);
        this.setState(prevState => ({works: prevState.works.concat(currentDayWorks)}))
    })


    this.unsusbscribeFromProject2 = project2Ref.onSnapshot(snap => {
        const project2Works = convertWorksSnapshotToMap(snap);
        updateWorks(project2Works);
        this.setState(prevState => ({works: prevState.works.concat(project2Works)}))
    })


    // currentDayRef.get().then(documentSnapshots => {
    //     console.log(documentSnapshots);
    //     const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
    //     const currentDayWorks = convertWorksSnapshotToMap(documentSnapshots);
    //     updateWorks(currentDayWorks);
    //     console.log(currentDayWorks);
    //     this.setState( prevState => ({
    //         ...prevState, 
    //         lastVisibleObject: {
    //                 ...prevState.lastVisibleObject,
    //                 currentDayAssignmentTitle: lastVisible
    //         }, 
    //         works: prevState.works.concat(currentDayWorks)
    //     }))
    // })
    
   
    const randomRef = collectionRef.where('assignment', '==', 'random');
    randomRef.orderBy("timestamp", "desc").limit(5).get().then(documentSnapshots => {
        const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
        const randomWorks = convertWorksSnapshotToMap(documentSnapshots);
        updateWorks(randomWorks);
        this.setState( prevState => {
            // let newWorks = prevState.works;
            //  newWorks.concat(randomWorks);
            return ({ 
            ...prevState,
            lastVisibleObject: {
                    ...prevState.lastVisibleObject,
                    randomLastVisible: lastVisible
            }, 
             works: prevState.works.concat(randomWorks) 
        })
        }
        )
    })


    // this.unsubscribeFromFeatured = collectionRef.where('featured', '==', true).onSnapshot(async snapshot => {
    //     const featuredWorks = convertWorksSnapshotToMap(snapshot);
    //     updateWorks(featuredWorks);
    //     this.setState(prevState => { 
    //         return({
    //             works: prevState.works.concat(featuredWorks)
    //         });
    //     })
    // })


    this.loadAllAssignments(completedDays);





   

    // console.log(galleryCategories);

    // galleryCategories.map(category => {
    //     console.log(category);
    //     const categoryRef = collectionRef.where('assignment', '==', `${category}`);
    //     const unsubscribeFromCategoryListener = categoryRef.limit(10).onSnapshot(async snapshot => {
    //         const works = convertWorksSnapshotToMap(snapshot); 
    //         updateWorks(works);
            
    //       })
    //       worksListeners.push(unsubscribeFromCategoryListener);
    // })



    // this.unsusbscribeFromWorks = collectionRef.orderBy("timestamp", "desc").limit(30).onSnapshot(async snapshot => {
    //   const works = convertWorksSnapshotToMap(snapshot); 
    //   updateWorks(works);
    //   this.setState({loading: false});
    // })
    window.scrollTo(0, 0);
    window.addEventListener('onbeforeunload', this.onbeforeunload);
}

componentWillUnmount(){
    this.unsubscribeFromFeatured();
    // this.unsubscribeFromCritMessages();
    this.unsusbscribeFromCurrentWorks();
    this.unsusbscribeFromProject2();
    // this.state.worksListeners.map(unsubscribe => unsubscribe());

  }

  

  onbeforeunload = function () {
    window.history.scrollRestoration = "manual";
    window.scrollTo(0, 0);
  }


  loadAssignmentWorks = (title) => {
      console.log(title);
      const currentDayRef = this.state.collectionRef.where('assignment', '==', title);
    
      currentDayRef.orderBy("timestamp", "desc").limit(6).get().then(documentSnapshots => {
        //   console.log(documentSnapshots);
          const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
          const currentDayWorks = convertWorksSnapshotToMap(documentSnapshots);
          this.props.updateWorks(currentDayWorks);
            const key = title;
          this.setState( prevState => { 
              let lastVisibleObject = Object.assign({}, prevState.lastVisibleObject);
                lastVisibleObject[key] = lastVisible;
               let works = prevState.works.concat(currentDayWorks);  
                return { lastVisibleObject, works }
          })
      })
  }

  loadMoreAssignmentWorks = (title) => {
    const { collectionRef, lastVisibleObject } = this.state;
    const currentDayRef = collectionRef.where('assignment', '==', title);
    if(lastVisibleObject[title]){
    currentDayRef.orderBy("timestamp", "desc").startAfter(lastVisibleObject[title]).get().then(documentSnapshots => {
      //   console.log(documentSnapshots);
        const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
        const currentDayWorks = convertWorksSnapshotToMap(documentSnapshots);
        console.log(currentDayWorks);
        this.props.updateWorks(currentDayWorks);
          const key = title;
        this.setState( prevState => { 
            let lastVisibleObject = Object.assign({}, prevState.lastVisibleObject);
              lastVisibleObject[key] = lastVisible;
              let works = prevState.works.concat(currentDayWorks); 
              return { lastVisibleObject, works }
        })
    })
    }
}

  loadMoreRandom = () => {
      console.log(this.state);
    const { collectionRef, lastVisibleObject } = this.state;
    const randomRef = collectionRef.where('assignment', '==', 'random');
    if(lastVisibleObject.randomLastVisible){
    randomRef.orderBy("timestamp", "desc").startAfter(lastVisibleObject.randomLastVisible).limit(5).get().then(documentSnapshots => {
        const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
       
        const randomWorks = convertWorksSnapshotToMap(documentSnapshots);
        console.log(randomWorks);
        this.props.updateWorks(randomWorks);
        this.setState( prevState => ({ 
            lastVisibleObject: {
                    ...prevState.lastVisibleObject,
                    randomLastVisible: lastVisible
            },
             works: prevState.works.concat(randomWorks) 
        }))
    })
    }
  }



   renderFeatured() {
       const { featuredWorks, works } = this.props;
       console.log(works.filter(item => item.featured));
       console.log('featured:  ', featuredWorks);
    if(featuredWorks){
        //const randomizedFeaturedWorks = featuredWorks.sort((a,b) => (0.5 - Math.random()) ); 
        const sortedFeaturedWorks = featuredWorks.sort((a,b) => (b.timestamp - a.timestamp) );  
         if(sortedFeaturedWorks.length > 0 ){
            return (
                <Fragment key="featured">
                
                <GalleryRow  item={'selected'} works={sortedFeaturedWorks} className="gallery-row" loadMore={this.loadMoreAssignmentWorks}/>
                </Fragment>
            )
         }
         else {
             return (
                 <div></div>
             )
         }

    }

    return (
        <div></div>
    )
    }
   
    renderRows = (works) => galleryCategories.map((item, index) => {
            
            if(works){
                // const categoryWorks = works.filter(work => work.assignment === item).sort((a,b) => (0.5 - Math.random()) );
                const categoryWorks = works.filter(work => work.assignment === item);
                // console.log(item)
                if(categoryWorks.length > 0) {
                    return (
                        <Fragment key={item}>
                          
                            <GalleryRow  item={item} works={categoryWorks} className="gallery-row" loadMore={this.loadMoreAssignmentWorks}/>
                        </Fragment>
                )}
            }
            return(
                <div key={Math.random()}></div>
            )
    })    


    renderRandom = (works) => {
        // console.log(this.state.lastVisibleObject);
        // console.log("random here");
     if(works){
          const randomWorks = works.filter(item => item.random).sort((a,b) => (a.timestamp - b.timestamp) );

          if(randomWorks.length > 0 ){
             return (
                 <Fragment key="random">
                 {/* <h4>random</h4> */}
                 <GalleryRow  item={'random'} works={randomWorks} className="gallery-row" loadMore={this.loadMoreRandom}/>
                 </Fragment>
             )
          }
          else {
              return (
                  <div></div>
              )
          }
 
     }
 
     return (
         <div>please refresh your browser and/or login</div>
     )
 }

    renderMoreAssignmentsButtons = completedDays => {
            console.log(completedDays);
            const sortedCompletedDays = completedDays.sort((a,b) => b.id - a.id);
            const buttons = sortedCompletedDays.map((day, id) => {
                if(id > 0){
                return (
                    <div className="buttons-container">
                        <Button onClick={()=>this.loadAssignmentWorks(day.assignment.title)}>{day.assignment.title || null}</Button>
                    </div>
                  
                )
                } else {
                    return null
                }
            })

            return buttons
    }

    loadAllAssignments = completedDays => {
            console.log(completedDays);
            const sortedCompletedDays = completedDays.sort((a,b) => b.id - a.id);
            sortedCompletedDays.map(day => {
                this.loadAssignmentWorks(day.assignment.title);
                return null
            })
    }



    refreshItem = () => {
        this.setState({imageChanged: true});
    }

    render(){
        const { works} = this.props;
        console.log(works);
        if(works){
        return (
            <React.Fragment>
    
                    {this.renderFeatured(works)}
                    {this.renderRows(works)}
                    {this.renderRandom(works)}
                    {/* {this.renderMoreAssignmentsButtons(completedDays)} */}
    
    
            </React.Fragment>
                )
    } else {
        return (
            <div>
                loading
            </div>
        )
    

    }     

    
        }
    }


const mapDispatchToProps = dispatch => ({
    updateWorks: works => dispatch(updateWorks(works)), 
})




// const mapStateToProps = createStructuredSelector({
    // works: getWorks
// })

const mapStateToProps = (state, ownProps) => {
    // console.log(ownProps.currentUser.id);
   return({

       currentDay: getCurrentDay()(state), 
       completedDays: getCompletedDays(Date.now())(state),
       works: getWorks(state),
       featuredWorks: getFeaturedWorks(state)

   })
}



export default connect(mapStateToProps, mapDispatchToProps)(GalleryPage)