import React from 'react';
import mime from 'mime-types';
import { Modal, Input, Button, Icon } from 'semantic-ui-react';

class FileModal extends React.Component {

    state = {
        file: null,
        authorized: ['image/jpeg', 'image/png'],
       
    }

    addFile = event => {
        const file = event.target.files[0];
        const fileSize = file.size / 1024 /1024;
        if(file){

            if(fileSize > 1) {
                alert('file cannot be bigger than 1MB')
            }
            else {
                this.setState({ file: file});
            }
            
        }
    }

    sendFile = () => {
        const { file } = this.state;
        const { uploadFile, closeModal, } = this.props;

        if(file !== null){
            if(this.isAuthorized(file.name)){
                const metadata = { contentType: mime.lookup(file.name)}
                uploadFile(file, metadata);
                closeModal();     
                this.clearFile();
                this.setState({ file: null})
            }
        }
        else {
            alert('please check to see that the image file is less than 1MB')
        }

    }

    clearFile = () => this.setState({ file: null});

    isAuthorized = filename => this.state.authorized.includes(mime.lookup(filename));

    render(){
    
        const { modal, closeModal } = this.props;
    
        return (
            <Modal basic open={modal} onClose={closeModal}>
                <Modal.Header>select an image file</Modal.Header>
                <Modal.Content>
                    <Input 
                        onChange={this.addFile}
                        fluid
                        label="file types: .jpg, .png, file cannot exceed 1mb"
                        name="file"
                        type="file"
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button 
                    onClick={this.sendFile}
                        color="green"
                        inverted
                        >
                            <Icon name="checkmark" /> select
                    </Button>
                    <Button 
                        onClick={closeModal}
                        color="red"
                        inverted
                        >
                            <Icon name="remove" /> cancel
                    </Button>
                </Modal.Actions>


            </Modal>
        )
    }
}



export default FileModal