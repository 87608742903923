import React from 'react';
import {withRouter } from 'react-router-dom';
import { connect  } from 'react-redux';
// import { updateUsers } from '../../redux/user/user.actions.js';
// import { firestore, convertUsersSnapshotToMap} from '../../firebase/firebase.utils';
import StudentsRoster from '../../components/students-roster/students-roster.component';
import LoadingSpinner from '../../components/loading-spinner/loading-spinner.component';
import './all-users-page.styles.scss';


class AllStudentsPage extends React.Component{

    state = {
        loading: true,
        // usersRef: firestore.collection('users'),
    }
    


    componentDidMount(){
        //  const { students } = this.props;
        this.setState({ loading: false });
         // const { usersRef } = this.state;



    }


    render(){
        const { students, history } = this.props;
        const { loading } = this.state;
        console.log(students);
    
        // if(students){
        //     this.setState({loading: false});
        // }

        if(Array.isArray(students)){

            const randomizedStudents = students.sort((a,b) => (0.5 - Math.random()) );
            console.log(randomizedStudents);
            

        if(!loading){

        
                return (
                    
                    <div className="all-users-container">
                        <StudentsRoster  students={randomizedStudents} history={history} />
                    </div>
                )
            }

        } else {
            return (
                <LoadingSpinner />
            )
        }

        return (
            <LoadingSpinner />
        )
    
    }

}

const mapDispatchToProps = dispatch => ({
    // updateUsers: users => dispatch(updateUsers(users)),
})

const mapStateToProps = (state) => ({
    currentUser: state.users.currentUser,
    students: state.users.users,
  })


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AllStudentsPage))