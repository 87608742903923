import { ASSIGNMENT4 } from "../../assignments/assignments.data"

const UNIT1_DAY4 = {
    id: 4,
    unit: 'unit1',
    route: '/units/unit1/day4',
    assignmentRoute: '/units/unit1/day4/assignment', 
    month: 'September',
    day: 8, 
    name: 'can haz conceptz?',
    // illustrationURL: 'https://res.cloudinary.com/chris-kubick/image/upload/v1596752800/side-effects/mario-kruger_auqv3u.jpg',
    illustrationURL: 'https://res.cloudinary.com/chris-kubick/image/upload/v1596773243/side-effects/what-if_zxos1x.jpg',
    description: "Today we'll start off by looking at some artists that made memes long before the internet made it a ubiquitous practice: Dada, surrealism, situationism, conceptualism, institutional critique.  Then, we'll make our own.  In addition, we're going to start having some assets, ie image files, which we'll host using a service called Cloudinary.",
    assignment: ASSIGNMENT4,
    videos: [
        {
            id : 'UB1O30fR-EE',
            title:'html basics from traversy media',
            description: 'an hour long dive into the basics of html'
        },
        {
            id : 'yfoY53QXEnI',
            title: 'css basics from traversy media',
            description: 'a video about the basics of css from one of my favorite teachers of web coding'
        },
        {

        },
        {

        },
        {

        },
        {

        },
    ],  
    inspirationLinks: [

        {
            linkText:'image macros as public discourse',
            linkSrc : 'http://www.rhizomes.net/issue32/memes/publicdiscourse.html',
        },
        {
            linkText:'art has its own memes',
            linkSrc : 'https://blog.indiewalls.com/2016/10/art-has-its-own-memes',
        },
        {
            linkText:'facebook memes and situationist slogans',
            linkSrc : 'https://tenderardour.com/2012/08/12/facebook-memes-situationist-slogans/',
        },
        {
            linkText:'meme, at wikipedia',
            linkSrc : 'https://en.wikipedia.org/wiki/Meme#:~:text=The%20word%20meme%20is%20a%20shortening%20(modeled%20on%20gene)%20of,a%20concept%20for%20discussion%20of',
        },
        {
            linkText:'can haz cheezburger',
            linkSrc : 'https://icanhas.cheezburger.com/',
        },
        {
            linkText:'marcel duchamp, the creative act',
            linkSrc : 'http://www.ubu.com/papers/duchamp_creative.html',
        },
        {
            linkText:'barbara kruger: WARCOOTUJ',
            linkSrc : 'https://www.thecut.com/2017/11/i-think-about-when-barbara-kruger-dragged-supreme-a-lot.html',
        },

        {
            linkText:'guerilla girls',
            linkSrc : 'http://www.guerrillagirls.com/',
        },
        {
            linkText:'poor meme, rich meme',
            linkSrc : 'https://reallifemag.com/poor-meme-rich-meme/',
        },
        {

            linkText: 'Hito Steyerl - In Defense of the Poor Image',
            linkSrc: 'https://www.e-flux.com/journal/10/61362/in-defense-of-the-poor-image/',
        }


   


        ], 
        techLinks: [

            {
                linkText: 'cloudinary : a nice platform for hosting images',
                linkSrc: 'https://cloudinary.com/',
            },
            {
                linkText:'google fonts',
                linkSrc : 'https://fonts.google.com/',
            },
            {
                linkText: 'fontology',
                linkSrc: 'https://www.fonts.com/content/learning/fontology',
            },
            {
                linkText: 'a great online book about web typography -- book mark it!',
                linkSrc: 'https://prowebtype.com/',
            },
            {
                linkText:'the <img /> embed element',
                linkSrc : 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img',
            },
            {
                linkText: 'unicode character table',
                linkSrc: 'https://unicode-table.com/en/#basic-latin',
            },
            {
                linkText:'css filter:',
                linkSrc : 'https://developer.mozilla.org/en-US/docs/Web/CSS/filter',
            },
            {
                linkText: 'css webkit prefix',
                linkSrc: 'https://css3gen.com/css3-webkit/#:~:text=Webkit%20refers%20to%20the%20browser,knowledge%20of%20web%20browser%20engines.',
            },
            {
                linkText: 'getting started with cloudinary video',
                linkSrc: 'https://youtu.be/VbOUK3WmVqo',
            },
            {
                linkText: 'kitten meme',
                linkSrc: 'https://codepen.io/Fleischut/pen/LYNVjqx',
            },
            {
                linkText: 'banker meme',
                linkSrc:'https://codepen.io/Fleischut/pen/LYNEojd',
            }
        ]
}

export default UNIT1_DAY4