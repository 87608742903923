import { UserActionTypes } from './user.types';

const INITIAL_STATE = {
    currentUser: null,
    users: null,
    userNotifications: null,
}

const userReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case UserActionTypes.SET_CURRENT_USER :
            return {
                ...state, 
                currentUser: action.payload
            }       

        case UserActionTypes.UPDATE_USER_NOTIFICATIONS : 
            return {
                ...state,
                userNotifications: action.payload
            }    

        case UserActionTypes.UPDATE_USERS : 
            return {
                ...state,
                users: action.payload
            }   

        default: 
            return state    
    }
}

export default userReducer