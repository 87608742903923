import React from 'react';
import ReactMarkdown from 'react-markdown';
import SyllabusMarkdown from '../../redux/syllabus/syllabus-overview.md';

// import CustomButton from '../custom-button/custom-button.component';
import './syllabus-overview.styles.scss';

class SyllabusOverview extends React.Component {

    constructor(props){
        super(props);
        this.state = {syllabusMarkdown: null}
    }

    componentWillMount(){
        fetch(SyllabusMarkdown)
            .then(resp => resp.text())
            .then( text => this.setState({ syllabusMarkdown: text}))
    }

    render() {
    return (
        <div className="syllabus-overview-container">
            <div className="content">
                <ReactMarkdown source={this.state.syllabusMarkdown} />
            </div>
        </div>
    )
    }
}

export default SyllabusOverview