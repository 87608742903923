import React from 'react';
import './day-link.styles.scss';
import { withRouter } from 'react-router-dom';

const DayLink = ({item, history}) => {

    const { month, day, name, route} = item;

    return (
    <div className='day-link-item'
    onClick={() => history.push(`${route}`)}
    >

        
            <span>{month} </span>
            <span>{day}  :  </span>
            <span>{name}</span>
    

    </div>
)
    }

export default withRouter(DayLink)