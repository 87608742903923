import React from 'react';
import { connect } from 'react-redux';
import { updateSelectedMessageResponses } from '../../redux/messages/messages.actions';
import { selectAllUsers} from '../../redux/user/user.selectors';
import { firestore, addItemToFirestore } from '../../firebase/firebase.utils';
import {  Segment, Button } from 'semantic-ui-react';
import { createSelector } from 'reselect';
// import { selectSelectedMessage } from '../../redux/messages/messages.selectors';
// import FileModal from './FileModal';
// import ProgressBar from './ProgressBar';


class DiscussionMessageFormTextArea extends React.Component {

    state = {
        usersRef: 'users',
        messagesRef: 'messages',
        uploadState: '',
        uploadTask: null,
        percentUploaded: 0,
        message: '',
        topic: null,
        loading: false,
        user: this.props.currentUser,
        errors: [],
        modal: false,
    }


    componentDidMount(){
         const { topic, user, updateParent, selectedMessage } = this.props;
         this.setState({ topic, user, updateParent, selectedMessage});
    }


    openModal = () => this.setState({ modal: true});
    closeModal = () => this.setState({ modal: false});

    handleChange = event => {
        // this.queryUsers();
        this.setState({ message: event.target.value, user: this.props.user})
    }

    handleKeyPress = (e) => {
        if (e.charCode === 13) {
          // Prevent the default action to stop scrolling when space is pressed
        //   e.preventDefault()
        //   console.log('Button received click with keyboard');
          this.sendMessage();
        }
      }

      convertLinks = ( input ) => {

        let text = input;
        const linksFound = text.match( /(?:www|https?)[^\s]+/g );
        const aLink = [];
        
        if ( linksFound != null ) {
        
          for ( let i=0; i<linksFound.length; i++ ) {
            let replace = linksFound[i];
            if ( !( linksFound[i].match( /(http(s?)):\/\// ) ) ) { replace = 'http://' + linksFound[i] }
            let linkText = replace.split( '/' )[2];
            if ( linkText.substring( 0, 3 ) == 'www' ) { linkText = linkText.replace( 'www.', '' ) }
            if ( linkText.match( /youtu/ ) ) {
        
              let youtubeID = replace.split( '/' ).slice(-1)[0];
              aLink.push( '<div class="video-wrapper"><iframe src="https://www.youtube.com/embed/' + youtubeID + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>' )
            }
            else if ( linkText.match( /vimeo/ ) ) {
              let vimeoID = replace.split( '/' ).slice(-1)[0];
              aLink.push( '<div class="video-wrapper"><iframe src="https://player.vimeo.com/video/' + vimeoID + '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>' )
            }
            else {
              aLink.push( '<a href="' + replace + '" target="_blank">' + linkText + '</a>' );
            }
            text = text.split( linksFound[i] ).map(item => { return aLink[i].includes('iframe') ? item.trim() : item } ).join( aLink[i] );
          }
          return text;
        
        }
        else {
          return input;
        }
        }
        


    createMessage = (fileUrl = null) => {
        const { user, topic, selectedMessage } = this.state;
        let message = null;
        const responseStatus = selectedMessage != null;
        console.log(responseStatus); 
        // console.log(user, 'in discussion create message');
        if(topic){
            const now = new Date().getTime();
            message = {
                timestamp: now,
                content: this.state.message,
                topic: topic.id,
                response: responseStatus,
                createdBy: {
                    id: this.state.user.id,
                    name: user.displayName,
                    avatar: user.photoURL,
                }
            };
        } 

        // if(fileUrl !== null){
        //     message['image'] = fileUrl;
        // } else {
        //     message["content"] = this.state.message;
        // }

        return message
    }

    sendMessage = () => {
        const { message, messagesRef, updateParent }  = this.state;
        console.log('in send message');
        if(message && messagesRef){
            console.log('sending: ',message);
            this.setState({ loading: true});
            const newMessage = this.createMessage();
            addItemToFirestore(messagesRef, newMessage)
                .then(() => {
                    console.log('success');
                }).then(() =>{
                    // console.log('success');
                     this.updateSelectedMessageInFirestore(newMessage);
                }).then(()=>{
                    this.setState({ loading: false, message: ''});
                    updateParent(message.topic);
                }).then(()=> {
                    this.updateUserMessageTotal();
                    
                })
                .catch(err=>this.setState({
                    errors: this.state.errors.concat({message: err})
                }))

        
        } else {
            this.setState({
                errors: this.state.errors.concat({message: 'add a message'})
            })
        }
    }

    updateSelectedMessageInFirestore = newMessage => {
        const { selectedMessage, updateSelectedMessageResponses } = this.props
        console.log('update function', selectedMessage);

        const updatedResponses = [ ...selectedMessage.responses || [], newMessage];
            // const updatedResponses = [
            //     ...(selectedMessage.responses.length > 0 ? selectedMessage.responses : []),
            //     newMessage
            // ];
            const update = {responses: updatedResponses};
            console.log('UUUUUUUPPPPPDDATE', update);
            
            firestore.collection(this.state.messagesRef).doc(selectedMessage.id).update(update)
            .then(()=> {
                updateSelectedMessageResponses(updatedResponses);
                console.log("updated selected message in firestore ");
            })
            .catch(function(error) {
                console.error("Error writing selected message ", error);
            });
        

    }

    updateUserMessageTotal (){
        const { user } = this.state;
        let updatedVal = 1;
        if(user.numDiscussionMessages){
            updatedVal = user.numDiscussionMessages + 1;
        } 
    
       const update = {numDiscussionMessages: updatedVal}
       firestore.collection(this.state.usersRef).doc(user.id).update(update)
       .then(()=> {
           console.log("updated user discussion messages ");
       })
       .catch(function(error) {
           console.error("Error writing document: ", error);
       });
    }

    render(){
        const { errors, message } = this.state;
        return (
           <Segment className="message__form">
               {/* <Input 
                    
                    fluid
                    name="message"
                    style={{ marginBottom: '0.7em' }}
                    action={{
                        icon : 'add',
                        onClick: ()=> this.sendMessage(),
                        color: 'teal',
                    }}
                   
                    placeholder="write new message"
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    
                    value={message}

               /> */}
            <textarea
              style={{
                height: '70px',
                width: '100%',
                lineHeight: '1em',
                fontFamily: 'Martel',
                padding: '6px',
              }}
              placeholder="leave a comment"
              onChange={this.handleChange}
            //   value={this.state.textareaValue}
              value={message}
            />
            <Button
                       icon = 'add'
                       color = 'teal'
                       onClick={()=> this.sendMessage() } 
                    style={{width: '100%'}}

            > add comment
            </Button>


            {/* <Button.Group icon widths="2"> */}
                {/* <Button
                    onClick={this.sendMessage}
                    disabled={loading}
                    color="orange"
                    content="add comment"
                    labelPosition="left"
                    icon="edit"
                    
                /> */}
                {/* <Button
                    onClick={this.openModal}
                    color="teal"
                    content="upload media"
                    labelPosition="right"
                    icon="cloud upload"
                /> */}

            {/* </Button.Group> */}

            {/* <FileModal
                    modal={modal}  
                    uploadFile={this.updloadFile}
                    closeModal={this.closeModal}
            /> */}

            {/* <ProgressBar
                uploadState={uploadState}
                percentUploaded={percentUploaded}
            /> */}

           </Segment>
        )
    }

}


const mapDispatchToProps = dispatch => ({
    updateSelectedMessageResponses : update => dispatch(updateSelectedMessageResponses(update)),
})

const mapStateToProps = (state) => ({
    users: state.users.users,
})


export default connect(mapStateToProps, mapDispatchToProps)(DiscussionMessageFormTextArea)




// messagesRef
// .child(channel.id)
// .push()
// .set(this.createMessage())
// .then(() => {
//     this.setState({ loading: false, message: ''})
// })
// .catch(err=>{
//     console.error(err);
//     this.setState({
//         loading: false,
//         errors: this.state.errors.concat(err)
//     })
// })