import React from 'react';
import { withRouter } from 'react-router-dom';
import { Comment } from 'semantic-ui-react';
import moment from 'moment';

import './user-page-crit-message.styles.scss';



const timeFromNow = (timestamp) => moment(timestamp).fromNow();


const UserPageCritMessage = ({ message, history }) => {
    // console.log(message);
    return (
        
        <Comment  className="comment-block">
            <Comment.Avatar src={message.by.avatar} onClick={ ()=> history.push(`/students/${message.user.id}`)}/>
           
            <Comment.Content >
                <Comment.Author as="a" onClick={ ()=> history.push(`/students/${message.user.id}`)}>{message.by.name}</Comment.Author>
                
                <Comment.Metadata>{timeFromNow(message.timestamp)}</Comment.Metadata>
                <Comment.Metadata>about {message.about}</Comment.Metadata>
                <div className="comment-text">{message.comment} </div> 
            </Comment.Content>


        </Comment>


    )
    }


export default withRouter(UserPageCritMessage)

