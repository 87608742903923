import React from 'react';
// import { connect } from 'react-redux';
// import { Link, withRouter } from 'react-router-dom';
// import { selectCurrentDay } from '../../redux/days/days.selectors'; 
// import { selectCurrentUser } from '../../redux/user/user.selectors';
// import ReactMarkdown from 'react-markdown';
import MarkdownView from 'react-showdown';
// import CustomButton from '../../components/custom-button/custom-button.component';
// import { Button } from 'semantic-ui-react';

// import SubmitWorkModal from '../../components/submit-work-modal/submit-work-modal.component';

// import AssignmentText from '../../redux/workshops/day2.workshop.md';

import rPiPath from './raspi.md';


import './rPipage.styles.scss';

class RpiPage extends React.Component {
    state = {
        markdown : null,
        modal: false,
    }



    componentDidMount(){
        // const { day, currentUser } = this.props;
        // console.log(currentUser, 'in component did mount');
        // this.setState({
        //     day,
        //     currentUser,
        // })
        window.scrollTo(0, 0);
    }


    componentWillMount(){ 
            fetch(rPiPath)
            .then(resp => resp.text())
            .then( text => this.setState({ markdown: text}))
    }

    // openModal = () => this.setState({ modal: true });
    // closeModal = () => this.setState({ modal: false });


    // showButton(){
    //     const { assignment } = this.props.day;
    //     const {currentUser, history } = this.props;
       
    //     const completed = currentUser.completedAssignments || [];
    //     const submitted = completed.indexOf(assignment.title) > -1;
            
     

    //     if(!submitted){
    //         if(assignment.project){
    //             console.log(assignment.project);
    //             return (
    //             <Button
    //             onClick={() => history.push(`/unit${assignment.project}/project/project${assignment.project}`)}
    //             color="green"
    //             content="submit project"
    //             labelPosition="right"
    //             icon="star"
    //             style={{ marginBottom: '1em'}}
    //             />
    //             )
    //         } else {
    //         return (
    //             <Button
    //             onClick={() => this.openModal()}
    //             color="green"
    //             content="submit assignment"
    //             labelPosition="right"
    //             icon="star"
    //             style={{ marginBottom: '1em'}}
    //             />
    //         )}
    //     } else {
    //         return (
    //             <div className='edit-error'>you've already submitted this assignment.  you can edit it in the <Link to="/gallery">  gallery</Link></div>
    //         )
    //     }

    // }


    render() {
        const { modal  } = this.state;
       const { currentUser, day } = this.props;
    return (
        <div className="assignment-page-container">
            <div className="content">
               
                <MarkdownView 
                      markdown={this.state.markdown}
                      options={{ tables: true, emoji: true }}
                
                />
              
            </div>

            {/* {this.showButton()}

            <SubmitWorkModal modal={modal} closeModal={this.closeModal} user={currentUser} day={day}/> */}
            
        </div>
    )
    }
}

// const mapStateToProps = (state, ownProps) => {

//     const {users:{currentUser}} = state;
//     return ({
//         currentUser,
//          day: selectCurrentDay(ownProps.match.params.dayId)(state),
//     }) 
// } 

export default RpiPage