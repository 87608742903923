import { WorksActionTypes } from './works.types';

export const updateWorks = works => ({
    type: WorksActionTypes.UPDATE_WORKS,
    payload: works
})

export const addSubmission = submission => ({
    type: WorksActionTypes.ADD_SUBMISSION,
    payload: submission
})

export const setCurrentItem = item => ({
    type: WorksActionTypes.SET_CURRENT_ITEM,
    payload: item
})

export const updateCurrentItem = item => ({
    type: WorksActionTypes.UPDATE_CURRENT_ITEM,
    payload: item
})

export const removeItem = item => ({
    type: WorksActionTypes.REMOVE_ITEM,
    payload: item
})