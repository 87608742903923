import React from 'react';
import {  Link, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { firestore, convertCritMessagesSnapshotToMap} from '../../firebase/firebase.utils';
import { updateCritMessages } from '../../redux/critMessages/critMessages.actions';

import { selectCurrentWork } from '../../redux/works/works.selectors';
// import { selectCurrentCritMessages } from '../../redux/critMessages/critMessages.selectors';
// import { selectItemUser } from '../../redux/user/user.selectors';
// import { concatCritMessages } from '../../redux/critMessages/critMessages.actions';
import { setCurrentItem } from '../../redux/works/works.actions';
import { Grid, Icon } from 'semantic-ui-react';
import MessagesPanel from '../../components/messages-panel/MessagesPanel.component';
import WorkPanel from '../../components/work-panel/work-panel.component';
// import { renderers } from 'react-markdown';
import { DisappearingMessagesPanel, ShiftingColumn, GalleryBackground } from './final-project-item.styles.jsx';
document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
document.body.scrollTop = 0; // For Safari
 class FinalProjectItemPage extends React.Component {

    state = {
        loading : true,
        messagesLoading : true,
        imageChanged: false,
        messages: null,
    }

    componentDidMount(){

    const { currentUser, item, currentItem, refreshItem } = this.props;
        console.log(currentItem);
    this.setState({ currentUser, item, currentItem, refreshItem });
        console.log(item.id);
    if(item){

        // const itemCritCollectionRef = firestore.collection('critMessages').where('topic', '==', `${item.id}`);
        // this.unsubscribeFromItemCritMessages = itemCritCollectionRef.onSnapshot(async snapshot => {
          
        //   const itemCritMessages = convertCritMessagesSnapshotToMap(snapshot); 
        //   console.log("itemCritMessages:  ", itemCritMessages);
        //   this.props.concatCritMessages(itemCritMessages);
        //   this.setState({loading: false});
        // })

        this.props.setCurrentItem(item)
        const { updateCritMessages } = this.props;
        const critCollectionRef = firestore.collection('critMessages').where('topic', '==', `${item.id}`);
        this.unsubscribeFromItemCritMessages = critCollectionRef.onSnapshot(async snapshot => {
          console.log(snapshot);
          const critMessages = await convertCritMessagesSnapshotToMap(snapshot); 
          console.log('crit-messages:', critMessages.length)
          await updateCritMessages(critMessages);
          this.setState({loading: false, messages: critMessages});
        })
    }

    window.scrollTo(0, 0);
    }


    componentWillUnmount(){
         this.unsubscribeFromItemCritMessages();
         window.onbeforeunload = function () {
            window.scrollTo(0, 0);
          }
    }

    updateItem = (item) => {
         this.setState({loaded: true});
        // const { item } = this.props;
        // console.log(item, downloadURL);
       
        // const newItem = {
        //     ...item,
        //     imageURL: downloadURL,
        // }
        this.props.setCurrentItem(item);
       
    }



       render() {
        const { currentUser, item, currentItem, createdBy} = this.props;
        const { messages } = this.state;
        // console.log(item, itemCritMessages, 'in galleryitem opage');
       if(item && messages) {
        return (

            <GalleryBackground>
                <div style={{marginBottom: '18px', marginTop: '24px', width: '200px', backgroundColor: 'lightgrey', padding: '12px'}}>
                <Link to='/finalshow' >
                    <Icon name="arrow left" /> back to show
                 </Link>
                </div>


            <Grid columns="equal" >
                <DisappearingMessagesPanel width={5} >
                    <MessagesPanel currentUser={currentUser} item={item} messages={messages} />
                </DisappearingMessagesPanel>
               

                <ShiftingColumn >
                    <WorkPanel item={item} currentUser={currentUser} currentItem={currentItem} updateItem={this.updateItem} createdBy={createdBy}/>
                </ShiftingColumn>
                

            </Grid>
            {/* { user.admin ? <div>admin here</div> : <div></div>} */}
            </GalleryBackground>
        )
       } else {
           return (
               <div>loading...</div>
           )
       }

        
  
        }
}



const mapDispatchToProps = dispatch => ({
    setCurrentItem: item => dispatch(setCurrentItem(item)),
    // updateCurrentItem: item => dispatch(updateCurrentItem(item)),
    // concatCritMessages: itemCritMessages => dispatch(concatCritMessages(itemCritMessages)),
    updateCritMessages: critMessages => dispatch(updateCritMessages(critMessages)),

  })

const mapStateToProps = (state, ownProps) => {
    //  console.log(ownProps.match.params.unitId);
    //  console.log('ownprops', ownProps.match.params.workId);
    // console.log(ownProps, 'own props in gallery item page')
    return ({
        // item: selectCurrentDay(ownProps.match.params.dayId)(state)
         item: selectCurrentWork(ownProps.match.params.workId)(state),
         currentItem: state.works.currentItem,
         currentUser: state.users.currentUser,
        //  critMessages: selectCurrentCritMessages(ownProps.item.id)(state)
        //  itemUser: selectItemUser(ownProps.item.id)(state),
         
    }) 
} 


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FinalProjectItemPage));

