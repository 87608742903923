import { ASSIGNMENT6 } from "../../assignments/assignments.data"

const UNIT1_DAY6 = {
    id: 6,
    unit: 'unit1',
    route: '/units/unit1/day6',
    assignmentRoute: '/units/unit1/day6/assignment', 
    month: 'September',
    day: 15,
    name: 'collectivizing',
    description: "Today we're going to look at a bunch of art that involves this idea of collecting that I want you to explore in your first project.  We'll start by talking about the Tacita Dean article you read, which talks about the magic and the burden of collecting.  We'll talk about ambergris and we'll talk about that one sock in the drawer that doesn't match anything and sort of makes the whole drawer sing, as a result.",
    illustrationURL: 'https://res.cloudinary.com/chris-kubick/image/upload/v1597205509/side-effects/FA8224F5-0759-480F-B9FF-38D9320412C4_ycjanr.jpg',
    assignment: ASSIGNMENT6,
    videos: [
        {
            id : 'UB1O30fR-EE',
            title:'html basics from traversy media',
            description: 'an hour long dive into the basics of html'
        },
        {
            id : 'yfoY53QXEnI',
            title: 'css basics from traversy media',
            description: 'a video about the basics of css from one of my favorite teachers of web coding'
        },
        {

        },
        {

        },
        {

        },
        {

        },
    ],  
    inspirationLinks: [
        {
            linkText:"Arthur Jafa, Love is the Message, the Message is Death",
            linkSrc:"https://www.youtube.com/watch?v=lKWmx0JNmqY"
        },
        {
            linkText:"Experience the horror of 90 solid minutes of The Grateful Dead tuning its instruments",
            linkSrc:"http://news.avclub.com/experience-the-horror-of-90-solid-minutes-of-the-gratef-1798273081"
        },
        {
            linkText: 'MSCHF Cuts Up $30,000 USD Damien Hirst Spot Print Into 88 Dots',
            linkSrc: 'https://hypebeast.com/2020/4/mschf-damien-hirst-severed-spots-project',
        },
        {
            linkText:"Tacita Dean, 'Collections and Childhood'",
            linkSrc : 'https://drive.google.com/file/d/1xGCnGF_bqDqtF8u83OrKUbtSOprsSVSE/view?usp=sharing',
        },
        {
            linkText: 'the green ray, tacita dean',
            linkSrc: 'https://vimeo.com/38026163',
        },
        {
            linkText: 'monet haystacks',
            linkSrc: 'https://www.google.com/search?q=monet+haystacks&source=lnms&tbm=isch&sa=X&ved=0ahUKEwjAqvDCuLnWAhWJxVQKHc85A5YQ_AUICigB&biw=1382&bih=1279',
        },  


        {
            linkText: 'edward burtynsky',
            linkSrc: 'https://www.edwardburtynsky.com/',
        },
        {
            linkText: 'Lorna Simpson, Easy To Remember',
            linkSrc: 'https://vimeo.com/91549843',
        },
        {
            linkText: 'Christian Marclay, the clock',
            linkSrc: 'https://www.youtube.com/watch?v=BXbQw0rE5UE&list=PLWZ5fzURD_c2XUmveT2oF0SNMPB_f291a',
        },
        {
            linkText: 'Lauren Woods, American Monument',
            linkSrc: 'https://hyperallergic.com/461150/american-monument-lauren-woods-kimberli-meyer-california-state-university-long-beach/',
        },
        {
            linkText: 'working on my novel, corey arcangel',
            linkSrc: 'https://twitter.com/WrknOnMyNovel',
        },
        {
            linkText: 'food that looks likek iggy azalea',
            linkSrc: 'http://foodthatlookslikeiggyazalea.tumblr.com/',
        },
        {
            linkText: 'zimoun',
            linkSrc: 'https://www.zimoun.net/',
        },

        {
            linkText:"Martin Creed - half the air in a given space",
            linkSrc:"https://www.artsy.net/artwork/martin-creed-work-no-dot-200-half-the-air-in-a-given-space"
        },
        {
            linkText: 'every episode of friends (link works for now)',
            linkSrc: 'https://www.youtube.com/watch?v=c5h3qXAQOQI',
        },
        {
            linkText: 'eva hesse repetition nineteen III',
            linkSrc: 'https://www.moma.org/learn/moma_learning/eva-hesse-repetition-nineteen-iii-1968',
        },
        {
            linkText: 'one and three chairs, joseph kosuth',
            linkSrc: 'https://www.moma.org/learn/moma_learning/joseph-kosuth-one-and-three-chairs-1965',
        },
        {
            linkText: 'martin creed work no. 223 (three metronomes)',
            linkSrc: 'http://martincreed.com/site/works/work-no-223',
        },

        {
            linkText: 'agnes martin: drawing as idea and process',
            linkSrc: 'http://notations.aboutdrawing.org/agnes-martin/',
        },

        {
            linkText: 'warhol soup cans',
            linkSrc: 'https://www.moma.org/collection/works/79809',
        },
        {
            linkText:"Richard Serra Throws Molten Lead Inside SFMOMA",
            linkSrc:"https://www.sfmoma.org/richard-serra-throws-molten-lead-inside-sfmoma/"
        },
        {
            linkText: 'Francis Alÿs: The Fabiola Project',
            linkSrc: 'https://www.menil.org/exhibitions/242-francis-alys-the-fabiola-project',
        },				
        {
            linkText:"Aaron Koblin, 'Artfully Visualizing Our Humanity'",
            linkSrc:"https://www.youtube.com/watch?v=4v4XxlfVk3o"
        },
        {
            linkText: 'alejandro duran',
            linkSrc: 'https://alejandroduran.com/',
        },
        ],
        techLinks: [
            {
                linkText: 'css tricks: a complete guide to flexbox',
                linkSrc: 'https://css-tricks.com/snippets/css/a-guide-to-flexbox/',
            },
            {
                linkText:"javascript array reference",
                linkSrc:"https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array"
            },					                
            {
                linkText:"array methods",
                linkSrc:"https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array"
            },

            {
                linkText: 'How To Correctly Shuffle An Array in JavaScript',
                linkSrc: 'https://medium.com/@nitinpatel_20236/how-to-shuffle-correctly-shuffle-an-array-in-javascript-15ea3f84bfb',
            },
            {
                linkText: 'hirst dots starter code',
                linkSrc: 'https://github.com/socalledsound/hirst-starter',
            },

            {
                linkText: 'hirst dots finished',
                linkSrc: 'https://github.com/socalledsound/hirst-starter/tree/finished',
            },
            {
                linkText: 'flex kittens starter code',
                linkSrc: 'https://github.com/socalledsound/kittens-starter',
            },
            {
                linkText: 'js kittens code',
                linkSrc: 'https://github.com/socalledsound/kittens-starter/tree/js-kitties',
            },
        ],
}

export default UNIT1_DAY6