import { ASSIGNMENT18 } from '../../assignments/assignments.data';

const UNIT3_DAY1 = {
    id: 18,
    unit: 'unit3',  
    route: '/units/unit3/day18',
    month: 'October',
    day: 27,
    name: 'mic check',
    description: "Today I first want to take some time to look back at the midterm projects; discuss your successes and work a little with people to understand how to do some things that were problematic.  Then we will leap into expanded realities by taking a look at the original expanded reality: sound/.",
    illustrationURL: 'https://res.cloudinary.com/chris-kubick/image/upload/v1603689412/side-effects/HNND2_lw07um.jpg',
    assignmentRoute: '/units/unit3/day18/assignment', 
    assignment: ASSIGNMENT18,

    inspirationLinks: [
        {
            linkText:"Piezoelectricity",
            linkSrc:"https://en.wikipedia.org/wiki/Piezoelectricity"
        },
        {
            linkText:"I am Sitting in A Room",
            linkSrc:"https://www.youtube.com/watch?v=fAxHlLK3Oyk"
        },
        {
            linkText:"Alvin Lucier on I Am Sitting In A Room",
            linkSrc:"https://www.youtube.com/watch?v=v9XJWBZBzq4"
        },
        {
            linkText:"faust acoustic adventure",
            linkSrc:"http://www.acoustic-adventure.de/projekt.html#"
        },	
        {
            linkText:"Christina Kubisch",
            linkSrc:"http://www.christinakubisch.de/en/works/installations/5"
        },
        {
            linkText:"gary hill, soundings",
            linkSrc:"https://vimeo.com/111166472"
        },
        {
            linkText:"robert morris, box with the sound of tis own making",
            linkSrc:"https://www.wikiart.org/en/robert-morris/box-with-the-sound-of-its-own-making-1961"
        },
        {
            linkText:"terry fox cones of silence",
            linkSrc:"https://www.artsy.net/artwork/terry-fox-cones-of-silence"
        },
        {
            linkText:"Celeste Boursier Mougenot",
            linkSrc:"http://www.artnews.com/2015/04/30/french-pavilion-artist-celeste-boursier-mougenot-teaches-the-world-to-sing/"
        },
        {
            linkText:"toshimaru nakamaru, no input mixing board",
            linkSrc:"https://www.youtube.com/watch?v=qTi0hom6r44"
        },
        {
            linkText:"toshimaru nakamura on his approach to sound",
            linkSrc:"https://www.youtube.com/watch?v=dqfGbtqDVDk"
        },

        {
            linkText:"feeback loops and interaction design",
            linkSrc:"https://www.smashingmagazine.com/2013/02/designing-great-feedback-loops/"
        },	
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        ],
        techLinks: [
            {
                linkText:"how to make a contact microphone",
                linkSrc:"http://www.instructables.com/id/Make-a-Contact-Microphone/"
            },

            {
                linkText:"simple sitting in a room",
                linkSrc:"https://editor.p5js.org/socalledsound/sketches/By7inU4uQ"
            },
            {
                linkText:"sitting in a room cutup",
                linkSrc:"https://editor.p5js.org/socalledsound/sketches/rkw3-54uX"
            },
            
            {
                linkText:"recording filtered sounds to disk",
                linkSrc:"https://editor.p5js.org/socalledsound/sketches/B1TnzOmum"
            },
            {
                linkText:"Acoustic Feedback System With Snare Drum (or Any Other Object)",
                linkSrc:"http://www.instructables.com/id/Acoustic-Feedback-System-With-Snare-Drum-or-Any-Ot/"
            },
            {
                linkText:"no input mixer setup guide",
                linkSrc:"https://www.youtube.com/watch?v=Hv1VQL4zTKc"
            },
            {
                linkText: '',
                linkSrc: '',
            },
            {
                linkText: '',
                linkSrc: '',
            },
            {
                linkText: '',
                linkSrc: '',
            },
            {
                linkText: '',
                linkSrc: '',
            },
        ], 
        videos: [

            {
    
            },
            {
    
            },
            {
    
            },
            {
    
            },
        ],    
}

export default UNIT3_DAY1