import { ASSIGNMENT26 } from '../../assignments/assignments.data';

const UNIT4_DAY3 = {
    id: 26,
    unit: 'unit4', 
    route: '/units/unit4/day26', 
    name: 'rear end',
    month: 'November',
    day: 24,  
    description: "Today we'll create a cloud-based database and learn how to upload images to it.  We'll also learn how to watch that database for changes, in our case, images.  We're going to buid an online gallery that people can drag and drop images to.  The images will be stored in perpetuity on our firebase database, and our gallery will dsiplay all of the images hosted there.",
    illustrationURL:'https://res.cloudinary.com/chris-kubick/image/upload/v1597869193/side-effects/jim-campbell-eroding-wave_nf3txs.jpg',
    assignmentRoute: '/units/unit4/day26/assignment', 
    assignment: ASSIGNMENT26,
  
    inspirationLinks: [
       
        {
            linkText: 'raiders of the lost web',
            linkSrc: 'https://www.theatlantic.com/technology/archive/2015/10/raiders-of-the-lost-web/409210/',
        },
        {
            linkText: "why there's so little left of the early internet",
            linkSrc: 'https://www.bbc.com/future/article/20190401-why-theres-so-little-left-of-the-early-internet',
        },
        {
            linkText: 'the wayback machine at archive.org',
            linkSrc: 'https://archive.org/',
        },
        {
            linkText: 'this website will self-destruct',
            linkSrc: 'https://www.thiswebsitewillselfdestruct.com/',
        },
        {
            linkText: 'my image gallery app, working',
            linkSrc: 'https://new-image-gallery.web.app/',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        {
            linkText: '',
            linkSrc: '',
        },
        ],
        techLinks: [
            
            {
                linkText: 'how to make a drag and drop file uploader with vanilla js',
                linkSrc: 'https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/',
            },
            {
                linkText: 'firebase (you need an account, use your berkeley email so you get yourt free credits)',
                linkSrc: 'https://firebase.google.com/',
            },
            {
                linkText: 'install firebase command line tools',
                linkSrc: 'https://firebase.google.com/docs/cli',
            },

            {
                linkText: 'firebase',
                linkSrc: 'https://firebase.google.com/',
            },
            {
                linkText: 'FileReader object api',
                linkSrc: 'https://developer.mozilla.org/en-US/docs/Web/API/FileReader',
            },
            {
                linkText: 'get started with firebase hosting',
                linkSrc: 'https://firebase.google.com/docs/hosting/quickstart',
            },
            {
                linkText: '',
                linkSrc: '',
            },
            {
                linkText: '',
                linkSrc: '',
            },
        ], 
        videos: [

            {
    
            },
            {
    
            },
            {
    
            },
            {
    
            },
        ],  
}


export default UNIT4_DAY3