import { ASSIGNMENT24 } from '../../assignments/assignments.data';

const UNIT4_DAY1 = {
    id: 24,
    unit: 'unit4',
    month: 'November',
    day: 17, 
    route: '/units/unit4/day24', 
    name: 'johny-five',
    description: "Today we will start to talk about internets that exist away from your desk, in other words, the now-famous 'internet of things'.  We are also going to start talking about how to hook up much more interesting kinds of sensors than mice and keyboards to our browsers.  We'll build on what we learned last class about sockets and servers, and introduce a new javascript library called johny-five, that handles the interface between web sockets and the GPIP pins on a raspberry pi or an arduino.  And, if you've managed to acquire one, I'll show you how to get started with the raspberry pi, which is, in my view, a truly amazing little device.",
    illustrationURL:'https://res.cloudinary.com/chris-kubick/image/upload/v1597764048/side-effects/iot_xzqlxn.jpg',

    assignmentRoute: '/units/unit4/day24/assignment', 
    assignment: ASSIGNMENT24,
    
    inspirationLinks: [
        {
            linkText:"wafaa bilal - domestic tension",
            linkSrc:"http://wafaabilal.com/domestic-tension/"
        },
        {
            linkText: "johny five inventor's kit could be a nice alternative to the rpi (140$)",
            linkSrc: 'https://www.sparkfun.com/j5ik?utm_source=j5io&utm_medium=banner&utm_campaign=j5ik',
        },
        {
            linkText: 'raspberry pi vs arduino comparison',
            linkSrc: 'https://makezine.com/2015/12/04/admittedly-simplistic-guide-raspberry-pi-vs-arduino/',
        },
        {
            linkText: '3d laser scanner with rpi -- decent and cheap!',
            linkSrc: 'https://www.hackster.io/news/create-an-affordable-3d-laser-scanner-with-a-raspberry-pi-070af6b01580',
        },
        {
            linkText: 'handheld 3d scanner with realsense depth sensing camera',
            linkSrc: 'https://eleccelerator.com/pi-handheld-3d-scanner/',
        },
        {
            linkText: 'a nice post on photogrammetry',
            linkSrc: 'https://eleccelerator.com/outdoor-photogrammetry-adventures/',
        },
        {
            linkText: 'getting started with the rpi',
            linkSrc: 'https://projects.raspberrypi.org/en/projects/raspberry-pi-getting-started/1',
        },

        ],
        techLinks: [
            {
                linkText: 'download etcher',
                linkSrc: 'https://www.balena.io/etcher/',
            },
            {
                linkText: 'raspberry pi imager',
                linkSrc: 'https://www.raspberrypi.org/software/',
            },    
            {
                linkText: 'johny five',
                linkSrc: 'http://johnny-five.io/',
            },
            {
                linkText:"GPIO pins on the r pi",
                linkSrc:"https://www.raspberrypi.org/documentation/usage/gpio/"
            },
            {
                linkText:"how to install node.js and npm on raspberry pi",
                linkSrc:"https://linuxize.com/post/how-to-install-node-js-on-raspberry-pi/"
            },
            
            {
                linkText:"setting up the raspberry pi and johnny five",
                linkSrc:"https://www.hackster.io/IainIsCreative/setting-up-the-raspberry-pi-and-johnny-five-56d60f"
            },
            {
                linkText:"another johnny five for r pi tutorial ",
                linkSrc:"https://diyprojects.io/start-programming-nodejs-johnny-five-iot-firmata-orangepi/#.XZQv2OdKiA0"
            },
            {
                linkText:"button example in johnny five",
                linkSrc:"http://johnny-five.io/examples/button/"
            },

            {
                linkText:"setting up an r pi for analog inputs (like a potentiometer",
                linkSrc:"https://learn.adafruit.com/reading-a-analog-in-and-controlling-audio-volume-with-the-raspberry-pi"
            },
            {
                linkText:"using a motion sensor with the r pi",
                linkSrc:"https://maker.pro/raspberry-pi/tutorial/how-to-interface-a-pir-motion-sensor-with-raspberry-pi-gpio"
            },
            {
                linkText:"my eyeball game with sockets code",
                linkSrc:"https://github.com/socalledsound/eyeball_pi"
            },

            {
                linkText: 'microbit-aframe-example',
                linkSrc: 'https://github.com/nbogie/microbit-aframe-example',
            },
            {
                linkText: 'updated multiplayer ball instrument from last time',
                linkSrc: 'https://sound-circles.herokuapp.com/',
            },
            {
                linkText: 'multiplayer sound circle game repo',
                linkSrc: 'https://github.com/socalledsound/sound-circle-server',
            },
            {
                linkText: 'original granular sound thing',
                linkSrc: 'http://zya.github.io/granular/#_=_',
            },
            {
                linkText: 'p5 version of drag and drop granular sound thing (still needs some work)',
                linkSrc: ' https://github.com/socalledsound/p5-grains',
            },
            {
                linkText: 'net ninja google firestore tutorial series',
                linkSrc: 'https://www.youtube.com/watch?v=4d-gIPGzmK4&list=PL4cUxeGkcC9itfjle0ji1xOZ2cjRGY_WB',
            },
            {
                linkText: 'headless pi doc',
                linkSrc: 'https://www.raspberrypi.org/documentation/configuration/wireless/headless.md',
            },
            {
                linkText: 'accessing your pi with ssh',
                linkSrc: 'https://magpi.raspberrypi.org/articles/ssh-remote-control-raspberry-pi',
            },
            
            
        ], 
        videos: [

            {
    
            },
            {
    
            },
            {
    
            },
            {
    
            },
        ],    
}

export default UNIT4_DAY1